import React, { useState, useEffect } from "react";
import theme from "../config/theme";
import {
  Box,
  Typography,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import CssTextField from "../components/CssTextField";
import { useForm, Controller } from "react-hook-form";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { ThemeProvider } from "@emotion/react";
import { useNewPasswordMutation } from "../redux/features/user/userApi";

function ResetPasswordForm({setAlertOpen,setAlert,emailInResetForm,setSeverity,setOpenDialog}) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
// ======================= From endpoint ==================
  const [newPassword, { data, isError, isSuccess, error }] =
  useNewPasswordMutation();
  const [passwordNotMatchError, setPasswordNotMatchError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const validatePassword = (value) => {
    // Password must contain at least one letter (a-zA-Z),
    // one number (0-9), one special character (!@#$%^&*),
    // and be at least 8 characters long
    const regex = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$/;
    return regex.test(value);
  };
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const onSubmit = (data) => {
    //* check if password match or not
    if (data.password !== data.confirm_password) {
      setPasswordNotMatchError("Password is not matching");
      return;
    } else {
      setPasswordNotMatchError("");
    }
    // * removing confirm_password from the registerData
    delete data.confirm_password;
    data.email = emailInResetForm;
    newPassword(data);
  };
  useEffect(()=>{
    if(isSuccess && data){
        console.log('isSuccess',isSuccess,'data',data);
        setAlertOpen(true)
        setSeverity('success')
        setAlert("Your password has been successfully reset, please log in using your new password")
        setOpenDialog(false)
        
    }else if(isError && error){
        setAlertOpen(true);
        setAlert(error?.data?.message);
    }
  })
  return (
    <ThemeProvider theme={theme}>
      <Box display="block">
        <Typography
          sx={{
            py: 2,
            textAlign: "center",
            fontSize: theme.typography.subtitle2,
            fontWeight: theme.typography.fontWeight.bold,
            color: theme.palette.secondary.gold,
          }}
        >
          Code verification successful, reset password below.
        </Typography>
      </Box>
      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        sx={{ mt: 3 }}
      >
        <Box>
          <Controller
            name="password"
            control={control}
            rules={{ validate: validatePassword }}
            defaultValue=""
            render={({ field }) => (
              <CssTextField
                required
                fullWidth
                label="Password"
                type={showPassword ? "text" : "password"}
                variant="outlined" // Use "variant" instead of "size"
                {...field}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        size="small"
                        sx={{
                          color: "#8CA690",
                        }}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...register("password", { required: true })}
              />
            )}
          />
          {errors.password && (
            <Typography sx={{ color: "red" }} variant="subtitle2">
              Password must contain at least:
              <br />
              * one letter
              <br /> * one number
              <br /> * one special character (!@#$%^&*)
              <br /> * at least 8 characters long.
            </Typography>
          )}
        </Box>

        <Box sx={{ mt: 2 }}>
          <CssTextField
            required
            fullWidth
            label="Confirm Password"
            type={showPassword ? "text" : "password"}
            {...register("confirm_password", { required: true })}
          />
          {errors.confirm_password && (
            <Typography sx={{ color: "red" }} variant="subtitle2">
              Confirm password is required
            </Typography>
          )}
          {passwordNotMatchError && (
            <Typography sx={{ color: "red" }} variant="subtitle2">
              {passwordNotMatchError}
            </Typography>
          )}
        </Box>
      
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{
          fontFamily: theme.typography.fontFamily,
          mt: 3,
          mb: 3,
          borderRadius: 15,
          fontWeight: "bold",
          bgcolor: "#9ABEBC",
          "&:hover": {
            bgcolor: " #d0e1e0",
          },
        }}
      >
        Submit
      </Button>
      </Box>
    </ThemeProvider>
  );
}

export default ResetPasswordForm;
