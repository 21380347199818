import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Container,
  Fade,
  Modal,
  Backdrop,
  IconButton,
  InputAdornment,
  Grid,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import createAdminIcon from "../assets/icons/createAdmin.svg";
import { ThemeProvider } from "@mui/material/styles";
import { useForm, Controller } from "react-hook-form";
import { useCreateAdminMutation } from "../redux/features/user/userApi";
import { useNavigate } from "react-router-dom";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import theme from "../config/theme";
import CloseIcon from "@mui/icons-material/Close";
import CssTextField from "../components/CssTextField";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90vw", sm: "65vw", md: "55vw", lg: "45vw" },
  overflow: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "15px",
};
const CreateAdmin = ({ open, handleClose, onSuccess }) => {
  // * * * * * * * * * * * User Registration API * * * * * * * * * * * *
  const [createAdmin, { data, isError, isLoading, isSuccess, error }] =
    useCreateAdminMutation();

  const [passwordNotMatchError, setPasswordNotMatchError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  // * * * * * * * * * * * Functions * * * * * * * * *

  const onSubmit = (data) => {
    //* check if password match or not
    if (data.password !== data.confirm_password) {
      setPasswordNotMatchError("Password is not matching");
      return;
    } else {
      setPasswordNotMatchError("");
    }
    // * removing confirm_password from the registerData
    delete data.confirm_password;
    // console.log("data", data);
    createAdmin(data);
  };

  const validatePassword = (value) => {
    // Password must contain at least one letter (a-zA-Z),
    // one number (0-9), one special character (!@#$%^&*),
    // and be at least 8 characters long
    const regex = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$/;
    return regex.test(value);
  };
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  //  * * * * * * * * * * * Notification* * * * * * * * * * * * *
  useEffect(() => {
    if (isSuccess && data) {
      onSuccess(data?.message, "success");
      handleClose();
    } else if (isError && error?.data) {
      onSuccess(error?.data?.message, "error");
    }
  }, [data, isError, isSuccess, error, navigate, onSuccess, handleClose]);

  return (
    <ThemeProvider theme={theme}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 400,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Box
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                mt: 0.5,
                mr: 0.5,
              }}
            >
              <IconButton size="small" onClick={handleClose}>
                <CloseIcon sx={{ color: "gray" }} />
              </IconButton>
            </Box>
            <Box sx={{ height: "65vh", display: "flex", alignItems: "center" }}>
              <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img src={createAdminIcon} alt="createAdminIcon" />
                  <Typography
                    sx={{
                      mt: 0.4,
                      fontSize: theme.typography.subtitle1,
                      fontWeight: theme.typography.fontWeight.semiBold,
                      color: theme.palette.primary.black,
                    }}
                  >
                    Create Admin
                  </Typography>
                  <Box
                    component="form"
                    noValidate
                    onSubmit={handleSubmit(onSubmit)}
                    sx={{ mt: 3 }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <CssTextField
                          autoComplete="first_name"
                          required
                          fullWidth
                          label="First Name"
                          autoFocus
                          size="small"
                          {...register("first_name", { required: true })}
                          defaultValue=""
                        />
                        {errors.first_name && (
                          <Typography sx={{ color: "red" }} variant="subtitle2">
                            First name is required
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <CssTextField
                          required
                          fullWidth
                          label="Last Name"
                          autoComplete="last_name"
                          size="small"
                          {...register("last_name", { required: true })}
                        />
                        {errors.last_name && (
                          <Typography sx={{ color: "red" }} variant="subtitle2">
                            Last Name is required
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <CssTextField
                          required
                          fullWidth
                          label="Email Address"
                          autoComplete="email"
                          type="email"
                          size="small"
                          {...register("email", { required: true })}
                        />
                        {errors.email && (
                          <Typography sx={{ color: "red" }} variant="subtitle2">
                            Email address is required
                          </Typography>
                        )}
                      </Grid>

                      <Grid item xs={12}>
                        <Controller
                          name="password"
                          control={control}
                          rules={{ validate: validatePassword }}
                          defaultValue=""
                          render={({ field }) => (
                            <CssTextField
                              required
                              fullWidth
                              label="Password"
                              type={showPassword ? "text" : "password"}
                              size="small"
                              {...field}
                              InputProps={{
                                // <-- This is where the toggle button is added.
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      size="small"
                                      sx={{
                                        color: "#8CA690",
                                      }}
                                    >
                                      {showPassword ? (
                                        <Visibility />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              {...register("password", { required: true })}
                            />
                          )}
                        />
                        {errors.password && (
                          <Typography sx={{ color: "red" }} variant="subtitle2">
                            Password must contain at least:
                            <br />
                            * one letter
                            <br /> * one number
                            <br /> * one special character (!@#$%^&*)
                            <br /> * at least 8 characters long.
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <CssTextField
                          required
                          fullWidth
                          label="Confirm Password"
                          type={showPassword ? "text" : "password"}
                          size="small"
                          {...register("confirm_password", { required: true })}
                        />
                        {errors.confirm_password && (
                          <Typography sx={{ color: "red" }} variant="subtitle2">
                            Confirm password is required
                          </Typography>
                        )}
                        {passwordNotMatchError && (
                          <Typography sx={{ color: "red" }} variant="subtitle2">
                            {passwordNotMatchError}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      disabled={isLoading}
                      sx={{
                        fontFamily: theme.typography.fontFamily,
                        mt: 3,
                        mb: 3,
                        borderRadius: 15,
                        fontWeight: "bold",
                        bgcolor: theme.palette.primary.babyblue,
                        "&:hover": {
                          bgcolor: theme.palette.primary.navy,
                        },
                      }}
                    >
                      Submit
                    </Button>
                  </Box>
                </Box>
              </Container>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </ThemeProvider>
  );
};

export default CreateAdmin;
