import { Navigate } from "react-router-dom";
import { getFromLocalStorage } from "../utils/localStorage";
import React, { useEffect, useRef } from 'react';
export const AdminRoute = ({ children }) => {
  const alertShown = useRef(false);
  const accessToken = getFromLocalStorage("token");
  const userString = getFromLocalStorage("user");

  useEffect(() => {
    // Check if the user object is valid and has a role property
    if (!alertShown.current && accessToken && userString) {
      const user = JSON.parse(userString);

      if (user && user.role && (user.role === "admin" || user.role === "superadmin")) {
        // No need to show alert, user has necessary permissions
      } else {
        // Show a pop-up alert
        window.alert("You do not have the necessary permissions to access this page.");
        // Set the ref to true to avoid showing the alert again
        alertShown.current = true;
      }
    }
  }, [accessToken, userString]);
  // Check if the userString is available and not empty
  if (!accessToken || !userString) {
    return <Navigate to="/login" />;
  }

  const user = JSON.parse(userString);

  // Check if the user object is valid and has a role property
  if (user && user.role && (user.role === "admin" || user.role === "superadmin")) {
    return <>{children}</>;
  } else {
    // Redirect to the login page
    return <Navigate to="/login" />;
  }
};
