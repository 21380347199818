import React, {useState} from 'react';
import {Card, CardContent, Typography, Box, Button} from '@mui/material';
import {useParams} from 'react-router-dom';
import {useStartQuizQuery} from '../redux/features/quiz/quizApi';
import QuizComponent from '../components/QuizComponent';
import Loading from '../components/Loading';
import theme from '../config/theme';

const QuizAttempt = () => {
    const param = useParams();
    const [startQuiz, setStartQuiz] = useState(false);
    // * * * * * * * * API * * * * * * *
    const {data, isLoading} = useStartQuizQuery(param?.quiz_id);
    console.log(data?.data);
    return (
        <>
            {isLoading ? (
                <Loading/>
            ) : startQuiz ? (
                <QuizComponent
                    showResult={data?.data?.show_final_result}
                    showScore={data?.data?.show_score}
                    quizData={data?.data?.questions}
                    quizTime={data?.data?.quiz_time}
                    quizName={data?.data?.quiz_name}
                    quizId={data?.data?.id}
                />
            ) : (
                <div style={{
                    backgroundColor: theme.palette.secondary.lightGrey,
                    height: '100vh',
                    width: '100vw',
                    padding: '48px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                }}>
                    <Card
                        sx={{
                            height: '80%',
                            m: 0,
                            p: {
                                xs: 1,
                                sm: 2,
                            },
                            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                            borderRadius: '20px',
                            color: 'gray',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <CardContent sx={{my: 'auto', flexGrow: '1'}}>
                            <Box>
                                <Typography
                                    gutterBottom
                                    sx={{
                                        fontFamily: theme.typography.fontFamily,
                                        fontWeight: theme.typography.fontWeight.bold,
                                        mt: 5,
                                        mb: 0,
                                        fontSize: {
                                            xs: '25px',
                                            sm: '30px',
                                        },
                                        color: theme.palette.primary.navy,
                                    }}
                                >
                                    {data?.data?.quiz_name}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: theme.typography.h6,
                                        fontFamily: theme.typography.fontFamily,
                                    }}
                                    gutterBottom
                                >
                                    Please read the following instructions
                                </Typography>
                            </Box>

                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: {
                                        xs: 'column',
                                        sm: 'row',
                                    },
                                }}
                            >
                                <Box
                                    sx={{
                                        width: {
                                            xs: '100%',
                                            sm: '60%',
                                        },
                                        pr: 5,
                                    }}
                                >
                                    <Typography
                                        gutterBottom
                                        sx={{
                                            fontFamily: theme.typography.fontFamily,
                                            fontSize: {
                                                xs: '18px',
                                                sm: '22px',
                                            },
                                            fontWeight: theme.typography.fontWeight.medium,
                                            color: theme.palette.primary.black,
                                            mt: 2,
                                        }}
                                    >
                                        Instructions
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: theme.typography.h6,
                                            fontFamily: theme.typography.fontFamily,
                                            color: theme.palette.primary.black,
                                            fontWeight: theme.typography.fontWeight.medium,
                                        }}
                                        paragraph
                                    >
                                        This quiz consists of{' '}
                                        <span style={{color: 'transparent'}}>
                    {data?.data?.questions?.length}
                  </span>{' '}
                                        multiple-choice questions.
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: theme.typography.h6,
                                            fontFamily: theme.typography.fontFamily,
                                            color: theme.palette.primary.black,
                                            fontWeight: theme.typography.fontWeight.medium,
                                        }}
                                        paragraph
                                    >
                                        Keep the following in mind:
                                    </Typography>
                                    <Box
                                        sx={{
                                            fontSize: theme.typography.h6,
                                            fontFamily: theme.typography.fontFamily,
                                            color: theme.palette.primary.black,
                                            fontWeight: theme.typography.fontWeight.medium,
                                        }}
                                    >
                                        {+data?.data?.quiz_time === -1 ? (
                                            <Typography
                                                sx={{
                                                    fontSize: theme.typography.h6,
                                                    fontFamily: theme.typography.fontFamily,
                                                    fontWeight: theme.typography.fontWeight.medium,
                                                }}
                                            >
                                                Timing : You need to complete the quiz in one sitting.
                                                There is {' '}
                                                <span style={{
                                                    fontSize: theme.typography.subtitle2,
                                                    color: 'red',
                                                }}
                                                >no time limit{' '}
                        </span>
                                                set to this quiz.
                                            </Typography>
                                        ) : (
                                            <Typography
                                                sx={{
                                                    fontSize: theme.typography.h6,
                                                    fontFamily: theme.typography.fontFamily,
                                                    fontWeight: theme.typography.fontWeight.medium,
                                                }}
                                            >
                                                Timing : You need to complete the quiz in one sitting, as
                                                you are allocated{' '}
                                                <span style={{color: 'red'}}>
                        {data?.data?.quiz_time}{' '}
                      </span>
                                                minutes to each attempt.
                                            </Typography>
                                        )}
                                    </Box>
                                    <Typography
                                        sx={{
                                            mt: 2,
                                            fontSize: theme.typography.h6,
                                            fontFamily: theme.typography.fontFamily,
                                            color: theme.palette.primary.black,
                                            fontWeight: theme.typography.fontWeight.medium,
                                        }}
                                        paragraph
                                    >
                                        To start, click the <b>Start</b> button. When finished, click
                                        the <b>Submit</b> button.
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        width: {
                                            xs: '100%',
                                            sm: '40%',
                                        },
                                    }}
                                >
                                    <Box sx={{display: 'flex'}}>
                                        <Box sx={{width: '54%'}}>
                                            <Typography
                                                gutterBottom
                                                sx={{
                                                    mt: '18px',
                                                    fontFamily: theme.typography.fontFamily,
                                                    fontSize: {
                                                        xs: '17px',
                                                        sm: '19px',
                                                    },
                                                    mb: 3,
                                                    fontWeight: '600',
                                                }}
                                            >
                                                Date:
                                            </Typography>
                                            <Typography
                                                gutterBottom
                                                sx={{
                                                    fontFamily: theme.typography.fontFamily,
                                                    fontSize: {
                                                        xs: '17px',
                                                        sm: '19px',
                                                    },
                                                    mb: 2,
                                                    fontWeight: '600',
                                                }}
                                            >
                                                Time:
                                            </Typography>
                                            <Typography
                                                gutterBottom
                                                sx={{
                                                    fontFamily: theme.typography.fontFamily,
                                                    fontSize: {
                                                        xs: '17px',
                                                        sm: '19px',
                                                    },
                                                    mb: 2,
                                                    fontWeight: '600',
                                                }}
                                            >
                                                Questions:
                                            </Typography>
                                            <Typography
                                                gutterBottom
                                                sx={{
                                                    fontFamily: theme.typography.fontFamily,
                                                    fontSize: {
                                                        xs: '17px',
                                                        sm: '19px',
                                                    },
                                                    fontWeight: '600',
                                                }}
                                            >
                                                Attempt(s):
                                            </Typography>
                                        </Box>

                                        <Box sx={{width: '47%'}}>
                                            <Typography
                                                gutterBottom
                                                sx={{
                                                    fontFamily: theme.typography.fontFamily,
                                                    fontSize: {
                                                        xs: '17px',
                                                        sm: '29px',
                                                    },
                                                    mt: '5px',
                                                    mb: {xs: '12px', sm: 2.5},
                                                    fontWeight: '600',
                                                }}
                                            >
                      <span style={{fontSize: '18px'}}>
                        {new Date().toLocaleDateString()}
                      </span>
                                            </Typography>
                                            <Typography
                                                gutterBottom
                                                sx={{
                                                    fontFamily: theme.typography.fontFamily,
                                                    fontWeight: theme.typography.fontWeight.medium,
                                                    fontSize: {
                                                        xs: '17px',
                                                        sm: '19px',
                                                    },
                                                    mt: {sm: '15px'},
                                                    mb: {xs: '12px', sm: 2},
                                                }}
                                            >
                                                {+data?.data?.quiz_time === -1 ? (
                                                    'Unlimited'
                                                ) : (
                                                    <span style={{fontSize: '18px'}}>
                          {data?.data?.quiz_time} {data?.data?.quiz_time === 1 ? 'min' : 'mins'}
                        </span>
                                                )}
                                            </Typography>
                                            <Typography
                                                gutterBottom
                                                sx={{
                                                    fontFamily: theme.typography.fontFamily,
                                                    fontSize: {
                                                        xs: '17px',
                                                        sm: '19px',
                                                    },
                                                    mt: {sm: '15px'},
                                                    mb: {xs: '12px', sm: '13px'},
                                                    fontWeight: '600',
                                                }}
                                            >
                      <span style={{fontSize: '18px'}}>
                        {data?.data?.questions?.length}
                      </span>
                                            </Typography>
                                            <Typography
                                                variant="subtitle1"
                                                gutterBottom
                                                sx={{
                                                    fontSize: {
                                                        xs: '18px',
                                                        sm: '20px',
                                                    },
                                                    fontWeight: '600',
                                                }}
                                            >
                      <span style={{fontSize: '18px'}}>
                        {data?.data?.leaderboard?.length ?? 0}
                      </span>
                                            </Typography>
                                        </Box>
                                    </Box>

                                    <Button
                                        variant="contained"
                                        sx={{
                                            whiteSpace: 'nowrap',
                                            borderRadius: 5,
                                            fontWeight: 'bold',
                                            bgcolor: theme.palette.primary.main,
                                            '&:hover': {
                                                bgcolor: theme.palette.primary.main_1,
                                                color: theme.palette.primary.black,
                                            },
                                            mt: 4,
                                            width: '80%',
                                        }}
                                        onClick={() => setStartQuiz(true)}
                                        disabled={!data?.data?.id}
                                    >
                                        Start
                                    </Button>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                </div>
            )}
        </>
    );
};

export default QuizAttempt;
