import api from "../../api/apiSlice";

const dashboardApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getDashboardData: builder.query({
        query: () => ({
            url: '/dashboard',
            method: 'GET',
          }),
    }),
  }),
});

export const {
  useGetDashboardDataQuery,
} = dashboardApi;

