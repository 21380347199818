import Button from '@mui/material/Button';
import Divider from '@mui/joy/Divider';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import Typography from '@mui/joy/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import theme from '../config/theme';

const AlertDialog = ({open, setOpen, message, onConfirm, type, primaryButtonLabel}) => {
    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" sx={{
                fontSize: theme.typography.title,
                fontFamily: theme.typography.fontFamily,
                fontWeight: 'bold',
            }}>
                <Typography
                    id="alert-dialog-modal-title"
                    sx={{fontSize: theme.typography.title, fontFamily: theme.typography.fontFamily, fontWeight: 'bold'}}
                    startDecorator={<WarningRoundedIcon sx={{color: 'orange'}}/>}
                >
                    Confirmation
                </Typography>
            </DialogTitle>
            <Divider/>
            <DialogContent>
                <DialogContentText id="alert-dialog-description"
                                   sx={{fontSize: theme.typography.subtitle2, fontFamily: theme.typography.fontFamily}}
                >
                    {message}
                </DialogContentText>
            </DialogContent>
            <Divider/>
            <DialogActions sx={{padding: '.8rem 1.5rem'}}>
                <Button
                    sx={{
                        whiteSpace: 'nowrap',
                        fontWeight: 'bold',
                        flexGrow: '0',
                        textTransform: 'capitalize',
                        ...(
                            type === 'delete' ?
                                {
                                    bgcolor: theme.palette.primary.main,
                                    '&:hover': {
                                        bgcolor: theme.palette.primary.main_1,
                                        color: '#000',
                                    },
                                } : {}),
                    }}
                    variant={type === 'delete' ? 'contained' : 'outlined'}
                    onClick={() => setOpen(false)}
                >
                    Cancel
                </Button>
                {!type && (
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{
                            bgcolor: theme.palette.primary.main,
                            '&:hover': {
                                bgcolor: theme.palette.primary.main_1,
                                color: '#000',
                            },
                        }}
                        onClick={() => {
                            onConfirm();
                            setOpen(false);
                        }}
                    >
                        {primaryButtonLabel}
                    </Button>
                )}
                {type === 'delete' && (
                    <Button
                        variant="outlined"
                        color="error"
                        sx={{
                            textTransform: 'capitalize',
                            fontWeight: 'bold',
                        }}
                        onClick={() => {
                            onConfirm();
                            setOpen(false);
                        }}
                    >
                        Delete
                    </Button>
                )}
                {type === 'submit' && (
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{
                            bgcolor: theme.palette.primary.main,
                            '&:hover': {
                                bgcolor: theme.palette.primary.main_1,
                                color: '#000',
                            },
                        }}
                        onClick={() => {
                            onConfirm();
                            setOpen(false);
                        }}
                    >
                        Submit
                    </Button>
                )}
                {type === 'verify' && (
                    <Button
                        variant="contained"
                        sx={{
                            whiteSpace: 'nowrap',
                            fontWeight: 'bold',
                            bgcolor: theme.palette.primary.main,
                            '&:hover': {
                                bgcolor: theme.palette.primary.main_1,
                                color: '#000',
                            },
                            flexGrow: '0',
                            textTransform: 'capitalize',
                        }}
                        onClick={() => {
                            onConfirm();
                            setOpen(false);
                        }}
                    >
                        Verify
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default AlertDialog;
