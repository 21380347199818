import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  getFromLocalStorage,
  removeFromLocalStorage,
  saveToLocalStorage,
} from "../../utils/localStorage";
// import Cookies from "js-cookie";
const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers, { getState }) => {
    const csrfToken = getFromLocalStorage('csrfToken');
    const authToken = getFromLocalStorage("token");
    if (!csrfToken) {
      console.error('CSRF token not available.');
      return;
    }
    headers.set('X-CSRFToken', csrfToken);
    headers.set("Authorization", `Bearer ${authToken}`);
    headers.set("ngrok-skip-browser-warning", "true");
    return headers;
  },
  mode: 'cors',
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, {
    ...extraOptions,
    credentials: "include", // Send cookies with the request
  });
  if (result.error && result.error.originalStatus === 401) {
    // try to get a new token
    const refreshResult = await baseQuery(
      `/user/refresh-token?refreshToken=${getFromLocalStorage(
        "refresh-token"
      )}`,
      api,
      extraOptions
    );
        // console.log('refreshResult',refreshResult);
    if (refreshResult.data) {
      saveToLocalStorage("token", refreshResult?.data?.data?.access_token);
      saveToLocalStorage(
        "user",
        JSON.stringify(refreshResult?.data?.data?.user)
      );

      // retry the initial query
      result = await baseQuery(args, api, {
        ...extraOptions,
        credentials: "include", // Send cookies with the request
      });
    } else {
      // logout
      removeFromLocalStorage("token");
      removeFromLocalStorage("refresh-token");
      removeFromLocalStorage("user");
      window.location.reload();
    }
  }
  // console.log('result',result);
  return result;
};

export const api = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithReauth, 
  tagTypes: ["user", "question", "quiz"],
  endpoints: () => ({}),
});

export default api;
