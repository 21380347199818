import React from 'react';
import {
    Backdrop,
    Box,
    Fade,
    IconButton,
    Modal,
    Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import theme from '../config/theme';
import GPTIcon from '../assets/icons/chatgtp-logo.png';
import manual from '../assets/icons/manual.svg';

const DetailsModal = ({open, handleClose, question}) => {
    const createdDate = new Date(question?.created_date_time * 1000);
    const updatedDate = question?.updated_date_time
        ? new Date(question?.updated_date_time * 1000)
        : null;

    const linkStyle = {
        color: theme.palette.primary.navy,
    };
    const hoverStyle = {
        color: theme.palette.primary.babyblue,
    };
    console.log(question);
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{timeout: 400}}
        >
            <Fade in={open}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: {xs: '95%', sm: '95%', md: '65%'},
                        maxHeight: '85vh',
                        overflow: 'auto',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        borderRadius: 3,
                        fontFamily: theme.typography.fontFamily,
                    }}
                >
                    {/* Close Icon */}
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 15,
                            right: 0,
                            mt: 1,
                            mr: {xs: 1, sm: 3},
                        }}
                    >
                        <IconButton size="small" onClick={handleClose}>
                            <CloseIcon sx={{color: 'gray'}}/>
                        </IconButton>
                    </Box>

                    {/* Content starts */}
                    <Box sx={{px: 4, py: 4}}>
                        {/* Artical info */}
                        <Box display="flex">
                            <Typography
                                gutterBottom
                                sx={{
                                    mr: 'auto',
                                    ml: 2,
                                    fontSize: theme.typography.subtitle1,
                                    fontWeight: theme.typography.fontWeight.semiBold,
                                }}
                            >
                                <a
                                    href={question?.article_url}
                                    style={linkStyle}
                                    onMouseEnter={(e) =>
                                        (e.target.style.color = hoverStyle.color)
                                    }
                                    onMouseLeave={(e) => (e.target.style.color = linkStyle.color)}
                                >
                                    {question?.article}
                                </a>
                            </Typography>
                        </Box>

                        <Box sx={{mt: 2, ml: 2, display: 'flex'}}>
                            {/* Generated from */}
                            <Box>
                                <Typography
                                    sx={{
                                        fontWeight: theme.typography.fontWeight.regular,
                                        fontFamily: theme.typography.fontFamily,
                                        fontSize: theme.typography.h7,
                                        color: theme.palette.primary.darkGrey,
                                        mr: 10,
                                    }}
                                >
                                    GENERATED FROM
                                </Typography>
                                <Box display="flex" alignContent="end">
                                    {question?.generated_from.toLowerCase() === 'openai' ? (
                                        <img src={GPTIcon} alt="chatGPT 3.5 icon"
                                             style={{height: '20px', width: '20px'}}/>
                                    ) : question?.generated_from.toLowerCase() === 'manual' ? (
                                        <img
                                            src={manual}
                                            alt="manual"
                                            style={{width: '12%', color: 'red'}}
                                        />
                                    ) : null}
                                    <Typography
                                        sx={{
                                            ml: 1,
                                            fontWeight: theme.typography.fontWeight.semiBold,
                                            fontSize: theme.typography.subtitle2,
                                            color: theme.palette.primary.black,
                                        }}
                                    >
                                        {' ' + question?.generated_from}
                                    </Typography>
                                </Box>
                            </Box>

                            {/* Tags */}
                            <Typography
                                sx={{
                                    width: '20vw',
                                    fontWeight: theme.typography.fontWeight.regular,
                                    fontFamily: theme.typography.fontFamily,
                                    fontSize: theme.typography.h7,
                                    color: theme.palette.primary.darkGrey,
                                }}
                            >
                                <span>RELEVENT TAG </span>
                                <br/>
                                <span
                                    style={{
                                        fontWeight: theme.typography.fontWeight.semiBold,
                                        fontSize: theme.typography.subtitle2,
                                        color: theme.palette.primary.black,
                                    }}
                                >
                  {Array.isArray(question?.tags)
                      ? question?.tags.join(', ')
                      : question?.tags}
                </span>
                            </Typography>
                            {/* Verified Status */}
                            {question?.verified === true ? (
                                <Typography
                                    sx={{
                                        ml: 10,
                                        fontWeight: theme.typography.fontWeight.bold,
                                        fontFamily: theme.typography.fontFamily,
                                        fontSize: theme.typography.h7,
                                        color: `${theme.palette.primary.green}!important`,
                                    }}
                                >
                                    <span>VERIFIED BY </span>
                                    <br/>
                                    <span
                                        style={{
                                            fontWeight: theme.typography.fontWeight.semiBold,
                                            fontSize: theme.typography.subtitle2,
                                            color: theme.palette.primary.black,
                                        }}
                                    >
                    {question?.verified_by}
                  </span>
                                </Typography>
                            ) : (
                                <Typography
                                    sx={{
                                        ml: 10,
                                        fontWeight: theme.typography.fontWeight.bold,
                                        fontFamily: theme.typography.fontFamily,
                                        fontSize: theme.typography.h7,
                                        color: `${theme.palette.primary.red}!important`,
                                    }}
                                >
                                    <span>UNVERIFIED </span>
                                </Typography>
                            )}
                        </Box>
                        {/* Created*/}
                        <Box
                            sx={{
                                mt: 2,
                                ml: 2,
                                display: 'flex',
                            }}
                        >
                            {['CREATED', 'BY'].map((dateType, index) => (
                                <Typography
                                    key={index}
                                    sx={{
                                        mr: 7.6,
                                        fontWeight: theme.typography.fontWeight.regular,
                                        fontFamily: theme.typography.fontFamily,
                                        fontSize: theme.typography.h7,
                                        color: theme.palette.primary.darkGrey,
                                    }}
                                >
                                    <span>{dateType}</span>
                                    <br/>
                                    <span
                                        style={{
                                            fontWeight: theme.typography.fontWeight.semiBold,
                                            fontSize: theme.typography.subtitle2,
                                            color: theme.palette.primary.black,
                                        }}
                                    >
                    {index === 0
                        ? createdDate.toLocaleDateString() +
                        ' - ' +
                        createdDate.toLocaleTimeString().slice(0, 5)
                        : question?.created_by
                            ? question?.created_by
                            : 'None'}
                  </span>
                                </Typography>
                            ))}
                        </Box>
                        {/* Updated */}
                        {updatedDate ? (
                            <Box
                                sx={{
                                    mt: 2,
                                    ml: 2,
                                    display: 'flex',
                                }}
                            >
                                {['UPDATED', 'BY'].map((dateType, index) => (
                                    <Typography
                                        key={index}
                                        sx={{
                                            mr: 7.6,
                                            fontWeight: theme.typography.fontWeight.regular,
                                            fontFamily: theme.typography.fontFamily,
                                            fontSize: theme.typography.h7,
                                            color: theme.palette.primary.darkGrey,
                                        }}
                                    >
                                        <span>{dateType}</span>
                                        <br/>
                                        <span
                                            style={{
                                                fontWeight: theme.typography.fontWeight.semiBold,
                                                fontSize: theme.typography.subtitle2,
                                                color: theme.palette.primary.black,
                                            }}
                                        >
                      {index === 0
                          ? updatedDate.toLocaleDateString() +
                          ' - ' +
                          updatedDate.toLocaleTimeString().slice(0, 5)
                          : question?.updated_by
                              ? question?.updated_by
                              : 'None'}
                    </span>
                                    </Typography>
                                ))}
                            </Box>
                        ) : null}

                        {/* Question */}
                        <Box sx={{ml: 2, mt: 3}}>
                            <Typography
                                sx={{
                                    fontWeight: theme.typography.fontWeight.regular,
                                    fontFamily: theme.typography.fontFamily,
                                    fontSize: theme.typography.h7,
                                    color: theme.palette.primary.darkGrey,
                                    mr: 10,
                                }}
                            >
                                QUESTION
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    mb: 0,
                                    fontWeight: theme.typography.fontWeight.medium,
                                    fontFamily: theme.typography.fontFamily,
                                    fontSize: theme.typography.subtitle2,
                                }}
                                paragraph
                            >
                                {question?.question}
                            </Typography>
                        </Box>

                        {/* Options */}
                        <Box>
                            <Box sx={{pt: 1, ml: 2}}>
                                <Typography
                                    sx={{
                                        fontWeight: theme.typography.fontWeight.regular,
                                        fontFamily: theme.typography.fontFamily,
                                        fontSize: theme.typography.h7,
                                        color: theme.palette.primary.darkGrey,
                                        mr: 10,
                                        mb: 1,
                                    }}
                                >
                                    OPTIONS & ANSWER
                                </Typography>
                                    {(question?.options).map((option, index) => (
                                        <Typography
                                            key={index}
                                            sx={{
                                                mb: 1,
                                                mr: 2,
                                                borderRadius: 3,
                                                color:
                                                    option.includes(question?.answer) === true
                                                        ? theme.palette.primary.green
                                                        : null,
                                                // color: theme.palette.primary.black,
                                                fontWeight: theme.typography.fontWeight.semiBold,
                                                fontFamily: theme.typography.fontFamily,
                                                fontSize: theme.typography.h6,
                                            }}
                                        >
                                            {option}
                                        </Typography>
                                    ))}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
};

export default DetailsModal;
