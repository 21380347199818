import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import {
  Button,
  ButtonGroup,
  FormControl,
  IconButton,
  Typography,
  ThemeProvider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useForm, Controller } from "react-hook-form";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { useEditQuestionMutation } from "../redux/features/question/questionApi";
import theme from "../config/theme";
import { getFromLocalStorage } from "../utils/localStorage";
import CssTextField from "../components/CssTextField";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "95%", sm: "85%", md: "70%", lg: "60%" },
  maxHeight: "85vh",
  overflow: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 3,
};

const EditQuestionModal = ({ open, handleClose, question, onSuccess }) => {
  // * * * * * * * * * * * * Create Job Post API * * * * * * * * * * * *
  const [editQuestion, { data, isError, isLoading, isSuccess, error }] =
    useEditQuestionMutation();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const optionsArray = ["A", "B", "C", "D"];
    const user = JSON.parse(getFromLocalStorage("user"));
    const newData = {
      id: question.id,
      updated_by: user.email,
      tags: data.tags,
      question: data.question,
      answer: data.answer,
      options: optionsArray.map((option) => {
        const optionKey = `option_${option}`;
        return `${option}.${data[optionKey]}`;
      }),
    };
    // console.log(newData);
    // * * * * edit question API * * * *
    editQuestion(newData);
  };

  //  * * * * * * * * * * * Notify user * * * * * * * * * * * * *
  useEffect(() => {
    if (isSuccess && data) {
      onSuccess(data);
      handleClose(true);
    } else if (isError && error) {
      toast.error("error", error);
    }
  }, [data, isError, isSuccess, error, handleClose, onSuccess]);
  // console.log((question?.answer).slice(0, 1));

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 400,
            },
          }}
        >
          <Fade in={open}>
            <Box sx={style} className="chatboxScrollBar">
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit(onSubmit)}
                sx={{
                  p: 4,
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: 10,
                    right: 20,
                    mt: 0.5,
                    mr: 0.5,
                  }}
                >
                  <IconButton size="small" onClick={handleClose}>
                    <CloseIcon sx={{ color: theme.palette.primary.darkGrey }} />
                  </IconButton>
                </Box>
                <Box display="flex">
                  <Typography
                    sx={{
                      fontSize: theme.typography.h6,
                      fontWeight: theme.typography.fontWeight.medium,
                      color: theme.palette.primary.darkGrey,
                    }}
                  >
                    Tag(s)
                  </Typography>
                  <Typography
                    sx={{
                      ml:2,
                      mt:"3px",
                      fontSize: theme.typography.h9,
                      fontWeight: theme.typography.fontWeight.medium,
                      color: theme.palette.primary.darkGrey,
                    }}
                  >
                    * Enter the tags separated by commas, for example: SDG7,
                    SDG13, Poverty
                  </Typography>
                </Box>
                <CssTextField
                  defaultValue={
                    Array.isArray(question?.tags)
                      ? question?.tags.join(",")
                      : question?.tags
                  }
                  sx={{
                    mt: 1,
                    mr: {
                      xs: 0,
                      lg: 1,
                    },
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "black",
                    },
                  }}
                  placeholder="Enter the tag(s), a comma and a space is required between tags"
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  {...register("tags", {
                    required: "At least one tag is required",
                    pattern: {
                      value: /^(\w+\s*,\s*)+\w+$/, // Allows any characters separated by commas
                      message:
                        "Enter the tags separated by commas, for example: SDG7, SDG13, SDG14",
                    },
                  })}
                />
                {errors.tags && (
                  <Typography sx={{ color: "red" }} variant="subtitle1">
                    * {errors.tags.message}
                  </Typography>
                )}
                <Typography
                  sx={{
                    my: 1,
                    fontSize: theme.typography.h6,
                    fontWeight: theme.typography.fontWeight.medium,
                    color: theme.palette.primary.darkGrey,
                  }}
                >
                  Question
                </Typography>
                <CssTextField
                  defaultValue={question?.question}
                  id="outlined-multiline-static"
                  placeholder="Please enter the question"
                  multiline
                  rows={3}
                  fullWidth
                  {...register("question", { required: true })}
                />
                {errors.question && (
                  <Typography sx={{ color: "red" }} variant="subtitle1">
                    * Question is required.
                  </Typography>
                )}
                <Typography
                  sx={{
                    mt: 1,
                    fontSize: theme.typography.h6,
                    fontWeight: theme.typography.fontWeight.medium,
                    color: theme.palette.primary.darkGrey,
                  }}
                >
                  Options
                </Typography>
                <Box display="flex">
                  <Typography
                    sx={{
                      mt: "15px",
                      ml: 1,
                      width: "25px",
                      fontSize: theme.typography.h6,
                      fontWeight: theme.typography.fontWeight.medium,
                      color: theme.palette.primary.darkGrey,
                    }}
                  >
                    A
                  </Typography>
                  <CssTextField
                    defaultValue={question?.options?.[0]}
                    sx={{
                      mt: 1,
                    }}
                    fullWidth
                    id="outlined-basic 2"
                    placeholder="write the option A"
                    variant="outlined"
                    size="small"
                    {...register("option_A", { required: "value required" })}
                  />
                </Box>
                {errors.option_A && (
                  <Typography sx={{ color: "red", ml: 5 }} variant="subtitle1">
                    * {errors.option_A.message}
                  </Typography>
                )}
                <Box display="flex">
                  <Typography
                    sx={{
                      mt: "15px",
                      ml: 1,
                      width: "25px",
                      fontSize: theme.typography.h6,
                      fontWeight: theme.typography.fontWeight.medium,
                      color: theme.palette.primary.darkGrey,
                    }}
                  >
                    B
                  </Typography>
                  <CssTextField
                    defaultValue={question?.options?.[1]}
                    sx={{
                      mt: 1,
                    }}
                    fullWidth
                    id="outlined-basic 2"
                    placeholder="write the option B"
                    variant="outlined"
                    size="small"
                    {...register("option_B", { required: "value required" })}
                  />
                </Box>
                {errors.option_B && (
                  <Typography sx={{ color: "red", ml: 5 }} variant="subtitle1">
                    * {errors.option_B.message}
                  </Typography>
                )}
                <Box display="flex">
                  <Typography
                    sx={{
                      mt: "15px",
                      ml: 1,
                      width: "25px",
                      fontSize: theme.typography.h6,
                      fontWeight: theme.typography.fontWeight.medium,
                      color: theme.palette.primary.darkGrey,
                    }}
                  >
                    C
                  </Typography>
                  <CssTextField
                    defaultValue={question?.options?.[2]}
                    sx={{
                      mt: 1,
                    }}
                    fullWidth
                    id="outlined-basic 2"
                    placeholder="write the option C"
                    variant="outlined"
                    size="small"
                    {...register("option_C", { required: "value required" })}
                  />
                </Box>
                {errors.option_C && (
                  <Typography sx={{ color: "red", ml: 5 }} variant="subtitle1">
                    * {errors.option_C.message}
                  </Typography>
                )}
                <Box display="flex">
                  <Typography
                    sx={{
                      mt: "15px",
                      ml: 1,
                      width: "25px",
                      fontSize: theme.typography.h6,
                      fontWeight: theme.typography.fontWeight.medium,
                      color: theme.palette.primary.darkGrey,
                    }}
                  >
                    D
                  </Typography>
                  <CssTextField
                    defaultValue={question?.options?.[3]}
                    sx={{
                      mt: 1,
                    }}
                    fullWidth
                    id="outlined-basic 2"
                    placeholder="write the option D"
                    variant="outlined"
                    size="small"
                    {...register("option_D", { required: "value required" })}
                  />
                </Box>
                {errors.option_D && (
                  <Typography sx={{ color: "red", ml: 5 }} variant="subtitle1">
                    * {errors.option_D.message}
                  </Typography>
                )}
                <Box display="flex">
                  <Box width="50%">
                    <Typography
                      sx={{
                        mt: 1,
                        fontSize: theme.typography.h6,
                        fontWeight: theme.typography.fontWeight.medium,
                        color: theme.palette.primary.darkGrey,
                      }}
                    >
                      Answer
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex">
                  <Box width="50%">
                    <FormControl fullWidth size="small" sx={{ mt: 1 }}>
                      <Controller
                        name="answer"
                        control={control}
                        defaultValue={(question?.answer).slice(0, 1)}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <ButtonGroup>
                            <Button
                              variant={
                                field.value === "A" ? "contained" : "outlined"
                              }
                              onClick={() => field.onChange("A")}
                            >
                              <Typography
                                sx={{
                                  fontSize: theme.typography.h6,
                                  fontWeight:
                                    theme.typography.fontWeight.medium,
                                }}
                              >
                                A
                              </Typography>
                            </Button>
                            <Button
                              variant={
                                field.value === "B" ? "contained" : "outlined"
                              }
                              onClick={() => field.onChange("B")}
                            >
                              <Typography
                                sx={{
                                  fontSize: theme.typography.h6,
                                  fontWeight:
                                    theme.typography.fontWeight.medium,
                                }}
                              >
                                B
                              </Typography>
                            </Button>
                            <Button
                              variant={
                                field.value === "C" ? "contained" : "outlined"
                              }
                              onClick={() => {
                                field.onChange("C");
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: theme.typography.h6,
                                  fontWeight:
                                    theme.typography.fontWeight.medium,
                                }}
                              >
                                C
                              </Typography>
                            </Button>
                            <Button
                              variant={
                                field.value === "D" ? "contained" : "outlined"
                              }
                              onClick={() => field.onChange("D")}
                            >
                              <Typography
                                sx={{
                                  fontSize: theme.typography.h6,
                                  fontWeight:
                                    theme.typography.fontWeight.medium,
                                }}
                              >
                                D
                              </Typography>
                            </Button>
                          </ButtonGroup>
                        )}
                      />
                      {errors.answer?.type === "custom" && (
                        <Typography sx={{ color: "red" }} variant="subtitle1">
                          * {errors.answer.message}
                        </Typography>
                      )}
                    </FormControl>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    mt: 2,
                  }}
                >
                  <Button
                    type="submit"
                    disabled={isLoading}
                    sx={{
                      whiteSpace: "nowrap",
                      borderRadius: 5,
                      fontWeight: "bold",
                      bgcolor: theme.palette.primary.main,
                      "&:hover": {
                        bgcolor: theme.palette.primary.main_1,
                          color: '#000'
                      },
                      flexGrow: "0",
                      textTransform: "capitalize",
                      mr: {
                        xs: 0,
                        lg: 4,
                      },
                      mb: {
                        xs: 2,
                        lg: 0,
                      },
                      width: "170px",
                    }}
                    size="medium"
                    variant="contained"
                  >
                    Update
                  </Button>
                </Box>
              </Box>
            </Box>
          </Fade>
        </Modal>
      </ThemeProvider>
    </div>
  );
};

export default EditQuestionModal;
