import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import {
    Button,
    ButtonGroup,
    FormControl,
    IconButton,
    TextField,
    ThemeProvider,
    Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {useForm, Controller, useFieldArray} from 'react-hook-form';
import {useEffect} from 'react';
import {useCreateQuestionMutation} from '../redux/features/question/questionApi';
import theme from '../config/theme';
import {styled} from '@mui/material/styles';
import {getFromLocalStorage} from '../utils/localStorage';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {xs: '95%', sm: '85%', md: '70%', lg: '60%'},
    maxHeight: '85vh',
    overflow: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 3,
};

const CreateQuestionModal = ({open, handleClose, onSuccess}) => {
    // * * * * * * * * * * * * Create Job Post API * * * * * * * * * * * *
    const [createQuestion, {data, isError, isLoading, isSuccess, error}] =
        useCreateQuestionMutation();

    const letters = ['A', 'B', 'C', 'D'];
    const initialValues = Array.from({length: 4}, () => ({text: ''}));

    const {
        control,
        register,
        handleSubmit,
        formState: {errors},
    } = useForm({
        defaultValues: {
            options: initialValues,
        },
    });

    const {fields} = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormProvider)
        name: 'options', // unique name for your Field Array
    });

    const isValidURL = (url) => {
        // basic URL pattern
        const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
        return urlPattern.test(url);
    };

    const onSubmit = (data) => {
        const user = JSON.parse(getFromLocalStorage('user'));
        const newData = {
            method: 'manual',
            article: data.article,
            article_url: data.article_url,
            created_by: user.email,
            tags: data.tags,
            question: data.question,
            answer: data.answer,
            options: data.options.map(i => i.text),
        };
        // * * * * create question API * * * *
        createQuestion(newData);
    };

    //  * * * * * * * * * * * Notify user * * * * * * * * * * * * *
    useEffect(() => {
        if (isSuccess && data) {
            onSuccess(data);
            handleClose(true);
        } else if (isError && error) {
            console.error('error', error);
        }
    }, [data, isError, isSuccess, error, handleClose, onSuccess]);

    const CssTextField = styled(TextField)({
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#ADC4CE',
                borderRadius: 15,
            },
            '&:hover fieldset': {borderColor: '#ADC4CE'},
            '&.Mui-focused fieldset': {
                borderColor: '#ADC4CE',
                borderWidth: '0.1em',
                fontSize: theme.typography.h6,
            },
            '& .MuiInputBase-input': {
                fontSize: theme.typography.h6,
                color: '#2E4374',
            },
        },
        '& .MuiInputLabel-root': {
            color: 'rgb(50,86,60,59%)',
        },
    });

    return (
        <div>
            <ThemeProvider theme={theme}>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    slots={{backdrop: Backdrop}}
                    slotProps={{
                        backdrop: {
                            timeout: 400,
                        },
                    }}
                >
                    <Fade in={open}>
                        <Box sx={style} className="chatboxScrollBar">
                            <Box
                                component="form"
                                noValidate
                                onSubmit={handleSubmit(onSubmit)}
                                sx={{
                                    p: 4,
                                }}
                            >
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        right: 0,
                                        mt: 0.5,
                                        mr: 0.5,
                                    }}
                                >
                                    <IconButton size="small" onClick={handleClose}>
                                        <CloseIcon sx={{color: 'gray'}}/>
                                    </IconButton>
                                </Box>
                                <Typography
                                    sx={{
                                        fontSize: theme.typography.h6,
                                        fontWeight: theme.typography.fontWeight.medium,
                                        color: theme.palette.primary.darkGrey,
                                    }}
                                >
                                    Article name:
                                </Typography>
                                <CssTextField
                                    sx={{
                                        mt: 1,
                                        mr: {
                                            xs: 0,
                                            lg: 1,
                                        },
                                        '& .MuiInputBase-input.Mui-disabled': {
                                            WebkitTextFillColor: 'black',
                                            textDecoration: 'line-through',
                                            color: 'grey',
                                        },
                                    }}
                                    placeholder="Provide the name of the article that the question refers to."
                                    fullWidth
                                    id="outlined-basic 2"
                                    variant="outlined"
                                    size="small"
                                    {...register('article', {
                                        required: 'An article name is required',
                                    })}
                                />
                                {errors.article && (
                                    <Typography sx={{color: 'red'}} variant="subtitle1">
                                        * {errors.article.message}
                                    </Typography>
                                )}
                                <Typography
                                    sx={{
                                        mt: 1,
                                        fontSize: theme.typography.h6,
                                        fontWeight: theme.typography.fontWeight.medium,
                                        color: theme.palette.primary.darkGrey,
                                    }}
                                >
                                    Article url:
                                </Typography>
                                <CssTextField
                                    sx={{
                                        mt: 1,
                                        mr: {
                                            xs: 0,
                                            lg: 1,
                                        },
                                        '& .MuiInputBase-input.Mui-disabled': {
                                            textDecoration: 'line-through',
                                            color: 'grey',
                                        },
                                    }}
                                    placeholder="Enter the url of the article that the question refers to."
                                    fullWidth
                                    id="outlined-basic 2"
                                    variant="outlined"
                                    size="small"
                                    {...register('article_url', {
                                        required: 'An URL is required',
                                        validate: {
                                            validURL: (value) =>
                                                isValidURL(value) || 'Please enter a valid URL',
                                        },
                                    })}
                                />
                                {errors.article_url && (
                                    <Typography sx={{color: 'red'}} variant="subtitle1">
                                        * {errors.article_url.message}
                                    </Typography>
                                )}
                                <Typography
                                    sx={{
                                        mt: 1,
                                        fontSize: theme.typography.h6,
                                        fontWeight: theme.typography.fontWeight.medium,
                                        color: theme.palette.primary.darkGrey,
                                    }}
                                >
                                    Tag(s)
                                </Typography>
                                <CssTextField
                                    sx={{
                                        mt: 1,
                                        mr: {
                                            xs: 0,
                                            lg: 1,
                                        },
                                        '& .MuiInputBase-input.Mui-disabled': {
                                            WebkitTextFillColor: 'black',
                                            textDecoration: 'line-through',
                                            color: 'grey',
                                        },
                                    }}
                                    placeholder="Enter the tag(s), a comma and a space is required between tags"
                                    fullWidth
                                    id="outlined-basic 2"
                                    variant="outlined"
                                    size="small"
                                    {...register('tags', {
                                        required: 'At least one tag is required',
                                        pattern: {
                                            value: /^[\s\S]*(,[\s\S]*)*$/, // Allows any characters separated by commas
                                            message: 'Enter the tags separated by commas, for example: SDG7, SDG13, SDG14',
                                        },
                                    })}
                                />
                                {errors.tags && (
                                    <Typography sx={{color: 'red'}} variant="subtitle1">
                                        * {errors.tags.message}
                                    </Typography>
                                )}
                                <Typography
                                    sx={{
                                        my: 1,
                                        fontSize: theme.typography.h6,
                                        fontWeight: theme.typography.fontWeight.medium,
                                        color: theme.palette.primary.darkGrey,
                                    }}
                                >
                                    Question
                                </Typography>
                                <CssTextField
                                    id="outlined-textarea 1"
                                    multiline
                                    placeholder="Please enter your question"
                                    InputLabelProps={{
                                        focused: false,
                                    }}
                                    rows={3}
                                    fullWidth
                                    {...register('question', {required: true})}
                                />
                                {errors.question && (
                                    <Typography sx={{color: 'red'}} variant="subtitle1">
                                        * Question is required.
                                    </Typography>
                                )}
                                <Typography
                                    sx={{
                                        mt: 1,
                                        fontSize: theme.typography.h6,
                                        fontWeight: theme.typography.fontWeight.medium,
                                        color: theme.palette.primary.darkGrey,
                                    }}
                                >
                                    Options
                                </Typography>
                                {fields.map((field, index) => (
                                    <React.Fragment key={field.id}>
                                        <Box display="flex">
                                            <Typography
                                                sx={{
                                                    mt: '15px',
                                                    px: 1,
                                                    width: '25px',
                                                    fontSize: theme.typography.h6,
                                                    fontWeight: theme.typography.fontWeight.medium,
                                                    color: theme.palette.primary.darkGrey,
                                                }}
                                            >
                                                {letters[index]}
                                            </Typography>
                                            <CssTextField
                                                sx={{
                                                    mt: 1,
                                                }}
                                                fullWidth
                                                id="outlined-basic 2"
                                                placeholder={`Write the option ${letters[index]}`}
                                                variant="outlined"
                                                size="small"
                                                {...register(`options.${index}.text`, {required: 'value required'})}
                                            />
                                        </Box>
                                        {errors.options && errors.options[index]?.text && (
                                            <Typography sx={{color: 'red', ml: 4}} variant="subtitle1">
                                                * {errors.options[index].text.message}
                                            </Typography>
                                        )}
                                    </React.Fragment>
                                ))}
                                <Box display="flex">
                                    <Box width="50%">
                                        <Typography
                                            sx={{
                                                mt: 1,
                                                fontSize: theme.typography.h6,
                                                fontWeight: theme.typography.fontWeight.medium,
                                                color: theme.palette.primary.darkGrey,
                                            }}
                                        >
                                            Answer
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box display="flex">
                                    <Box width="50%">
                                        <FormControl fullWidth size="small" sx={{mt: 1}}>
                                            <Controller
                                                name="answer"
                                                control={control}
                                                rules={{required: 'Must select an answer'}}
                                                render={({field}) => (
                                                    <ButtonGroup>
                                                        <Button
                                                            variant={
                                                                field.value === 'A' ? 'contained' : 'outlined'
                                                            }
                                                            onClick={() => field.onChange('A')}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontSize: theme.typography.h6,
                                                                    fontWeight:
                                                                    theme.typography.fontWeight.medium,
                                                                }}
                                                            >
                                                                A
                                                            </Typography>
                                                        </Button>
                                                        <Button
                                                            variant={
                                                                field.value === 'B' ? 'contained' : 'outlined'
                                                            }
                                                            onClick={() => field.onChange('B')}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontSize: theme.typography.h6,
                                                                    fontWeight:
                                                                    theme.typography.fontWeight.medium,
                                                                }}
                                                            >
                                                                B
                                                            </Typography>
                                                        </Button>
                                                        <Button
                                                            variant={
                                                                field.value === 'C' ? 'contained' : 'outlined'
                                                            }
                                                            onClick={() => field.onChange('C')}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontSize: theme.typography.h6,
                                                                    fontWeight:
                                                                    theme.typography.fontWeight.medium,
                                                                }}
                                                            >
                                                                C
                                                            </Typography>
                                                        </Button>
                                                        <Button
                                                            variant={
                                                                field.value === 'D' ? 'contained' : 'outlined'
                                                            }
                                                            onClick={() => field.onChange('D')}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontSize: theme.typography.h6,
                                                                    fontWeight:
                                                                    theme.typography.fontWeight.medium,
                                                                }}
                                                            >
                                                                D
                                                            </Typography>
                                                        </Button>
                                                    </ButtonGroup>
                                                )}
                                            />
                                            {errors.answer?.type &&
                                                <Typography sx={{color: 'red'}} variant="subtitle1">
                                                    * {errors.answer.message}
                                                </Typography>
                                            }
                                        </FormControl>
                                    </Box>

                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        justifyContent: 'center',
                                        mt: 2,
                                    }}
                                >
                                    <Button
                                        type="submit"
                                        disabled={isLoading}
                                        sx={{
                                            whiteSpace: 'nowrap',
                                            borderRadius: 5,
                                            fontWeight: 'bold',
                                            bgcolor: theme.palette.primary.main,
                                            '&:hover': {
                                                bgcolor: theme.palette.primary.main_1,
                                            },
                                            flexGrow: '0',
                                            textTransform: 'capitalize',
                                            width: '170px',
                                        }}
                                        size="medium"
                                        variant="contained"
                                    >
                                        Create Question
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Fade>
                </Modal>
            </ThemeProvider>
        </div>
    );
};

export default CreateQuestionModal;
