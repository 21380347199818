import React from 'react';
import {Card, CardContent, Box, Typography, IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {useLocation, useNavigate} from 'react-router-dom';

import theme from '../config/theme';

const QuizResult = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const quizData = location.state.quizData;
    const score = location.state.score;
    const letters = ['A', 'B', 'C', 'D'];
    const columns = [
        {id: 'number', label: 'No.', align: 'center', maxWidth: 2},
        {id: 'qestion', label: 'Question', minWidth: 120},
        {
            id: 'options',
            label: 'Options',
            minWidth: 120,
        },
    ];

    const rows = quizData?.quizData.map((row, i) => {
        return {number: i + 1, question: row.question, options: row.options};
    });
    // console.log(quizData?.attempted_answers);

    //==============closing current page and go back to attemp page
    const currentURL = new URL(window.location.href);
    const newURL = currentURL.pathname.replace('/result', '');
    const handleClose = () => {
        navigate(newURL, {replace: true});
    };
    return (
        <>
            <Box
                sx={{
                    position: 'absolute',
                    top: 60,
                    right: 60,
                }}
            >
                <IconButton size="small" onClick={handleClose}>
                    <CloseIcon sx={{color: 'gray'}}/>
                </IconButton>
            </Box>
            <Card
                sx={{
                    minHeight: '80vh',
                    m: {
                        xs: 3,
                        sm: 6,
                    },
                    p: {
                        xs: 1,
                        sm: 2,
                    },
                    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                    borderRadius: '20px',
                    color: 'gray',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <CardContent sx={{my: 'auto', flexGrow: '1'}}>
                    <Box>
                        <Typography
                            gutterBottom
                            sx={{
                                fontFamily: theme.typography.fontFamily,
                                fontSize: theme.typography.subtitle1,
                                fontWeight: theme.typography.fontWeight.bold,
                                mt: 1,
                                mb: 0,
                                color: theme.palette.primary.navy,
                            }}
                        >
                            Your Result : {score}%
                        </Typography>
                        <Typography
                            gutterBottom
                            sx={{
                                fontFamily: theme.typography.fontFamily,
                                fontSize: theme.typography.h7,
                                fontWeight: theme.typography.fontWeight.bold,
                                color: theme.palette.primary.navy,
                            }}
                        >
                            Correct Answer :{' '}
                            <span
                                style={{
                                    display: 'inline-block',
                                    width: '20px',
                                    height: '10px',
                                    backgroundColor: '#E3F4F4',
                                    marginLeft: '5px',
                                }}
                            />
                        </Typography>
                    </Box>
                    <TableContainer sx={{maxHeight: '93vh'}}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{
                                                minWidth: column.minWidth,
                                                maxWidth: column.maxWidth,
                                            }}
                                            sx={{
                                                fontWeight: theme.typography.fontWeight.semiBold,
                                                fontFamily: theme.typography.fontFamily,
                                                fontSize: theme.typography.h6,
                                                color: theme.palette.primary.darkGrey,
                                                paddingY: '0px',
                                            }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row, i) => {
                                    return (
                                        <TableRow key={i}>
                                            <TableCell align="center">{row.number}</TableCell>
                                            <TableCell
                                                sx={{
                                                    fontWeight: theme.typography.fontWeight.semiBold,
                                                    fontFamily: theme.typography.fontFamily,
                                                    fontSize: theme.typography.h7,
                                                }}
                                                align="left"
                                            >
                                                {row.question}
                                            </TableCell>
                                            <TableCell>
                                                {row.options.map(
                                                    (option, index) =>
                                                        <React.Fragment key={index}>
                                                            <Box
                                                                display="flex"
                                                                sx={{
                                                                    color:
                                                                        quizData.attempted_answers[i] === option && !quizData.is_correct[i]
                                                                            ? theme.palette.primary.red
                                                                            : (quizData.attempted_answers[i] === option && quizData.is_correct[i]
                                                                                ? theme.palette.primary.green : null),
                                                                    borderRadius: 3,
                                                                    px: 2,
                                                                    bgcolor:
                                                                        quizData.quizData[i]?.answer === option
                                                                            ? '#E3F4F4'
                                                                            : null,
                                                                }}
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        fontWeight:
                                                                        theme.typography.fontWeight.semiBold,
                                                                        fontFamily: theme.typography.fontFamily,
                                                                        fontSize: theme.typography.h7,
                                                                    }}
                                                                >
                                                                    {letters[index]}.
                                                                </Typography>
                                                                <Typography
                                                                    sx={{
                                                                        ml: 1,
                                                                        fontWeight:
                                                                        theme.typography.fontWeight.semiBold,
                                                                        fontFamily: theme.typography.fontFamily,
                                                                        fontSize: theme.typography.h7,
                                                                    }}
                                                                >
                                                                    {option}
                                                                </Typography>
                                                                {index < row.options.length - 1 && <br/>}
                                                            </Box>
                                                        </React.Fragment>,
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
        </>
    );
};
export default QuizResult;
