import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import theme from "../config/theme"
const Copyright=(props)=> {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginX: "auto",
          pt: "1em",
        }}
      >
        <Typography sx={{
          fontSize:theme.typography.h8,
          color:theme.palette.primary.black}} {...props}>
          {"Copyright © SuperQuiz "}
          {new Date().getFullYear()}
        </Typography>
      </Box>
    );
  };
  export default Copyright;