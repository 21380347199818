import { Navigate } from "react-router-dom";
import { getFromLocalStorage } from "../utils/localStorage";

export const SuperAdminRoute = ({ children }) => {
  const accessToken = getFromLocalStorage("token");
  const user = JSON.parse(getFromLocalStorage("user"));
  // console.log('user',user)
  if (accessToken && user?.role === "superadmin") {
    return <>{children}</>;
  } else {
    return <Navigate to="/login"></Navigate>;
  }
};
