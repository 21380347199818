import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import {Box} from '@mui/material';
import theme from '../config/theme';

const Loading = ({height = '100vh', global = false, spinnerSize}) => {
    return (
        <Box
            sx={{
                height: height,
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 9999,
                ...(global ? {
                    backgroundColor: 'rgba(0, 0, 0, 0.4)',
                    position: 'fixed',
                } : {}),
            }}
        >
            <CircularProgress size={spinnerSize} sx={{color: theme.palette.primary.main_2}}/>
        </Box>
    );
};

export default Loading;

