import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Home from './pages/Home';
import Questions from './pages/Questions';
import Quiz from './pages/Quiz';
import Login from './pages/Login';
import Register from './pages/Register';
import Leaderboard from './pages/Leaderboard';
import Admin from './pages/Admin';
// import CreateAdmin from "./pages/CreateAdmin";
import QuizAttempt from './pages/QuizAttempt';
import {PrivateRoute} from './utils/privateRoute';
import {AdminRoute} from './utils/adminRoute';
import {SuperAdminRoute} from './utils/superAdminRoute';
import QuizResult from './components/QuizResult';
import Dashboard from './pages/Dashboard';
import Loading from './components/Loading';
import {useSelector} from 'react-redux';

function App() {
    const {loading} = useSelector((state) => state.global);
    return (
        <>
            {loading && <Loading global={true}/>}
            <BrowserRouter>
                <Routes>
                    <Route path="/register" element={<Register/>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route
                        path="/quiz/attempt/:quiz_id"
                        element={
                            <PrivateRoute>
                                <QuizAttempt/>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/quiz/attempt/:quiz_id/result"
                        element={
                            <PrivateRoute>
                                <QuizResult/>
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path="/"
                        exact
                        element={
                            <AdminRoute>
                                <Home/>
                            </AdminRoute>
                        }
                    >
                        <Route path="/" element={<Dashboard/>}/>
                        <Route path="/questions" element={<Questions/>}/>
                        <Route path="/quiz" element={<Quiz/>}/>
                        <Route path="/leaderboard/:quiz_id" element={<Leaderboard/>}/>
                        <Route
                            path="/admin"
                            element={
                                <SuperAdminRoute>
                                    <Admin/>
                                </SuperAdminRoute>
                            }
                        />
                    </Route>
                </Routes>
            </BrowserRouter>
        </>
    );
}

export default App;
