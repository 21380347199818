import React, {useEffect, useState, useCallback} from 'react';
import {
    Button,
    Card,
    CardContent,
    Typography,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    Box, Stack,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import {useSubmitQuizMutation} from '../redux/features/quiz/quizApi';
import AlertDialog from './AlertDialog';
import ResultModal from './ResultModal';
import theme from '../config/theme';
import {getFromLocalStorage} from '../utils/localStorage';

function QuizComponent({showResult, showScore, quizData, quizTime, quizName, quizId}) {
    // * * * * * * * * * * * * Submit Quiz API * * * * * * * * * * * *
    const [submitQuiz, {data, isError, isSuccess, error}] =
        useSubmitQuizMutation();
    // * * * * * * * * * * * * States * * * * * * * * * * * *
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [openAlertDialog, setOpenAlertDialog] = useState(null);
    const [openResultModal, setOpenResultModal] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState(
        new Array(quizData?.length).fill(''),//setting the length of the array
    );
    const [attemptedQuestions, setAttemptedQuestions] = useState([]);
    const [timeLeft, setTimeLeft] = useState(quizTime * 60);
    // * * * * * * * * * * * *  Functions * * * * * * * * * * * *
    // the quiz time the counts down
    const formatTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = timeInSeconds % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };
    // radio buttons
    const handleOptionChange = (event) => {
        //...is used to create a new array that is copy  of the selectedOptions array,
        //ensure immutability by avoiding direct mutation of the original array
        const updatedSelectedOptions = [...selectedOptions];
        updatedSelectedOptions[currentQuestion] = event.target.value;
        setSelectedOptions(updatedSelectedOptions);

    };

    const handleNextQuestion = () => {
        setCurrentQuestion(prevState => prevState + 1);
    };

    const handlePreviousQuestion = () => {
        setCurrentQuestion(prevState => prevState - 1);
    };

    const [newData, setNewData] = useState({});
    const handleSubmitQuiz = useCallback(() => {

        const result = selectedOptions.map((option, index) => quizData[index].answer === option);
        const totalCorrect = result.reduce((acc, curr) => curr ? acc + curr : acc, 0);

        const score = ((totalCorrect / quizData.length) * 100).toFixed(2);
        const user = JSON.parse(getFromLocalStorage('user'));
        const newQuizData = {
            quiz_id: quizId,
            score: score,
            quiz_taker: user.email,
        };

        submitQuiz(newQuizData);

        setNewData({
            ...newQuizData,
            quizData: quizData,
            attempted_answers: selectedOptions,
            is_correct: result,
        });
        setOpenResultModal(true);
    }, [quizId, selectedOptions, submitQuiz, quizData]);

    const handleCancelQuiz = () => {
        window.location.reload();
    }

    useEffect(() => {
        if (timeLeft === 0) {
            handleSubmitQuiz();
        }
        const timer = setInterval(() => {
            if (timeLeft > 0) {
                setTimeLeft(timeLeft - 1);
            }
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, [timeLeft, handleSubmitQuiz]);
    useEffect(() => {
        if (isSuccess && data) {
            console.log('submitted successfully');
        } else if (isError && error) {
            console.error(error);
        }
    }, [data, isSuccess, isError, error]);
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
                minWidth: '75vh',
                backgroundColor: theme.palette.secondary.lightGrey,
            }}
        >
            <Card
                sx={{
                    minHeight: '80vh',
                    width: '100%',
                    m: {
                        xs: 3,
                        sm: 6,
                    },
                    p: {
                        xs: 1,
                        sm: 2,
                    },
                    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                    borderRadius: '20px',
                    maxWidth: '1000px',
                    margin: '0 auto',
                    color: 'gray',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <CardContent sx={{my: 'auto', flexGrow: '1'}}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: {
                                xs: 'column-reverse',
                                sm: 'row',
                            },
                            mb: 3,
                        }}
                    >
                        <Box>
                            <Typography
                                gutterBottom
                                sx={{
                                    fontFamily: theme.typography.fontFamily,
                                    fontWeight: theme.typography.fontWeight.bold,
                                    color: theme.palette.primary.navy,
                                    mb: 0,
                                    fontSize: {
                                        xs: '25px',
                                        sm: '32px',
                                    },
                                }}
                            >
                                {quizName}
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: theme.typography.fontFamily,
                                    fontSize: theme.typography.h6,
                                }}
                                gutterBottom
                            >
                                Answer the question below
                            </Typography>
                        </Box>
                        <Box>
                            <Stack direction="row">
                                <Button labe variant="outlined" color="error"
                                        sx={{
                                            fontFamily: theme.typography.fontFamily,
                                            textTransform: 'capitalize',
                                            fontSize: '.8rem',
                                            p: '.2rem .5rem',
                                        }}
                                        onClick={() => setOpenAlertDialog({
                                            message: 'Are you sure you want to cancel this quiz?',
                                            buttonLabel: 'Yes',
                                            func: handleCancelQuiz,
                                        })}
                                        startIcon={<CancelIcon />}
                                >Cancel Quiz</Button>
                                {timeLeft >= 0 && <Typography
                                    paragraph
                                    sx={{
                                        fontFamily: theme.typography.fontFamily,
                                        fontWeight: theme.typography.fontWeight.bold,
                                        mb: 0,
                                        ml: 2,
                                        fontSize: {
                                            xs: '14px',
                                            sm: '18px',
                                        },
                                        color: timeLeft <= 60 ? 'red' : 'gray',
                                    }}
                                >
                                    {`Time Left: ${formatTime(timeLeft)}`}
                                </Typography>
                                }
                            </Stack>
                            <Typography
                                gutterBottom
                                sx={{
                                    fontFamily: theme.typography.fontFamily,
                                    fontSize: theme.typography.h6,
                                    textAlign: 'end',
                                    marginRight: '5px',
                                }}
                            >
                                Question {currentQuestion + 1}/{quizData?.length}
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: {
                                xs: 'column',
                                sm: 'row',
                            },
                        }}
                    >
                        <Box
                            sx={{
                                width: {
                                    xs: '100%',
                                    sm: '50%',
                                },
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: theme.typography.subtitle2,
                                    fontFamily: theme.typography.fontFamily,
                                    fontWeight: theme.typography.fontWeight.medium,
                                }}
                                paragraph
                            >
                                Question:
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: theme.typography.title,
                                    fontFamily: theme.typography.fontFamily,
                                    color: theme.palette.primary.black,
                                    fontWeight: theme.typography.fontWeight.bold,
                                }}
                                paragraph
                            >
                                {quizData[currentQuestion]?.question}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                width: {
                                    xs: '100%',
                                    sm: '50%',
                                },
                            }}
                        >
                            <FormControl component="fieldset">
                                <Typography
                                    sx={{
                                        marginLeft: {xs: 0, sm: 5},
                                        fontSize: theme.typography.subtitle2,
                                        fontFamily: theme.typography.fontFamily,
                                        fontWeight: theme.typography.fontWeight.medium,
                                    }}
                                    paragraph
                                >
                                    Select answer:
                                </Typography>
                                <RadioGroup
                                    sx={{marginLeft: {xs: 0, sm: 5}}}
                                    name="options"
                                    value={selectedOptions[currentQuestion]}
                                    onChange={handleOptionChange}
                                >
                                    {quizData[currentQuestion] && (
                                        (quizData[currentQuestion]?.options || []).map(
                                            (option, index) => (
                                                <FormControlLabel
                                                    key={index}
                                                    value={option}
                                                    control={

                                                        <Radio

                                                            style={{
                                                                color: theme.palette.primary.babyblue,
                                                                '&:hover': {
                                                                    backgroundColor: 'transparent !important',
                                                                },
                                                                '&.MuiChecked': {
                                                                    backgroundColor: 'transparent',
                                                                },
                                                            }}
                                                        />
                                                    }
                                                    label={
                                                        <Typography
                                                            sx={{
                                                                minWidth: 100,
                                                                fontFamily: theme.typography.fontFamily,
                                                                fontSize: theme.typography.subtitle2,
                                                                fontWeight: theme.typography.fontWeight.semiBold,
                                                                color: theme.palette.primary.black,
                                                                m: 1,
                                                            }}
                                                        >
                                                            {option}
                                                        </Typography>
                                                    }
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'flex-start', // Align the label with the top
                                                        flexDirection: 'row', // Display text as a column
                                                    }}
                                                />
                                            ),
                                        ))}
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    </Box>
                </CardContent>
                <Box p={2} sx={{display: 'flex', justifyContent: 'center', mb: 3}}>
                    <Button
                        variant="contained"
                        sx={{
                            fontFamily: theme.typography.fontFamily,
                            bgcolor: theme.palette.primary.babyblue,
                            '&:hover': {
                                bgcolor: theme.palette.primary.navy,
                            },
                            borderRadius: 5,
                            fontWeight: 'bold',
                            ml: 2,
                            mr: 2,
                        }}
                        onClick={handlePreviousQuestion}
                        disabled={currentQuestion === 0}

                    >
                        Previous
                    </Button>
                    {currentQuestion < quizData?.length - 1 ? (
                        <Button
                            variant="contained"
                            sx={{
                                fontFamily: theme.typography.fontFamily,
                                bgcolor: theme.palette.primary.babyblue,
                                '&:hover': {
                                    bgcolor: theme.palette.primary.navy,
                                },
                                borderRadius: 5,
                                ml: 2,
                                width: '100px',
                                fontWeight: 'bold',
                            }}
                            onClick={handleNextQuestion}
                            disabled={selectedOptions[currentQuestion] === ''}

                        >
                            Next
                        </Button>
                    ) : (
                        <Button
                            variant="contained"
                            sx={{
                                fontFamily: theme.typography.fontFamily,
                                bgcolor: theme.palette.primary.babyblue,
                                '&:hover': {
                                    bgcolor: theme.palette.primary.navy,
                                },

                                borderRadius: 5,
                                fontWeight: 'bold',

                                ml: 2,
                                width: '100px',
                            }}
                            onClick={() => setOpenAlertDialog({
                                message: 'Are you sure you want to submit?',
                                buttonLabel: 'Submit',
                                func: handleSubmitQuiz,
                            })}
                            disabled={
                                selectedOptions[currentQuestion] === ''
                            }
                        >
                            Submit
                        </Button>
                    )}
                </Box>
                {!!openAlertDialog && (
                    <AlertDialog
                        open={!!openAlertDialog}
                        setOpen={() => setOpenAlertDialog(null)}
                        message={openAlertDialog.message}
                        onConfirm={openAlertDialog.func}
                        primaryButtonLabel={openAlertDialog.buttonLabel}
                    />
                )}
                {openResultModal && (
                    <ResultModal
                        open={openResultModal}
                        setOpen={setOpenResultModal}
                        quizData={newData}
                        quizId={quizId}
                        showScore={showScore}
                        showResult={showResult}
                    />
                )}
            </Card>
        </div>
    );
}

export default QuizComponent;
