import { createTheme } from "@mui/material";

let theme = createTheme({
  typography: {
    fontFamily: 'Noto Sans, sans-serif',
    fontWeight: {
      thin:100,
      extraLight:200,
      light:300,
      regular: 400,
      medium: 500,
      semiBold:600,
      bold: 700,
    },
    large: 20,
    title: 18,
    subtitle1: 16,
    subtitle2: 14,
    h6: 13,
    h7: 12,
    h8: 11,
    h9:10,
    lineBreak: { fontSize: "0.4rem" },
  },
  palette: {
    primary: {
      main: "#386641",
      main_1: "#6A994E",
      main_2: "#A7C957",
      red:"#B22222",
      black:"#353935",
      babyblue: "#ADC4CE",
      grey: "#ddd",
      navy:"#1F4172",
      lightBlue:"#cadbed",
      darkGrey:"#808080",
      green:"#508D69",
      purple:"#DBDFEA",
      lightAqua: "#d0e1e0",
      white:"#fff"
    },
    secondary: {
      main: "#F2E8CF",
      lightGreen: "#6C8C70",
      lightBlue: "#E0F4FF",
      lightBeige: "#FAFAF5", //F1F0E8
      gold: "#F2B600",
      grey: "#F2F1EB",
      lightGrey: "#ced4da",
    },
  },
});

export default theme;
