import * as React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import theme from "../config/theme"
export default function Menu({ menu, index,indexIcons, open, selectedMenu, handleMenuItemClick }) {
    return (
        <div>
        <ListItem
                  className="menuList"
                  key={menu}
                  disablePadding
                  sx={{
                    fontFamily:theme.typography.fontFamily,
                    fontSize:theme.typography.subtitle2,
                    fontWeight:theme.typography.fontWeight.medium,
                    display: "block",
                    backgroundColor:
                      selectedMenu === index ? "#F5F5F5" : "transparent", // Change background color when selected
                    "&:hover": {
                      fontWeight: theme.typography.fontWeight.semiBold,
                      backgroundColor: "#F0FF5F5F50F0", // Change background color on hover
                    },
                  }}
                  onClick={() => handleMenuItemClick(index)}
                >
                  <ListItemButton
                    disableTouchRipple
                    sx={{
                      minHeight: 50,
                      justifyContent: open ? "initial" : "initial",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      style={{ color: theme.palette.primary.navy }} //menu font color
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {indexIcons[index] ? (
                        <img
                          src={indexIcons[index].icon}
                          alt={indexIcons[index].alt}
                        />
                      ) : null}
                      <ListItemText
                        disableTypography
                        primary={
                            <Typography variant="bodySideBar">
                              {menu}
                            </Typography>
                          // )
                        }
                        sx={{
                          opacity: open ? 1 : 0,
                          px: 3,
                        }}
                      />
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>
                </div>
    )
}

