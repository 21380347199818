import * as React from "react";
import { Box, Button } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Outlet, useNavigate } from "react-router-dom";
import questionsIcon from "../assets/icons/questions.svg";
import quizIcon from "../assets/icons/quiz.svg";
import dashboardIcon from "../assets/icons/dashboard.svg"
import admin from "../assets/icons/admin.svg";
import logoIcon from "../assets/icons/logoIcon.svg";
import logoutIcon from "../assets/icons/logout.svg";
import { useLogoutMutation } from "../redux/features/user/userApi";
import {
  getFromLocalStorage,
  removeFromLocalStorage,
} from "../utils/localStorage";
import Navbar from "./Navbar";
import MuiDrawer from "@mui/material/Drawer";
import { styled } from "@mui/material";
import MenuIcon from "../assets/icons/menuIcon.svg";
import MenuOpenIcon from "../assets/icons/menuOpenIcon.svg";
import Typography from "@mui/material/Typography";
import Snackbar from "@mui/material/Snackbar";
import SnackAlert from "@mui/material/Alert";
import theme from "../config/theme";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Menu from "../components/Menu";
import { CssBaseline } from "@mui/material";
const drawerWidth = 200;
const drawerExpended = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});
const drawerCollapsed = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(8)} + 3px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 1,
  ...(open && {
    ...drawerExpended(theme),
    "& .MuiDrawer-paper": drawerExpended(theme),
  }),
  ...(!open && {
    ...drawerCollapsed(theme),
    "& .MuiDrawer-paper": drawerCollapsed(theme),
  }),
}));
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}));
export default function SideNav() {
  const [open, setOpen] = React.useState(false);
  const [selectedMenu, setSelectedMenu] = React.useState();

  // * * * * * * * * * * * * User Logout API * * * * * * * * * * * *
  const [logout, { data, isError }] = useLogoutMutation();
  const [alert, setAlert] = React.useState(false);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const navigate = useNavigate();
  const user = JSON.parse(getFromLocalStorage("user"));
  const [logoutInitiated, setLogoutInitiated] = React.useState(false);
  const handleLogoutConfirmed = () => {
    logout({ refreshToken: getFromLocalStorage("refresh-token") });
    setLogoutInitiated(false);
    sessionStorage.setItem("alertShown", "true");
    setAlertOpen(false);
  };

  const handleCancelLogout = () => {
    setLogoutInitiated(false);
    setAlertOpen(false);
  };
  const handleLogout = () => {
    setLogoutInitiated(true);
    setAlert("Are you sure you want to logout?");
    setAlertOpen(true);
  };

  //  * * * * * * * * * * * Notify and redirect user * * * * * * * * * * * * *
  React.useEffect(() => {
    // console.log(data);
    if (data?.status === "success") {
      removeFromLocalStorage("token");
      removeFromLocalStorage("user");
      window.location.href = "/login";
    } else if (isError) {
      setAlertOpen(true);
      setAlert("Logout unsuccessful!");
    }
  }, [data, isError]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };
  const userRole = user?.role; // Get the user's role
  let menuItems = [];
  let indexIcons = [];

  if (userRole === "superadmin") {
    menuItems = ["Dashboard","Questions", "Quiz", "Admin"];
    indexIcons = [
      { icon: dashboardIcon, alt: "dashboardIcon" },
      { icon: questionsIcon, alt: "questionsIcon" },
      { icon: quizIcon, alt: "quizIcon" },
      { icon: admin, alt: "adminIcon" },
    ];
  } else {
    menuItems = ["Dashboard","Questions", "Quiz"];
    indexIcons = [
      { icon: dashboardIcon, alt: "dashboardIcon" },
      { icon: questionsIcon, alt: "questionsIcon" },
      { icon: quizIcon, alt: "quizIcon" },
    ];
  }

  const handleLogoClick = () => {
    setSelectedMenu(null);
    navigate("/");
  };
  const handleDrawerOpen = () => {
    setOpen(true);
    document.body.classList.add("no-scroll");
    setSelectedMenu(null);
  };
  const handleDrawerClose = () => {
    setOpen(false);
    document.body.classList.remove("no-scroll");
    setSelectedMenu(null);
  };

  const handleMenuItemClick = (index) => {
    setSelectedMenu(index);
    let path = `/${menuItems[index]}`;
    if (menuItems[index] === "Dashboard") {
      path = "/";
    }
    if (menuItems[index] === "Logout") {
      handleLogout();
    } else navigate(path);
  };

  return (
    <div>
      <CssBaseline />
      <Navbar />
      <Snackbar
        open={alertOpen}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {logoutInitiated ? (
          <SnackAlert
            sx={{ borderRadius: 5, fontFamily: theme.typography.fontFamily }}
            severity="error"
            onClose={handleCloseSnackbar}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                fontWeight: theme.typography.fontWeight.medium,
              }}
            >
              {alert}
              <Box display="flex" justifyContent="space-around" sx={{ mt: 1 }}>
                <Box>
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                      color: theme.palette.primary.main,
                      fontFamily: theme.typography.fontFamily,
                      fontSize: theme.typography.h8,
                      fontWeight: theme.typography.fontWeight.medium,
                      borderRadius: 15,
                      backgroundColor: "#f7d7db",
                      ":hover": { bgcolor: "#FFBFBF" },
                    }}
                    onClick={handleLogoutConfirmed}
                  >
                    Yes
                  </Button>
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                      ml: 1,
                      fontFamily: theme.typography.fontFamily,
                      fontSize: theme.typography.h8,
                      fontWeight: theme.typography.fontWeight.medium,
                      color: theme.palette.primary.main,
                      borderRadius: 15,
                      backgroundColor: "#FFF",
                      ":hover": { bgcolor: "#A6D0DD" },
                    }}
                    onClick={handleCancelLogout}
                  >
                    No
                  </Button>
                </Box>
              </Box>
            </div>
          </SnackAlert>
        ) : (
          <SnackAlert severity={"error"} onClose={handleCloseSnackbar}>
            {alert}
          </SnackAlert>
        )}
      </Snackbar>

      <Box sx={{ display: "flex" }}>
        <Drawer
          sx={{
            flexShrink: 0,
            display: {
              xs: "none",
              sm: "none",
              md: "block",
            },
            "& .MuiDrawer-paper": {
              backgroundColor: "#fff",
              // backgroundImage: "linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)",             borderRadius: "0px 25px 25px 0px",
              boxShadow: "1px 1px 2px #ddd",
            },
          }}
          variant="permanent"
          open={open}
          anchor="left"
        >
          <Box
            sx={{
              pt: 2,
              ml: 1.4,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <ListItemIcon
              onClick={handleLogoClick}
              sx={{
                cursor: "pointer",
                marginTop: "2px",
              }}
            >
              {open ? (
                // SuperQuiz logo
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginLeft: "2px",
                  }}
                >
                  <img src={logoIcon} alt="logo" style={{ width: "23.8%" }} />
                  <Typography
                    sx={{
                      fontFamily: theme.typography.fontFamily,
                      fontSize: theme.typography.subtitle2,
                      color: "#2E4F4F",
                      fontWeight: theme.typography.fontWeight.bold,
                      marginLeft: "16px",
                    }}
                  >
                    SuperQuiz
                  </Typography>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginLeft: "2px",
                  }}
                >
                  <img src={logoIcon} alt="logo" style={{ width: "50.5%" }} />
                  <Typography sx={{ color: "#2E4F4F" }}></Typography>
                </Box>
              )}
            </ListItemIcon>
          </Box>
          {/* ==========SuperQuiz Icon ends */}
          <List
            sx={{
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            {/* ---------------Open/Close Drawer Icons-----------------Start- */}
            <List sx={{ paddingTop: 0, paddingBottom: 0 }}>
              <ListItem
                disablePadding
                onClick={handleDrawerOpen}
                sx={{
                  backgroundColor: "transparent",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                  minHeight: 48,
                  alignItems: "center",
                  justifyContent: open ? "center" : "center",
                  display: open ? "none" : "block",
                }}
              >
                <ListItemButton
                  sx={{
                    ml: 0.5,
                    minHeight: 50,
                    justifyContent: open ? "initial" : "initial",
                    px: 2.5,
                    backgroundColor: "transparent",
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  <img src={MenuIcon} alt="menuIcon" />
                </ListItemButton>
              </ListItem>
              <ListItem
                disablePadding
                onClick={handleDrawerClose}
                sx={{
                  backgroundColor: "transparent",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                  minHeight: 48,
                  alignItems: "center",
                  justifyContent: open ? "center" : "center",
                  display: open ? "block" : "none",
                }}
              >
                <ListItemButton
                  disableTouchRipple
                  sx={{
                    ml: 0.5,
                    backgroundColor: "transparent",
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                    minHeight: 50,
                    justifyContent: open ? "initial" : "initial",
                    px: 2.5,
                  }}
                >
                  <img src={MenuOpenIcon} alt="menuOpenIcon" />
                </ListItemButton>
              </ListItem>
            </List>
            {/* ---------------Open/Close Drawer Icons-------End- */}
            {/* ----------------Menu List----------------start--- */}
            {menuItems.map((menu, index) => (
              <List key={menu} sx={{ paddingTop: 0, paddingBottom: 0 }}>
                {open ? (
                  <Menu
                    menu={menu}
                    index={index}
                    indexIcons={indexIcons}
                    open={open}
                    selectedMenu={selectedMenu}
                    handleMenuItemClick={handleMenuItemClick}
                  />
                ) : (
                  <LightTooltip
                    title={menu}
                    key={menu}
                    placement="bottom-start"
                    followCursor
                  >
                    <div>
                      <Menu
                        menu={menu}
                        index={index}
                        indexIcons={indexIcons}
                        open={open}
                        selectedMenu={selectedMenu}
                        handleMenuItemClick={handleMenuItemClick}
                      />
                    </div>
                  </LightTooltip>
                )}
              </List>
            ))}
          </List>
          {/* Logout starts */}
          <ListItem
            disablePadding
            sx={{
              fontSize: theme.typography.h6,
              display: "block",
              fontWeight: "550",
              "&:hover": {
                fontWeight: "bolder",
                backgroundColor: "#F0FF5F5F50F0", // Change background color on hover
              },
            }}
            onClick={() => handleLogout()}
          >
            <ListItemButton
              disableTouchRipple
              sx={{
                marginBottom: "20px",
                minHeight: 50,
                justifyContent: open ? "initial" : "initial",
                pl: 2.5,
              }}
            >
              <ListItemIcon
                style={{ color: theme.palette.primary.navy }} //menu font color
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img src={logoutIcon} alt={"logoutIcon"} />
                <ListItemText
                  disableTypography
                  primary={
                    <Typography
                      sx={{
                        fontFamily: theme.typography.fontFamily,
                        fontSize: theme.typography.subtitle2,
                        fontWeight: theme.typography.fontWeight.medium,
                        color: theme.palette.primary.red,
                        "&:hover": {
                          fontWeight: theme.typography.fontWeight.semiBold,
                        },
                      }}
                    >
                      Logout
                    </Typography>
                  }
                  sx={{
                    fontSize: theme.typography.subtitle2,
                    fontWeight: "550",
                    opacity: open ? 1 : 0,
                    px: 3,
                  }}
                />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
          {/* Logout ends */}
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1 }}>
          <Outlet />
        </Box>
      </Box>
    </div>
  );
}
