import * as React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Bar } from "react-chartjs-2";
import theme from "../config/theme";

function QuizOverview({ Item, data, isLargeScreen }) {
  const maxItem = (data?.leaderboard_summary || []).reduce(
    (max, item, index) => {
      const currentLeaderboardLength = item.leaderboard_length;

      if (currentLeaderboardLength > max.leaderboard_length) {
        return { ...item };
      }

      return max;
    },
    { leaderboard_length: 0, index: -1 }
  );

  const barChart = {
    labels: ["External", "Internal "], // Use a non-breaking space character directly
    datasets: [
      {
        data: [
          data?.total_external_user_count,
          data?.total_internal_user_count,
        ],
        backgroundColor: [
          theme.palette.secondary.lightGreen,
          theme.palette.primary.navy,
        ],
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        caretSize: 5,
        titleColor: theme.palette.primary.darkGrey,
        bodyColor: theme.palette.primary.darkGrey,
        backgroundColor: theme.palette.primary.white,
        borderColor: theme.palette.primary.darkGrey,
        borderWidth: 0.5,
      },
      title: {
        display: true,
        text: "Total Users",
        color: theme.palette.primary.black,
        font: {
          size: 10,
          weight: "bold",
        },
      },
      legend: {
        display: false,
        position: isLargeScreen ? "right" : "bottom",
        labels: {
          boxWidth: 10,
          color: theme.palette.primary.black,
        },
      },
    },
    scales: {
      x: {
        display: false, // Hide x-axis
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <>
      <Box
        sx={{
          px: 3,
          pt: 0.5,
        }}
      >
        <Typography
          sx={{
            fontSize: theme.typography.subtitle2,
            fontWeight: theme.typography.fontWeight.semiBold,
            color: theme.palette.primary.darkGrey,
            textDecoration: `underline dotted ${theme.palette.primary.navy};`,
          }}
        >
          Quiz & User Overview
        </Typography>
      </Box>
      <Box sx={{ mx: 3, pt: 0.5, flexGrow: 1 }}>
        <Grid container spacing={{ xs: 2, sm: 2, md: 3 }} columns={{ xs: 8 }}>
          <Grid item xs={4} sm={4} md={4}>
            <Item>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                height="100%"
              >
                <Typography
                  sx={{
                    fontFamily: theme.typography.fontFamily,
                    fontSize: theme.typography.h8,
                    fontWeight: theme.typography.fontWeight.semiBold,
                    color: theme.palette.primary.darkGrey,
                    mb: 1,
                  }}
                >
                  Total Quizzes:
                </Typography>
                <Typography
                  sx={{
                    fontSize: theme.typography.large,
                    fontWeight: theme.typography.fontWeight.bold,
                    color: theme.palette.primary.navy,
                  }}
                  textAlign="center"
                >
                  {data?.total_quizzes}
                </Typography>
              </Box>
            </Item>
          </Grid>
          <Grid item xs={4} sm={4} md={4}>
            <Item>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                height="100%"
              >
                <Typography
                  sx={{
                    fontFamily: theme.typography.fontFamily,
                    fontSize: theme.typography.h8,
                    fontWeight: theme.typography.fontWeight.semiBold,
                    color: theme.palette.primary.darkGrey,
                    mb: 1,
                  }}
                >
                  Most Attempted Quiz:
                </Typography>

                <Typography
                  sx={{
                    fontSize: theme.typography.h6,
                    fontWeight: theme.typography.fontWeight.bold,
                    color: theme.palette.primary.navy,
                  }}
                >
                  {maxItem.quiz_name}
                </Typography>
                <Typography
                  sx={{
                    mt: 1,
                    fontSize: theme.typography.h7,
                    fontWeight: theme.typography.fontWeight.medium,
                    color: theme.palette.primary.darkGrey,
                  }}
                >
                  {maxItem.leaderboard_length} times
                </Typography>
              </Box>
            </Item>
          </Grid>
          <Grid item xs={4} sm={4} md={4}>
            <Item>
              <Bar data={barChart} options={options} />
            </Item>
          </Grid>
          <Grid item xs={4} sm={4} md={4}>
            <Item>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                height="100%"
              >
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Typography
                    sx={{
                      fontSize: theme.typography.h7,
                      fontWeight: theme.typography.fontWeight.bold,
                      color: theme.palette.primary.darkGrey,
                    }}
                  >
                    Superadmin:
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: theme.typography.h6,
                      fontWeight: theme.typography.fontWeight.bold,
                      color: theme.palette.primary.navy,
                    }}
                  >
                    {data?.superadmin_count}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Typography
                    sx={{
                      fontSize: theme.typography.h7,
                      fontWeight: theme.typography.fontWeight.bold,
                      color: theme.palette.primary.darkGrey,
                    }}
                  >
                    Admin:
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: theme.typography.h6,
                      fontWeight: theme.typography.fontWeight.bold,
                      color: theme.palette.primary.navy,
                    }}
                  >
                    {data?.admin_count}
                  </Typography>
                </Box>
              </Box>
            </Item>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default QuizOverview;
