import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import { useLoginMutation } from "../redux/features/user/userApi";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { saveToLocalStorage } from "../utils/localStorage";
import { IconButton, InputAdornment } from "@mui/material";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import theme from "../config/theme";
import LeftPage from "../components/LeftPage";
import Copyright from "../components/Copyright";
import CssTextField from "../components/CssTextField";
import Snackbar from "@mui/material/Snackbar";
import SnackAlert from "@mui/material/Alert";
import logoIcon from "../assets/icons/logoIcon.svg";
import ResetPassword from "../components/ResetPassword";

const hideLeftPageBelow600px = {
  "@media (max-width: 600px)": {
    display: "none",
  },
};
const Login = () => {
  // * * * * * * * * * * * * User Login API * * * * * * * * * * * *
  const [login, { data, isError, isLoading, isSuccess, error }] =
    useLoginMutation();
  // console.log(data)
  // * * * * * * * * * * * Hooks * * * * * * * * * * *
  const navigate = useNavigate();
  // const location = useLocation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // * * * * * * * * * * * States * * * * * * * * * * *
  const [showPassword, setShowPassword] = useState(false);
  // const from = location.state?.from?.pathname || "/";
  const from = JSON.parse(sessionStorage.getItem("from"))?.pathname || "/";

  // * * * * * * * * * * * Functions * * * * * * * * * * *
  const onSubmit = (data) => {
    login(data);
  };
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [alert, setAlert] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [severity, setSeverity] = useState("error");

  //  * * * * * * * * * * * Notify and redirect user * * * * * * * * * * * * *
  useEffect(() => {
    // console.log(data?.csrf_token)
    // console.log(data?.access_token)
    if (isSuccess && data) {
      // console.log(data,'data');
      const user = {
        email: data?.email,
        firstName:data?.first_name,
        role: data?.role,
      };
      saveToLocalStorage("csrfToken", data?.csrf_token);
      saveToLocalStorage("token", data?.access_token);
      saveToLocalStorage("refresh-token", data?.refresh_token);
      saveToLocalStorage("user", JSON.stringify(user));
      sessionStorage.removeItem("alertShown");
      sessionStorage.setItem("alertShown", "true");
      // navigate to the home route
      navigate(from, {
        state: {
          alertMsg: "You have logged in successfully",
          open: true,
        },
      });
    } else if (isError && error?.data) {
      setAlertOpen(true);
      setSeverity('error')
      setAlert("Incorrect email or password!");
    }
  }, [data, isError, isSuccess, error,from, navigate]);
  const handleCloseSnackbar = (event, reason) => {
      setAlertOpen(false);
  };
  const handleForgotPassword = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main">
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={7}
          md={7}
          lg={7}
          sx={{
            ...hideLeftPageBelow600px,
            py: 1,
            background:
              "linear-gradient(127deg, rgba(215,232,247,1) 0%, rgba(236,247,241,1) 71%, rgba(255,255,255,1) 100%)",
            display: {
              xs: "none",
              sm: "grid",
              md: "grid",
              lg: "grid",
            },
            alignItems: "center",
          }}
        >
          <LeftPage />
        </Grid>
        <Grid item xs={12} sm={5} md={5} lg={5} component={Paper} elevation={0}>
          <Box
            sx={{
              ml: 4,
              mr: 4,
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              alignItems: "space-between", // Center content vertically
              justifyContent: "center",
            }}
          >
            <Snackbar
              open={alertOpen}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              autoHideDuration={5000}  
              onClose={handleCloseSnackbar}
            >
              <SnackAlert
                sx={{ fontFamily: theme.typography.fontFamily }}
                severity={severity}
                onClose={handleCloseSnackbar}
              >
                {alert}
              </SnackAlert>
             
            </Snackbar>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "center",
              }}
            >
              <img src={logoIcon} alt="logoIcon" style={{ height: "3rem" }} />
              <Typography
                variant="subtitle"
                sx={{ color: "#2E4F4F", mt: "0.5rem" }}
              >
                SuperQuiz
              </Typography>
            </Box>

            <Typography
              sx={{
                fontSize: theme.typography.subtitle1,
                fontWeight: theme.typography.fontWeight.medium,
                color: theme.palette.primary.black,
                mt: "0.5rem",
              }}
            >
              Login to your account
            </Typography>

            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit(onSubmit)}
              sx={{
                mt: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                width: {
                  xs: "100%",
                  sm: "100%",
                },
              }}
            >
              <CssTextField
                margin="normal"
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                size="medium"
                sx={{ marginBottom: "1em" }}
                {...register("email", { required: true })}
              />
              {errors.email && (
                <Typography sx={{ color: "red" }} variant="subtitle1">
                  Please enter your email
                </Typography>
              )}
              <CssTextField
                size="medium"
                margin="normal"
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                InputProps={{
                  // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        size="small"
                        sx={{
                          color: "#8CA690",
                        }}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...register("password", { required: true })}
              />
              {errors.password && (
                <Typography sx={{ color: "red" }} variant="subtitle1">
                  Please enter your password
                </Typography>
              )}

              <Box sx={{ ml: "auto" }}>
                <Typography
                  onClick={handleForgotPassword}
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                    },
                    px: 1,
                    fontSize: theme.typography.h6,
                    color: theme.palette.primary.black,
                    textDecoration: "none",
                    fontWeight: theme.typography.fontWeight.bold,
                  }}
                >
                  Forgot password?
                </Typography>
              </Box>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={isLoading}
                sx={{
                  whiteSpace: "nowrap",
                  mt: 3,
                  mb: 2,
                  borderRadius: 15,
                  fontWeight: "bold",
                  bgcolor: "#9ABEBC",
                  "&:hover": {
                    bgcolor: " #d0e1e0",
                  },
                }}
              >
                Login
              </Button>
              <Grid container justifyContent="center">
                <div>
                  <Typography
                    sx={{
                      fontSize: theme.typography.h7,
                      color: theme.palette.primary.black,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Don't have an account?{" "}
                    <Link
                      to="/register"
                      style={{
                        marginLeft: "0.5em",
                        fontSize: theme.typography.h6,
                        color: theme.palette.primary.black,
                        fontWeight: theme.typography.fontWeight.bold,
                        textDecoration: "none",
                      }}
                    >
                      Register here
                    </Link>
                  </Typography>
                </div>
              </Grid>
              <Grid container justifyContent="center" gap={2}>
                <Typography
                  sx={{
                    pt: "1em",
                    fontSize: theme.typography.h8,
                    color: theme.palette.primary.black,
                    fontWeight: theme.typography.fontWeight.bold,
                  }}
                >
                  <a href="mailto:contact@strive2thrive.earth">
                    Report an issue
                  </a>
                </Typography>
                <Typography
                  sx={{
                    pt: "1em",
                    fontSize: theme.typography.h8,
                    color: theme.palette.primary.black,
                    fontWeight: theme.typography.fontWeight.bold,
                  }}
                >
                  <a href="https://docs.google.com/forms/d/e/1FAIpQLSfWk8hYTGRg1j649chAiULXf42_5Ypr6kQ7-8qKFMUPxS4ylQ/viewform?usp=pp_url">
                    Provide Feedback
                  </a>
                </Typography>
                <Typography
                  sx={{
                    pt: "1em",
                    fontSize: theme.typography.h8,
                    color: theme.palette.primary.black,
                    fontWeight: theme.typography.fontWeight.bold,
                  }}
                >
                  <a href="https://docs.google.com/forms/d/e/1FAIpQLSdhD_w8kis4umQpafEMbF2HjwksuGcifcsXSfy2P9M8oS5j1w/viewform?usp=sf_link">
                    Share your ideas
                  </a>
                </Typography>
              </Grid>
              <Copyright />
            </Box>
          </Box>
        </Grid>
      </Grid>
      {openDialog && 
      <ResetPassword
      open={openDialog}
      handleCloseDialog={handleCloseDialog}
      setAlertOpen={setAlertOpen}
      setAlert={setAlert}
      setSeverity={setSeverity}
      setOpenDialog={setOpenDialog}/>}
    </ThemeProvider>
  );
};

export default Login;
