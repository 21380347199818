import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import {
    Button,
    IconButton,
    Typography,
    ThemeProvider,
    Switch,
    styled, Stack, FormControl, InputLabel,
    FormHelperText,
    FormGroup, FormLabel, FormControlLabel,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {useForm} from 'react-hook-form';
import {useEffect, useState} from 'react';
import {toast} from 'react-hot-toast';
import {useCreateQuizMutation} from '../redux/features/quiz/quizApi';
import theme from '../config/theme';
import CssTextField from '../components/CssTextField';
import Loading from './Loading';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        xs: '96vw',
        sm: '40vw',
    },
    maxHeight: '85vh',
    overflow: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '8px',
};

const CreateQuizModal = ({
                             open,
                             handleClose,
                             selectedQuestions,
                             onSuccess,
                         }) => {
    // * * * * * * * * * * * * Create Job Post API * * * * * * * * * * * *
    const [createQuiz, {data, isError, isLoading, isFetching, isSuccess, error}] =
        useCreateQuizMutation();

    const {
        register,
        handleSubmit,
        formState: {errors},
        watch,
        reset,
    } = useForm();

    const onSubmit = (data) => {
        const newData = {
            questions: selectedQuestions.map((item) => ({
                question: item.question,
                options: item.options,
                answer: item.answer,
            })),
            quiz_name: data.quiz_name,
            show_result: data.show_result,
            show_score: data.show_score,
            randomize_questions: data.randomize_questions,
        };
        if (data.enable_countdown === false) {
            newData.quiz_time = -1;
        } else {
            newData.quiz_time = Number(data.quiz_time);
        }

        // * * * * create quiz API * * * *
        createQuiz(newData);

    };

    useEffect(() => {
        reset();
    }, []);

    //  * * * * * * * * * * * Notify user * * * * * * * * * * * * *
    useEffect(() => {
        if (isSuccess && data) {
            onSuccess(data);
            handleClose();
        } else if (isError && error) {
            toast.error('error', error);
        }
    }, [isError, isSuccess]);
    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
        >
            <ThemeProvider theme={theme}>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    slots={{backdrop: Backdrop}}
                    slotProps={{
                        backdrop: {
                            timeout: 400,
                        },
                    }}
                >
                    <Fade in={open}>
                        <Box sx={style}>
                            <Box
                                component="form"
                                noValidate
                                sx={{
                                    p: {
                                        xs: 2,
                                        sm: 4,
                                    },
                                    pt: {
                                        xs: 5,
                                        sm: 4,
                                    },
                                    position: 'relative',
                                }}
                            >
                                {/* close icon */}
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        right: 0,
                                        mt: 0.5,
                                        mr: 0.5,
                                    }}
                                >
                                    <IconButton size="small" onClick={handleClose}>
                                        <CloseIcon sx={{color: 'gray'}}/>
                                    </IconButton>
                                </Box>
                                {/* close icon end*/}
                                <section className="mb-5 mt-2">

                                    <Box display="flex" justifyContent="space-between">
                                        <Typography
                                            sx={{
                                                color: theme.palette.primary.darkGrey,
                                                fontSize: theme.typography.h8,
                                                fontWeight: theme.typography.fontWeight.regular,
                                            }}
                                        >
                                            SELECTED QUESTIONS
                                        </Typography>
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                color: theme.palette.primary.black,
                                                fontSize: theme.typography.h8,
                                                fontWeight: theme.typography.fontWeight.bold,
                                            }}
                                        >
                                            Total selected questions : {selectedQuestions.length}
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            mt: 0.5,
                                            border: '1px solid',
                                            borderRadius: 5,
                                            py: 0.5,
                                            borderColor: 'rgb(50,86,60,29%)',
                                        }}
                                    >
                                        {selectedQuestions?.map((item, index) => (
                                            <Box key={index} display="flex" sx={{pt: 0.5}}>
                                                <Typography
                                                    sx={{
                                                        ml: 1.8,
                                                        fontSize: theme.typography.h8,
                                                        fontWeight: theme.typography.fontWeight.regular,
                                                        color: theme.palette.primary.black,
                                                    }}
                                                >
                                                    {index + 1}.
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        mb: '5px',
                                                        ml: 1,
                                                        mr: 1,
                                                        fontSize: theme.typography.h8,
                                                        fontWeight: theme.typography.fontWeight.regular,
                                                        color: theme.palette.primary.black,
                                                    }}
                                                >
                                                    {item.question}
                                                </Typography>
                                            </Box>
                                        ))}
                                    </Box>
                                </section>
                                <FormControl className="!mb-5 w-full" error={!!errors.quiz_name}>
                                    <Typography
                                        sx={{
                                            color: theme.palette.primary.darkGrey,
                                            fontSize: theme.typography.h8,
                                            fontWeight: theme.typography.fontWeight.regular,
                                        }}
                                    >
                                        QUIZ NAME
                                    </Typography>
                                    <CssTextField
                                        sx={{
                                            mt: '3px',
                                        }}
                                        id="quiz_name"
                                        placeholder="Enter quiz name"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        {...register('quiz_name', {required: true})}
                                    />

                                    {errors.quiz_name && (
                                        <FormHelperText error={!!errors.quiz_name}
                                                        sx={{
                                                            fontSize: theme.typography.h9,
                                                            fontWeight: theme.typography.fontWeight.medium,
                                                        }}>Please enter the quiz name</FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl className="!mb-5 w-full" component="fieldset" variant="standard">
                                    <FormLabel component="legend"
                                               sx={{
                                                   display: 'flex',
                                                   color: theme.palette.primary.darkGrey,
                                                   fontSize: theme.typography.h8,
                                                   fontWeight: theme.typography.fontWeight.regular,
                                               }}
                                    >FINAL SCORE</FormLabel>
                                    <Switch
                                        size="medium"
                                        {...register('show_score')}
                                    />

                                    <Typography
                                        sx={{
                                            fontSize: theme.typography.h9,
                                            fontWeight: theme.typography.fontWeight.medium,
                                            color: theme.palette.primary.navy,
                                        }}
                                    >
                                        Hide final score will hide result summary
                                    </Typography>

                                </FormControl>

                                <FormControl className="!mb-5 w-full" component="fieldset" variant="standard">
                                    <FormLabel component="legend"
                                               sx={{
                                                   display: 'flex',
                                                   color: theme.palette.primary.darkGrey,
                                                   fontSize: theme.typography.h8,
                                                   fontWeight: theme.typography.fontWeight.regular,
                                               }}
                                    >RESULT SUMMARY</FormLabel>
                                    <Switch
                                        size="medium"
                                        {...register('show_result')}
                                    />


                                </FormControl>

                                <FormControl className="!mb-5 w-full" component="fieldset" variant="standard">
                                    <FormLabel component="legend"
                                               sx={{
                                                   display: 'flex',
                                                   color: theme.palette.primary.darkGrey,
                                                   fontSize: theme.typography.h8,
                                                   fontWeight: theme.typography.fontWeight.regular,
                                               }}
                                    >ENABLE COUNTDOWN</FormLabel>
                                    <Switch
                                        size="medium"
                                        {...register('enable_countdown')}
                                    />
                                </FormControl>
                                {!watch('enable_countdown') ? null : (

                                    <FormControl className="!mb-5 w-full" component="fieldset" variant="standard">
                                        <FormLabel component="legend"
                                                   sx={{
                                                       display: 'flex',
                                                       color: theme.palette.primary.darkGrey,
                                                       fontSize: theme.typography.h8,
                                                       fontWeight: theme.typography.fontWeight.regular,
                                                       marginBottom: '2px',
                                                   }}
                                        >OVERALL TIME</FormLabel>

                                        <Box display="flex" flexDirection="row">
                                            <CssTextField
                                                type="number"
                                                InputProps={{inputProps: {min: 1}}}
                                                defaultValue={1}
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                {...register('quiz_time', {
                                                    required: true,
                                                    pattern: {
                                                        value: /^(-1|[1-9]|[1-5][0-9]|60)$/,
                                                        message:
                                                            'The valid time is between -1 and 60 (excluding 0)',
                                                    },
                                                })}
                                                sx={{
                                                    width: 70,
                                                }}
                                            />
                                            <Typography
                                                style={{
                                                    fontFamily: theme.typography.fontFamily,
                                                    fontSize: theme.typography.h6,
                                                    color: theme.palette.primary.black,
                                                    marginTop: '8px',
                                                    marginLeft: '5px',
                                                }}
                                            >
                                                minutes
                                            </Typography>


                                        </Box>

                                        {errors.quiz_time && (
                                            <FormHelperText error={true}
                                                            sx={{
                                                                fontSize: theme.typography.h9,
                                                                fontWeight: theme.typography.fontWeight.medium,
                                                                color: theme.palette.primary.red,
                                                            }}>{errors.quiz_time.message}</FormHelperText>
                                        )}
                                    </FormControl>
                                )}


                                <FormControl className="!mb-5 w-full" component="fieldset" variant="standard">
                                    <FormLabel component="legend"
                                               sx={{
                                                   display: 'flex',
                                                   color: theme.palette.primary.darkGrey,
                                                   fontSize: theme.typography.h8,
                                                   fontWeight: theme.typography.fontWeight.regular,
                                               }}
                                    >RANDOMIZE QUESTIONS</FormLabel>
                                    <Switch
                                        size="medium"
                                        {...register('randomize_questions')}
                                    />


                                </FormControl>

                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        alignItems: 'center',
                                        justifyContent: 'flex-end',
                                        gap: '1rem',
                                        borderTop: '1px solid',
                                        borderColor: theme.palette.primary.grey,
                                        paddingTop: '1rem',
                                    }}
                                >
                                    <Button
                                        type="button"
                                        sx={{
                                            fontWeight: 'bold',
                                            textTransform: 'capitalize',
                                            width: '150px',
                                        }}
                                        size="medium"
                                        variant="outlined"
                                        onClick={handleClose}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        type="submit"
                                        disabled={isLoading || isFetching}
                                        sx={{
                                            fontWeight: 'bold',
                                            color: theme.palette.primary.white,
                                            bgcolor: theme.palette.primary.main,
                                            '&:hover': {
                                                bgcolor: theme.palette.primary.main_1,
                                            },
                                            textTransform: 'capitalize',
                                            width: '150px',
                                        }}
                                        size="medium"
                                        variant="contained"
                                    >
                                        {
                                            isLoading || isFetching ?
                                                <Loading height="24.5px" spinnerSize="20px"/> : 'Create Quiz'
                                        }


                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Fade>
                </Modal>
            </ThemeProvider>
        </form>
    );
};

export default CreateQuizModal;
