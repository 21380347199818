import React, { useEffect } from "react";
import {
  ThemeProvider,
  experimentalStyled as styled,
} from "@mui/material/styles";
import { Box, Paper, Typography, Grid } from "@mui/material";
import { useGetDashboardDataQuery } from "../redux/features/dashboard/dashboardApi";
import { getFromLocalStorage } from "../utils/localStorage";
import useMediaQuery from "@mui/material/useMediaQuery";
import theme from "../config/theme";
import QuestionOverview from "../components/QuestionOverview";
import QuizOverview from "../components/QuizOverview";
import QuestionTimeline from "../components/QuestionTimeline";
import Loading from "../components/Loading"
const Item = styled(Paper)(({ theme }) => ({
  height: "15vh",
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: `4px 4px 3px  ${theme.palette.primary.grey}`,
}));

function Dashboard() {
  const { data, refetch, isLoading } = useGetDashboardDataQuery();
  const user = JSON.parse(getFromLocalStorage("user"));

  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  useEffect(() => {
    refetch();
  }, [refetch]);

  
  return (
    <ThemeProvider theme={theme}>
      {isLoading===true?<Loading/>:
      <Box
        sx={{
          mb: 0.5,
          mt: { xs: 5, sm: 10, md: 2 },
          mx: { xs: 3.5, sm: 2 },
          height: { xs: "95vh", sm: "90vh", md: "95vh" },
          overflow: "auto",
          boxShadow: 20,
          borderRadius: 3,
        }}
      >
        <Box
          sx={{
            px: 3,
            pt: {
              xs: 4,
              sm: 2,
              md: 2,
            },
          }}
        >
          <Typography
            sx={{
              fontSize: theme.typography.subtitle1,
              fontWeight: theme.typography.fontWeight.bold,
              color: theme.palette.primary.navy,
            }}
          >
            Hello {user?.firstName}!
          </Typography>
        </Box>
        <Grid container columns={{ xs: 6, sm: 12 }}>
          <Grid item xs={6} sm={6}>
            <QuestionOverview
              Item={Item}
              data={data}
              isLargeScreen={isLargeScreen}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <QuizOverview
              Item={Item}
              data={data}
              isLargeScreen={isLargeScreen}
            />
          </Grid>
        </Grid>

        <Grid container columns={{ xs: 6, sm: 12 }}>
          <Grid item xs={6} sm={11.5}>
            <QuestionTimeline 
            data={data}/>
          </Grid>
        </Grid>
      </Box>}
      
    </ThemeProvider>
  );
}

export default Dashboard;
