import React, { useEffect, useState } from "react";
import theme from "../config/theme";
import { Box, Typography, Button } from "@mui/material";
import { useResetPasswordMutation } from "../redux/features/user/userApi";
import CssTextField from "./CssTextField";
import Loading from "./Loading";

function ProvideEmailForm({
  setAlertOpen,
  setAlert,
  setEnterCode,
  setEmailInResetForm,
}) {
  const [resetPassword, { data, isError, isLoading, isSuccess, error }] =
    useResetPasswordMutation();

  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const handleReset = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.trim()) {
      setErrorMessage("Email is required");
    } else if (!emailRegex.test(email)) {
      setErrorMessage("Enter a valid email address");
    } else {
      setEmailInResetForm(email);
      resetPassword(email)
        .then(() => {
          setErrorMessage("");
        })
        .catch(() => {
          // handle error if the mutation fails
          setErrorMessage("Error resetting password. Please try again.");
        });
    }
  };
  useEffect(() => {
    if (isSuccess && data) {
      console.log("isSuccess", isSuccess, "data", data);
      setAlertOpen(false);
      setEnterCode(true);
    } else if (isError && error) {
      setAlertOpen(true);
      setAlert(error?.data?.message);
    }
  }, [data, error, isError, isSuccess, setAlert, setAlertOpen, setEnterCode]);
  return (
    <>
      {isLoading === true ? (
        <Typography sx={{ py: 3, fontSize: theme.typography.h6 }}>
          Please wait while we send a verification email to the provided
          address.
        </Typography>
      ) : (
        <Typography
          sx={{
            my: 2,
            ml: 1,
            fontSize: theme.typography.h6,
          }}
        >
          Please enter your email address below.
          <br />
          You will receive a link to create a new password via email.
        </Typography>
      )}
      {isLoading === true ? (
        <Box sx={{ pb: 2 }}>
          <Loading height="10px" />
        </Box>
      ) : (
        <CssTextField
          placeholder="enter your email here"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      )}

      {errorMessage && (
        <Typography sx={{ color: "red", ml: 1, mt: 1 }} variant="subtitle1">
          {errorMessage}
        </Typography>
      )}
      <Button
        // disabled={isLoading}
        sx={{
          whiteSpace: "nowrap",
          borderRadius: 5,
          fontWeight: "bold",
          bgcolor: "#9ABEBC",
          "&:hover": {
            bgcolor: " #d0e1e0",
          },
          flexGrow: "0",
          textTransform: "capitalize",
          width: "170px",
          display: "block",
          mx: "auto",
          mt: 2,
        }}
        size="medium"
        variant="contained"
        onClick={handleReset}
      >
        Reset
      </Button>
    </>
  );
}

export default ProvideEmailForm;
