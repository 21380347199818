import React, { useEffect } from "react";
import SideNav from "../components/SideNav";
import Footer from "../components/Footer"
import { useNavigate } from "react-router-dom";
import { getFromLocalStorage } from "../utils/localStorage";

const Home = () => {
  const navigate = useNavigate();
  const accessToken = getFromLocalStorage("token");

  useEffect(() => {
    if (!accessToken) {
      navigate("/login");
    }
  }, [accessToken, navigate]);
  return (
    <div>
      <SideNav />
      <Footer/>
    </div>
  );
};

export default Home;
