import React from "react";
import { Box, Typography } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import theme from "../config/theme";

const LeaderboardRow = ({ column, value, row }) => (
  <TableCell key={column.id} align={column.align}>
    <Box>
      {column.id === "quiz_taker" && (
        <Box>
          <Typography
            sx={{
              fontFamily: theme.typography.fontFamily,
              fontSize: theme.typography.h6,
              fontWeight: theme.typography.fontWeight.bold,
              color: theme.palette.primary.black,
            }}
          >
            {row.quiz_taker}
          </Typography>
          <Typography
            sx={{
              fontFamily: theme.typography.fontFamily,
              fontSize: theme.typography.h7,
              fontWeight: theme.typography.fontWeight.medium,
              color: theme.palette.primary.black,
            }}
          >
            {row.email}
          </Typography>
        </Box>
      )}
      {column.id === "date" && (
        <Box>
          <Typography
            sx={{
              fontFamily: theme.typography.fontFamily,
              fontSize: theme.typography.h7,
              fontWeight: theme.typography.fontWeight.bold,
              color: theme.palette.primary.black,
              textAlign: "center",
            }}
          >
            {row.date}
          </Typography>
          <Typography
            sx={{
              fontFamily: theme.typography.fontFamily,
              fontSize: theme.typography.h7,
              fontWeight: theme.typography.fontWeight.medium,
              color: theme.palette.primary.black,
              textAlign: "center",
            }}
          >
            {row.time}
          </Typography>
        </Box>
      )}
      {column.id !== "quiz_taker" && column.id !== "date" && (
        <Typography
          sx={{
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.h6,
            fontWeight: theme.typography.fontWeight.semiBold,
            color: theme.palette.primary.black,
            textAlign: "center",
          }}
        >
          {value}
        </Typography>
      )}
    </Box>
  </TableCell>
);

export default LeaderboardRow;
