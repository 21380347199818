import * as React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Doughnut } from "react-chartjs-2";
import theme from "../config/theme";

function QuestionOverview({ Item, data, isLargeScreen }) {
  const doughnutChart = {
    labels: isLargeScreen
      ? ["Verified", "Unverified"]
      : [`Verified \u00A0\u00A0\u00A0`, "Unverified"],
    datasets: [
      {
        data: [data?.verified_count, data?.unverified_count],
        backgroundColor: [
          theme.palette.secondary.lightGreen,
          theme.palette.primary.navy,
        ],
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      borderWidth: 22,

      tooltip: {
        caretSize: 5,
        titleColor: theme.palette.primary.darkGrey,
        bodyColor: theme.palette.primary.darkGrey,
        backgroundColor: theme.palette.primary.white,
        borderColor: theme.palette.primary.darkGrey,
        borderWidth: 0.5,
      },
      legend: {
        display: true,
        position: isLargeScreen ? "right" : "bottom", // Adjust the legend's position
        labels: {
          boxWidth: 10,
          color: theme.palette.primary.black,
        },
      },
    },
  };
  return (
    <>
      <Box
        sx={{
          px: 3,
          pt: 0.5,
        }}
      >
        <Typography
          sx={{
            fontSize: theme.typography.h6,
            fontWeight: theme.typography.fontWeight.bold,
            color: theme.palette.primary.darkGrey,
            textDecoration: `underline dotted ${theme.palette.primary.navy};`,
          }}
        >
          Question Overview
        </Typography>
      </Box>
      <Box sx={{ mx: 3, pt: 0.5, flexGrow: 1 }}>
        <Grid container spacing={{ xs: 2, sm: 2, md: 3 }} columns={{ xs: 8 }}>
          <Grid item xs={4} sm={4} md={4}>
            <Item>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                height="100%"
              >
                <Typography
                  sx={{
                    fontFamily: theme.typography.fontFamily,
                    fontSize: theme.typography.h7,
                    fontWeight: theme.typography.fontWeight.semiBold,
                    color: theme.palette.primary.black,
                    mb: 1,
                  }}
                >
                  Total Questions:
                </Typography>
                <Typography
                  sx={{
                    fontSize: theme.typography.large,
                    fontWeight: theme.typography.fontWeight.bold,
                    color: theme.palette.primary.black,
                  }}
                  textAlign="center"
                >
                  {data?.total_questions}
                </Typography>
              </Box>
            </Item>
          </Grid>
          <Grid item xs={4} sm={4} md={4}>
            <Item sx={{ backgroundColor: theme.palette.primary.navy }}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                height="100%"
              >
                <Typography
                  sx={{
                    fontFamily: theme.typography.fontFamily,
                    fontSize: theme.typography.h7,
                    fontWeight: theme.typography.fontWeight.semiBold,
                    color: theme.palette.primary.white,
                    mb: 1,
                  }}
                >
                  Openai Generated:
                </Typography>
                <Typography
                  sx={{
                    fontSize: theme.typography.large,
                    fontWeight: theme.typography.fontWeight.bold,
                    color: theme.palette.primary.white,
                  }}
                  textAlign="center"
                >
                  {data?.openai_question_count}
                </Typography>
              </Box>
            </Item>
          </Grid>
          <Grid item xs={4} sm={4} md={4}>
            <Item sx={{ backgroundColor: theme.palette.primary.navy }}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                height="100%"
              >
                <Typography
                  sx={{
                    fontFamily: theme.typography.fontFamily,
                    fontSize: theme.typography.h7,
                    fontWeight: theme.typography.fontWeight.semiBold,
                    color: theme.palette.primary.white,
                    mb: 1,
                  }}
                >
                  Manual Generated:
                </Typography>
                <Typography
                  sx={{
                    fontSize: theme.typography.large,
                    fontWeight: theme.typography.fontWeight.bold,
                    color: theme.palette.primary.white,
                  }}
                  textAlign="center"
                >
                  {data?.manual_question_count}
                </Typography>
              </Box>
            </Item>
          </Grid>
          <Grid item xs={4} sm={4} md={4}>
            <Item>
              <Doughnut data={doughnutChart} options={options} />
            </Item>
            {/* </Card> */}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default QuestionOverview;
