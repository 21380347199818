import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { ThemeProvider } from "@mui/material/styles";
import { useGetAllAdminQuery } from "../redux/features/user/userApi";
import theme from "../config/theme";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import RoleUpdate from "../components/RoleUpdate";
import Loading from "../components/Loading";
import Snackbar from "@mui/material/Snackbar";
import SnackAlert from "@mui/material/Alert";
import { styled } from "@mui/material/styles";
import CreateAdmin from "../components/CreateAdmin";

// search bar style
const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#ADC4CE",
      borderRadius: 15,
    },
    "&:hover fieldset": { borderColor: "#ADC4CE" },
    "&.Mui-focused fieldset": { borderColor: "#ADC4CE", borderWidth: "0.1em" },
    "& .MuiInputBase-input": { fontSize: "14px", color: "#2E4374" },
  },
  "& .MuiInputLabel-root": {
    color: "rgb(50,86,60,59%)",
  },
});

const Admin = () => {
  const [searchQuery, setSearchQuery] = useState(null);
  const { data, refetch, isLoading } = useGetAllAdminQuery({
    query: searchQuery || "",
  });

  function createData(first_name, last_name, email, role, id) {
    return { first_name, last_name, email, role, id };
  }

  const columns = [
    { id: "number", label: "No.", align: "center" },
    { id: "first_name", label: "Given name", align: "left" },
    { id: "last_name", label: "Surname", align: "left" },
    { id: "email", label: "Email", align: "left" },
    { id: "role", label: "Access Level", align: "left" },
  ];

  const rows =
    data?.internal_user_data?.map((row) =>
      createData(
        row?.first_name,
        row?.last_name,
        row?.email,
        row?.role,
        row?._id
      )
    ) || [];

  useEffect(() => {
    refetch();
  }, [refetch]);

  const rowStyle = {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.h7,
    fontWeight: theme.typography.fontWeight.medium,
    color: theme.palette.primary.black,
    py:1.2
  };
  const [alert, setAlert] = useState("");
  const [severity, setSeverity] = useState("success");
  const handleCloseSnackbar = () => {
    setAlert();
  };
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const OpenCreateAdminModal = () => {
    setOpenCreateModal(true);
  };
  const handleCloseCreateModal = () => {
    setOpenCreateModal(false);
    refetch()
  };
  const handleSuccess = (message, severity) => {
    setSeverity(severity);
    setAlert(message);
  };
  return (
    <ThemeProvider theme={theme}>
      {isLoading ? (
        <Loading />
      ) : (
        <Box>
          {!alert ? null : (
            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={true}
              autoHideDuration={4000}
              onClose={handleCloseSnackbar}
            >
              <SnackAlert
                sx={{ fontFamily: theme.typography.fontFamily }}
                severity={severity}
                onClose={handleCloseSnackbar}
              >
                {alert}
              </SnackAlert>
            </Snackbar>
          )}
          <Box
            sx={{
              px: {
                xs: 1.5,
                sm: 3,
              },
              marginBottom: "10px",
              pt: { xs: 9, sm: 10, md: 3 },
            }}
          >
            <Box display="flex">
              {/* search starts */}
              <CssTextField
                sx={{
                  width: { xs: "100%", sm: "60%" },
                  bgcolor: "#fff",
                  borderRadius: "5px",
                  border: "none",
                  ":focus": {
                    border: "none",
                  },
                }}
                id="outlined-basic"
                placeholder="search by name"
                variant="outlined"
                size="small"
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {/* search ends */}
              {/* create admin starts */}
              <Button
                variant="text"
                sx={{
                  whiteSpace: "nowrap",
                  borderRadius: 10,
                  textTransform: "none", //to disable the button change its size
                  ":hover": { bgcolor: "#eee" },
                  color: theme.palette.primary.black,
                }}
                onClick={OpenCreateAdminModal}
              >
                <Typography
                  sx={{
                    fontFamily: theme.typography.fontFamily,
                    fontSize: theme.typography.h8,
                    fontWeight: "600",
                    px: 1,
                  }}
                >
                  Create Admin
                </Typography>
              </Button>
              {/* create admin ends */}
            </Box>
            <Typography
              sx={{
                mt:2,
                mb:1,
                textAlign: "left",
                fontWeight: theme.typography.fontWeight.bold,
                fontFamily: theme.typography.fontFamily,
                fontSize: theme.typography.h9,
                color: theme.palette.primary.red,
              }}
            >
              * Only Superadmin level can access this page
            </Typography>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} size="small">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        sx={{
                          fontWeight: theme.typography.fontWeight.bold,
                          fontFamily: theme.typography.fontFamily,
                          fontSize: theme.typography.h7,
                          color: theme.palette.primary.white,
                          bgcolor:theme.palette.primary.navy
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell sx={rowStyle} align="center">
                        {index + 1}
                      </TableCell>
                      <TableCell sx={rowStyle} align="left">
                        {row.first_name.charAt(0).toUpperCase() +
                          row.first_name.slice(1).toLowerCase()}
                      </TableCell>
                      <TableCell sx={rowStyle} align="left">
                        {row.last_name.toUpperCase()}
                      </TableCell>
                      <TableCell sx={rowStyle} align="left">
                        {row.email}
                      </TableCell>
                      <TableCell align="left">
                        <RoleUpdate
                          row={row}
                          setSeverity={setSeverity}
                          setAlert={setAlert}
                          refetch={refetch}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      )}
            {/* Create Admin Modal */}
          <Box
            sx={{
              width: { xs: "95%", sm: "60%", md: "50%", lg: "40%" },
              display: "flex",
              justifyContent: "space-between",
              ml: { xs: 1.2, sm: 2 },
            }}
          >
            {openCreateModal && (
              <CreateAdmin
                method="openai"
                open={true}
                handleClose={handleCloseCreateModal}
                onSuccess={handleSuccess}
              />
            )}
          </Box>
    </ThemeProvider>
  );
};

export default Admin;
