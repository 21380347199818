import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Box, Typography, Menu, MenuItem } from "@mui/material";
import { useUpdateAccessLevelMutation } from "../redux/features/user/userApi";
import theme from "../config/theme";
import downIcon from "../assets/icons/down.svg";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 100,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenuItem-root": {
      fontSize: theme.typography.h7,
    },
  },
}));
function RoleUpdate({row, setSeverity, setAlert,refetch}) {
  //===================from Redux===================
  const [updateAccessLevel, { data, isError, isSuccess, error }] =
    useUpdateAccessLevelMutation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (e, oldRole) => {
    setAnchorEl(e.currentTarget);
    setOriginalRole(oldRole);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [originalRole, setOriginalRole] = useState("");
  const handleMenuItemClick = (newRole) => {
    if (originalRole === newRole) {
      console.log("no change");
    } else {
      const newData = {
        id: row?.id,
        role: newRole,
      };
      // console.log(newData);
      updateAccessLevel(newData);
    }
    handleClose();
  };
  //======================Notifification======================
  useEffect(() => {
    if (isSuccess && data) {
      setSeverity(data.status);
      setAlert("Updated successfully");
      refetch();
    } else if (isError && error) {
      setSeverity("error");
      setAlert(error);
    }
  }, [data, isSuccess, isError, error, setSeverity, setAlert,refetch]);
  return (
    <>
      <Box
        component="div"
        onClick={(e) => handleClick(e, row?.role)}
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <Typography
          sx={{
            borderRadius: 5,
            width: row?.role === "admin" ? "70px" : "103px",
            px: 1,
            bgcolor: row?.role === "admin" ? "#E2F6CA" : "#FFF5C2",
            color: row?.role === "admin" ? "#4AA96C" : "#F0A500",
            fontSize: theme.typography.h7,
          }}
        >
          {row?.role}
          <img
            src={downIcon}
            alt="Down Arrow"
            style={{
              marginLeft: 7,
              paddingBottom: 2,
              height: "12px",
              filter: "invert(50%) sepia(10%)",
            }}
          />
        </Typography>
      </Box>
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          onClick={() => handleMenuItemClick("admin")}
          sx={{
            "&:hover": {
              color: theme.palette.primary.green,
            },
          }}
        >
          admin
        </MenuItem>
        <MenuItem
          onClick={() => handleMenuItemClick("superadmin")}
          sx={{
            "&:hover": {
              color: theme.palette.primary.green,
            },
          }}
        >
          superadmin
        </MenuItem>
      </StyledMenu>
    </>
  );
}

export default RoleUpdate;
