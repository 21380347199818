import Modal from "@mui/joy/Modal";
import React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/joy/Typography";
import { Box, IconButton } from "@mui/material";
import rewardIcon from "../assets/icons/balloon.png";
import CloseIcon from "@mui/icons-material/Close";
import theme from "../config/theme";
import { useNavigate } from "react-router-dom";

const ResultModal = ({
  open,
  setOpen,
  quizData,
  quizId,
  showScore,
  showResult,
}) => {
  const navigate = useNavigate();
  const handleResultView = () => {
    navigate(`/quiz/attempt/${quizId}/result`, {
      state: { quizData: quizData, score: quizData?.score },
    });
  };
  // console.log(showScore);
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "95%", sm: "85%", md: "70%", lg: "60%" },
            maxHeight: "85vh",
            overflow: "auto",
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 3,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              mt: 0.5,
              mr: 0.5,
            }}
          >
            <IconButton
              size="small"
              onClick={() => {
                setOpen(false);
                window.location.reload();
              }}
            >
              <CloseIcon sx={{ color: "gray" }} />
            </IconButton>
          </Box>

          <Box display="block">
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "30%",
                  mx: 2,
                  my: 5,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  style={{
                    width: "100%",
                    height: "auto",
                  }}
                  src={rewardIcon}
                  alt="rewardIcon"
                />
              </Box>
              <Box display="block" sx={{ mt: 5 }}>
                <Typography
                  id="alert-dialog-modal-description"
                  textColor="text.tertiary"
                  textAlign="center"
                  sx={{
                    fontFamily: theme.typography.fontFamily,
                    fontSize: {
                      xs: theme.typography.h8,
                      sm: theme.typography.subtitle2,
                    },
                  }}
                >
                  Congratulations on completing the quiz!{" "}
                </Typography>
                <br />
                {showScore === true? (
                  <Typography
                    id="alert-dialog-modal-description"
                    textColor="text.tertiary"
                    textAlign="center"
                    sx={{
                      fontFamily: theme.typography.fontFamily,
                      fontSize: {
                        xs: theme.typography.subtitle1,
                        sm: theme.typography.title,
                      },
                      color: "#F2B600",
                      fontWeight: theme.typography.fontWeight.bold,
                      mb: 2,
                    }}
                  >
                    You scored {quizData?.score}%
                  </Typography>
                ) : (
                  <Typography
                    id="alert-dialog-modal-description"
                    textColor="text.tertiary"
                    textAlign="center"
                    sx={{
                      fontFamily: theme.typography.fontFamily,
                      fontSize: {
                        xs: theme.typography.h9,
                        sm: theme.typography.h6,
                      },
                      color: "#F2B600",
                      fontWeight: theme.typography.fontWeight.bold,
                      mb: 2,
                    }}
                  >
                    <span>This final score is intentionally disabled.</span>
                    <br />
                    <span>
                      If you need further information, feel free to contact us.
                    </span>
                  </Typography>
                )}
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  {showResult === false ? (
                    <Typography
                      sx={{
                        fontFamily: theme.typography.fontFamily,
                        fontSize: {
                          xs: theme.typography.h9,
                          sm: theme.typography.h6,
                        },
                      }}
                    >
                      Thank you for your time
                    </Typography>
                  ) : (
                    <Button
                      onClick={handleResultView}
                      sx={{
                        whiteSpace: "nowrap",
                        borderRadius: 5,
                        fontWeight: "bold",
                        bgcolor: "#9ABEBC",
                        "&:hover": {
                          bgcolor: " #d0e1e0",
                        },
                        flexGrow: "0",
                        padding: 0,
                        mr: {
                          xs: 0,
                          lg: 4,
                        },
                        mb: {
                          xs: 2,
                          lg: 0,
                        },
                        width: "100px",
                      }}
                      size="small"
                      variant="contained"
                    >
                      Result
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>
            <Box display="flex" justifyContent="center" textAlign="center">
              <Typography
                sx={{
                  mb: 3,
                  fontFamily: theme.typography.fontFamily,
                  fontSize: {
                    xs: theme.typography.h9,
                    sm: theme.typography.h6,
                  },
                }}
              >
                Keep up the great work in making the world a more sustainable
                place!
              </Typography>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default ResultModal;
