import * as React from "react";
import { Box, Typography } from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import theme from "../config/theme";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Box
        sx={{
          backgroundColor: theme.palette.primary.white,
          border: "1px solid #ccc",
          borderRadius: "4px",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          padding: "8px",
        }}
      >
        <Typography
          sx={{
            fontSize: theme.typography.h8,
            fontFamily: theme.typography.fontFamily,
          }}
        >{`Date: ${label}`}</Typography>
        <Typography
          sx={{
            fontSize: theme.typography.h8,
            fontFamily: theme.typography.fontFamily,
          }}
        >{`Question generated: ${payload[0].value}`}</Typography>
      </Box>
    );
  }

  return null;
};
function QuestionTimeline({ data }) {
  const lineChartData =
    data?.question_count_by_date?.map((data) => ({
      date: data?.formatted_date,
      questionCount: data?.question_count,
    })) || [];
  return (
    <>
      <Box
        sx={{
          px: 3,
          pt: 3,
        }}
      >
        <Typography
          sx={{
            fontSize: theme.typography.subtitle2,
            fontWeight: theme.typography.fontWeight.semiBold,
            color: theme.palette.primary.darkGrey,
            textDecoration: `underline dotted ${theme.palette.primary.navy};`,
          }}
        >
          Generated Questions Timeline
        </Typography>
      </Box>
      <ResponsiveContainer height="86%">
        <LineChart data={lineChartData}>
          <CartesianGrid strokeDasharray="2 3" />
          <XAxis
            label=""
            dataKey="date"
            style={{
              fontSize: theme.typography.h8,
              fontFamily: theme.typography.fontFamily,
            }}
          />
          <YAxis
            label=""
            style={{
              fontSize: theme.typography.h8,
              fontFamily: theme.typography.fontFamily,
            }}
            axisLine={false}
          />
          <Tooltip
            content={<CustomTooltip />}
            contentStyle={{
              fontSize: theme.typography.h8,
              fontFamily: theme.typography.fontFamily,
              backgroundColor: theme.palette.primary.white,
              border: "1px solid #ccc",
              borderRadius: "4px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              padding: "8px",
            }}
          />
          <Line
            type="monotone"
            dataKey="questionCount"
            stroke="navy"
            strokeWidth={3}
          />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
}

export default QuestionTimeline;
