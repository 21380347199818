import { Navigate, useLocation } from "react-router-dom";
import { getFromLocalStorage } from "../utils/localStorage";

export const PrivateRoute = ({ children }) => {
  const accessToken = getFromLocalStorage("token");
  const location = useLocation();

  if (accessToken) {
    return <>{children}</>;
  } else {
    sessionStorage.setItem("from", JSON.stringify(location));
    return <Navigate to="/login"></Navigate>;
  }
};
