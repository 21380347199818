import {
    Box,
    Button,


} from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import chatGPTLogo from '../assets/icons/chatgtp-logo.png';
import React, {useEffect, useRef, useState} from 'react';
import isEqual from 'lodash/isEqual';
import CreateQuestionModal from '../components/CreateQuestionModal';
import CreateByOpenai from '../components/CreateByOpenai';
import AlertDialog from '../components/AlertDialog';
import EditQuestionModal from '../components/EditQuestionModal';
import DetailsModal from '../components/DetailsModal';
import QuestionTable from '../components/QuestionTable';
import {
    useDeleteQuestionMutation,
    useBulkDeleteQuestionMutation,
    useGetAllQuestionQuery,
    useVerifyQuestionMutation,
} from '../redux/features/question/questionApi';
import {useSelector} from 'react-redux';
import CreateQuizModal from '../components/CreateQuizModal';
import theme from '../config/theme';
import '../style/QuestionTable.css';
import {useDispatch} from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
} from 'chart.js';
import 'chart.js/auto';
import {clearSelectedQuestions} from '../redux/features/question/questionSlice';
import {getFromLocalStorage} from '../utils/localStorage';
import Divider from '@mui/joy/Divider';
import {setGlobalLoading} from '../redux/features/global/globalSlice';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale);

const Questions = () => {
    // * * * * * * * * * * Redux * * * * * * * * * *
    const {selectedQuestions} = useSelector((state) => state.question);
    const dispatch = useDispatch();
    // * * * * * * * * * * State * * * * * * * * * *
    // * * * * * * * * API * * * * * * *
    const defaultQuery = {
        page: 0,
        search: '',
        order: '',
        orderBy: '',
        filter: {},
    };
    const [fetchQuestionsQuery, setFetchQuestionsQuery] = useState(defaultQuery);
    const prevFetchQuestionsQuery = useRef(defaultQuery);

    const {data, refetch, isLoading, isFetching} = useGetAllQuestionQuery(fetchQuestionsQuery);

    const [deleteQuestion, {isError: deleteError, isSuccess: deleteSuccess, error: deleteErrorMessage}] =
        useDeleteQuestionMutation();

    const [bulkDeleteQuestion, {
        isError: bulkDeleteError,
        isSuccess: bulkDeleteSuccess,
        error: bulkDeleteErrorMessage,
    }] =
        useBulkDeleteQuestionMutation();

    const [
        verifyQuestion,
        {isError: isVerifyError, isSuccess: isVerifySuccess, error: verifyError},
    ] = useVerifyQuestionMutation();
    // * For Create Question Modal
    const [openQuizCreatingModal, setOpenQuizCreatingModal] = useState(false);
    const [openOpenaiModal, setOpenOpenaiModal] = useState(false);
    const handleOpenCreateQuestionModal = () => setOpenQuizCreatingModal(true);
    const handleCloseCreateQuestionModal = () => setOpenQuizCreatingModal(false);
    const OpenCreateByOpenaiModal = () => setOpenOpenaiModal(true);
    const handleCloseCreateByOpenaiModal = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpenOpenaiModal(false);
        }
    };
    // * For Edit Question Modal
    const [selectedEditItem, setSelectedEditItem] = useState(null);
    const [selectedViewItem, setSelectedViewItem] = useState(null);
    const handleOpenEditQuestionModal = (question) => {
        setSelectedEditItem(question);
    };
    const handleCloseEditQuestionModal = () => setSelectedEditItem(null);

    // * For question details Modal
    const handleOpenDetailsModal = (question) => {
        setSelectedViewItem(question);
    };
    const handleCloseDetailsModal = () => setSelectedViewItem(null);
    // * For Create Quiz Modal

    const [openCreateQuizModal, setOpenCreateQuizModal] = useState(false);

    const handleOpenCreateQuizModal = (selected) => {
        if (selected.every((item) => item.verified)) {
            setOpenCreateQuizModal(true);
        } else {
            handleToastMessage('Please select only verified questions to create a quiz.', 'warning');
        }
    };
    const handleCloseCreateQuizModal = () => setOpenCreateQuizModal(false);

    const [dialog, setDialog] = useState(null);

    const handleDeleteQuestion = (question) => {
        deleteQuestion(question.id);
        dispatch(setGlobalLoading(true));
    };

    const openVerifyDialog = (item) => setDialog({
        message: 'Are you sure you want to verify this question?',
        onConfirm: () => handleVerifyQuestion(item),
        type: 'verify',
    });
    const openDeleteConfirmationDialog = (item) => setDialog({
        message: 'Are you sure you want to verify this question?',
        onConfirm: () => handleDeleteQuestion(item),
        type: 'delete',
    });


    const handleVerifyQuestion = (question) => {
        const user = JSON.parse(getFromLocalStorage('user'));
        const newData = {id: question?.id, verified_by: user.email};
        verifyQuestion(newData);
        dispatch(setGlobalLoading(true));
    };

    //----------------------Delete Multiple questions ----------------
    const [isDeleting, setIsDeleting] = useState(false);
    const handleMultipleDelete = (selected) => {
        const hasVerifiedQuestion = selected.some(
            (data) => data.verified === true,
        );
        if (hasVerifiedQuestion) {
            handleToastMessage('Verified questions cannot be deleted.', 'warning');
        } else if (!selected?.length) {
            handleToastMessage('You must select at least 1 question', 'warning');
        } else {
            setDialog({
                message: <p>Are you sure you want to
                    delete <b>{selected.length}</b> question{selected.length > 1 ? 's' : ''}?</p>,
                severity: 'warning',
                onConfirm: handleMultipleDeleteConfirmed,
                type: 'delete',
            });
        }
    };
    const handleMultipleDeleteConfirmed = async () => {
        try {
            const selectedIds = selectedQuestions.map(i => i.id);
            bulkDeleteQuestion({ids: selectedIds});
            dispatch(setGlobalLoading(true));
        } catch (error) {
            console.error('Error deleting questions:', error);
        }
    };
    //  * * * * * * * * * * * Notify user * * * * * * * * * * * * *
    useEffect(() => {
        if (isVerifySuccess) {
            handleToastMessage('Question verified successfully');
        } else if (isVerifyError && verifyError?.data) {
            handleToastMessage(verifyError?.data?.message, 'error');
        }
        dispatch(setGlobalLoading(false));
    }, [isVerifySuccess, isVerifyError, verifyError]);

    useEffect(() => {
        if (!isEqual(fetchQuestionsQuery, prevFetchQuestionsQuery.current)) {
            refetch();
            prevFetchQuestionsQuery.current = fetchQuestionsQuery;
        }
    }, [fetchQuestionsQuery]);

    useEffect(() => {
        if (bulkDeleteSuccess) {
            const questionCount = selectedQuestions.length;
            handleToastMessage(`Deleted ${questionCount} question${questionCount > 1 ? 's' : ''} successfully`);
            clearAllSelection();
            refetch();
        } else if (bulkDeleteError && bulkDeleteErrorMessage?.data) {
            handleToastMessage(bulkDeleteErrorMessage?.data?.message, 'error');
        }
        dispatch(setGlobalLoading(false));
    }, [bulkDeleteError, bulkDeleteSuccess]);

    useEffect(() => {
        if (deleteSuccess) {
            handleToastMessage(`Deleted question successfully`);
            refetch();
        } else if (deleteError && deleteErrorMessage?.data) {
            handleToastMessage(deleteErrorMessage?.data?.message, 'error');
        }
        dispatch(setGlobalLoading(false));
    }, [deleteError, deleteSuccess]);

    const [alert, setAlert] = useState(null);
    const handleToastMessage = (message, severity = 'success') => {
        setAlert({
            message,
            severity,
        });
    };
    const handleCloseSnackbar = (event) => {
        setAlert(null);
        if (event) {
            event.stopPropagation();
        }
    };

    function clearAllSelection() {
        dispatch(clearSelectedQuestions());
    }

    function onCreateQuizSuccess(data) {
        handleToastMessage('Quiz created successfully');
        clearAllSelection();
    }

    return (
        <>
            <Box>
                {alert !== null &&
                    <Snackbar
                        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                        open={true}
                        autoHideDuration={4000}
                        onClose={handleCloseSnackbar}
                    >
                        <Alert
                            sx={{fontFamily: theme.typography.fontFamily}}
                            severity={alert?.severity}
                            onClose={handleCloseSnackbar}
                        >
                            <Box
                                sx={{
                                    display: 'block',
                                    fontWeight: 'bold',
                                    fontFamily: theme.typography.fontFamily,
                                }}
                            >
                                {alert?.element ? alert?.element : <p>{alert?.message}</p>}
                            </Box>
                        </Alert>
                    </Snackbar>
                }

                {/* ========= Search and filter area start ========= */}
                <Box
                    sx={{
                        display: {
                            xs: 'block',
                            sm: 'flex',
                        },
                        px: {
                            xs: 1.5,
                            sm: 3,
                        },
                        pb: {
                            xs: 1,
                            sm: 1,
                        },
                        maxWidth: '100%',
                        bgcolor: '#fff',
                        justifyContent: 'space-between',
                        marginBottom: '1px',
                        position: 'sticky',
                        top: 0,
                        pt: {xs: 9, sm: 10, md: 3},
                        zIndex: '5',
                        flexDirection: 'column',
                    }}
                >

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            gap: '2rem',
                        }}
                    >
                        <Button
                            variant="contained"
                            startIcon={<img src={chatGPTLogo} alt="chatGPT" style={{height: '20px', width: '20px'}}/>}
                            sx={{
                                whiteSpace: 'nowrap',
                                textTransform: 'none', //to disable the button change its size
                                bgcolor: theme.palette.primary.main,
                                color: '#fff',
                                '&:hover': {
                                    bgcolor: theme.palette.primary.main_1,
                                    color: theme.palette.primary.black,
                                },
                                fontWeight: '600',
                            }}
                            onClick={OpenCreateByOpenaiModal}
                        >
                            Create by GPT
                        </Button>
                        <Button
                            variant="contained"
                            startIcon={<CreateIcon/>}
                            sx={{
                                whiteSpace: 'nowrap',
                                textTransform: 'none',
                                bgcolor: theme.palette.primary.main,
                                color: '#fff',
                                '&:hover': {
                                    bgcolor: theme.palette.primary.main_1,
                                    color: theme.palette.primary.black,
                                },
                                fontWeight: '600',
                            }}
                            onClick={handleOpenCreateQuestionModal}
                        >
                            Create Question
                        </Button>
                    </Box>
                    <Divider sx={{marginTop: '1.5rem'}}/>

                </Box>

                {/* ========= Search and filter area end ========= */}


                {/* ========= Questions start ========= */}
                <QuestionTable data={data}
                               isLoading={isLoading || isFetching}
                               handleOpenEditQuestionModal={handleOpenEditQuestionModal}
                               openVerifyDialog={openVerifyDialog}
                               openDeleteConfirmationDialog={openDeleteConfirmationDialog}
                               handleOpenDetailsModal={handleOpenDetailsModal}
                               handleMultipleDelete={handleMultipleDelete}
                               handleOpenCreateQuizModal={handleOpenCreateQuizModal}
                               setFetchQuestionsQuery={setFetchQuestionsQuery}
                />


                {/* =========== Modal ========== */}
                <CreateQuestionModal
                    onSuccess={() => {
                        handleToastMessage('Created successfully');
                    }}
                    open={openQuizCreatingModal}
                    handleClose={handleCloseCreateQuestionModal}
                />

                <CreateByOpenai
                    method="openai"
                    open={openOpenaiModal}
                    handleClose={handleCloseCreateByOpenaiModal}
                    onSuccess={() => {
                        handleToastMessage('Created from Openai successfully');
                    }}
                    onError={() => {
                        const errorMessage = deleteErrorMessage?.data?.message;
                        handleToastMessage(errorMessage, 'error');
                    }}
                />

                {!!selectedEditItem && <EditQuestionModal
                    onSuccess={() => {
                        handleToastMessage('Update successfully');
                    }}
                    question={selectedEditItem}
                    open={!!selectedEditItem}
                    handleClose={handleCloseEditQuestionModal}
                />
                }

                {!!selectedViewItem && <DetailsModal
                    open={!!selectedViewItem}
                    handleClose={handleCloseDetailsModal}
                    question={selectedViewItem}
                />
                }

                <CreateQuizModal
                    onSuccess={onCreateQuizSuccess}
                    open={openCreateQuizModal}
                    handleClose={handleCloseCreateQuizModal}
                    selectedQuestions={selectedQuestions}
                />

                {!!dialog && <AlertDialog
                    open={!!dialog}
                    setOpen={() => setDialog(null)}
                    message={dialog?.message}
                    onConfirm={dialog?.onConfirm}
                    type={dialog?.type}
                />
                }


            </Box>
        </>
    );
};

export default Questions;
