import { configureStore } from "@reduxjs/toolkit";
// import userReducer from "./features/user/userSlice";
import questionReducer from "./features/question/questionSlice";
import quizReducer from "./features/quiz/quizSlice"
import api from "./api/apiSlice";
import globalSlice from './features/global/globalSlice';

export const store = configureStore({
  reducer: {
    global: globalSlice,
    // user: userReducer,
    question: questionReducer,
    quiz: quizReducer,
    [api.reducerPath]: api.reducer,//dynamic key [api.reducerPath] associated with the API slice
  },
  // devTools: true
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});


export default store;
