import Typography from "@mui/material/Typography";
import "../config/thriveLogoStyle.css";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import theme from "../config/theme";
function LoginLeftPage() {
  return (
    <Grid
      item
      component={Paper}
      style={{ backgroundColor: "transparent" }}
      elevation={0}
    >
      <Box
        sx={{
          ml: 3,
          mr: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "space-between", // Center content vertically
          justifyContent: "center",
        }}
      >
        <div>
          <img
            className="responsive-image"
            src="https://strive2thrive.earth/wp-content/uploads/2020/06/Thrive-logo-nostrap-colour.png"
            alt="Thrive logo"
          ></img>
        </div>
        <div id="earthback">
          <div className="filtered-image" id="earth"></div>
        </div>

        <Box
          sx={{
            display: {
              xs: "none",
              sm: "grid",
              md: "grid",
            },
            textAlign: "justify",
            color: "#3D5A58", //#244443
            px: "1em",
            my: "2em",
          }}
        >
          <Typography
            sx={{ 
              fontFamily:theme.typography.fontFamily,
              fontSize: theme.typography.subtitle1,
              fontWeight: "bold", 
             }}
          >
            Welcome to our Sustainable Knowledge Challenge!
          </Typography>
          <Typography sx={{ fontSize: theme.typography.lineBreak }}>
            <br />
          </Typography>
          <Typography sx={{ fontSize: theme.typography.subtitle2 }}>
          Our platform is your gateway to understanding and advocating for a
          greener and more sustainable future. Explore, Learn, and Test your
          grasp on sustainability.
        </Typography>
        </Box>
      </Box>
    </Grid>
  );
}

export default LoginLeftPage;
