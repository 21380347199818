// Leaderboard.js
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { useGetLeaderboardQuery } from "../redux/features/quiz/quizApi";
import Loading from "../components/Loading";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import theme from "../config/theme";
import LeaderboardRow from "../components/LeaderboardRow";
import TablePagination from "@mui/material/TablePagination";

const formatDate = (timestamp) => {
  return new Date(timestamp * 1000).toLocaleDateString();
};

const formatTime = (timestamp) => {
  return new Date(timestamp * 1000).toLocaleTimeString();
};

const columns = [
  { id: "number", label: "No.", minWidth: 30, align: "center" },
  { id: "quiz_taker", label: "Quiz Taker", minWidth: 100, align: "left" },
  { id: "date", label: "Date", minWidth: 100, align: "center" },
  { id: "score", label: "Score", minWidth: 170, align: "center" },
];

const createData = (number, quiz_taker, date, score, email, time) => {
  return { number, quiz_taker, date, score, email, time };
};

const Leaderboard = () => {
  const param = useParams();
  const { data, isLoading } = useGetLeaderboardQuery(param?.quiz_id);
  const [dateSortOrder, setDateSortOrder] = useState("asc");
  const [scoreSortOrder, setScoreSortOrder] = useState("asc");
  const [sortCriterion, setSortCriterion] = useState("date");

  const handleSort = (criterion) => {
    setSortCriterion(criterion); // Update the current sort criterion

    if (criterion === "date") {
      setDateSortOrder((prevOrder) => (prevOrder === "desc" ? "asc" : "desc"));
      setScoreSortOrder("asc"); // Reset score sorting
    } else if (criterion === "score") {
      setScoreSortOrder((prevOrder) => (prevOrder === "desc" ? "asc" : "desc"));
      setDateSortOrder("asc"); // Reset date sorting
    }
  };

  const sortedRows = [...(data?.leaderboard || [])].sort((a, b) => {
    const aValue = sortCriterion === "date" ? a.date : a.score;
    const bValue = sortCriterion === "date" ? b.date : b.score;

    if (sortCriterion === "date") {
      return dateSortOrder === "asc" ? aValue - bValue : bValue - aValue;
    } else {
      return scoreSortOrder === "asc" ? aValue - bValue : bValue - aValue;
    }
  });

  const rows = sortedRows.map((row, index) =>
    createData(
      index + 1,
      `${row?.first_name || ""} ${row?.last_name || ""}`,
      formatDate(row?.date),
      row?.score,
      row?.email,
      formatTime(row?.date)
    )
  );
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage + 1);
  };

  const paginatedRows = rows.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );
  return (
    <Box
      sx={{
        mt: { xs: 7, md: 0 },
        width: { xs: "100vw", md: "100%" },
        overflow: "auto",
      }}
    >
      {isLoading ? (
        <Loading />
      ) : data?.leaderboard?.length > 0 ? (
        <Box sx={{ p: 3 }}>
          <Box sx={{ display: "flex"}}>
          <Typography
              sx={{
                mr: 2,
                ml:2,
                fontSize: theme.typography.h7,
                fontWeight: theme.typography.fontWeight.bold,
                color: theme.palette.primary.darkGrey,
              }}
            >
              Quiz name:
            </Typography>
            <Typography
              sx={{
                mr: 2,
                fontSize: theme.typography.h6,
                fontWeight: theme.typography.fontWeight.bold,
                color: theme.palette.primary.navy,
                marginRight:"auto"
              }}
            >
              {data.quiz_name}
            </Typography>
            <Typography
              sx={{
                mr: 2,
                fontSize: theme.typography.h7,
                fontWeight: theme.typography.fontWeight.bold,
                color: theme.palette.primary.darkGrey,
              }}
            >
              Sort by:
            </Typography>
            <Box
              onClick={() => handleSort("date")}
              sx={{
                px: 1.2,
                mr: 2,
                fontSize: theme.typography.h7,
                fontWeight: theme.typography.fontWeight.bold,
                color: theme.palette.primary.navy,
                ":hover": {
                  cursor: "pointer",
                  bgcolor: theme.palette.primary.grey,
                  borderRadius: 2,
                },
              }}
            >
              Date {dateSortOrder === "asc" ? "▲" : "▼"}
            </Box>
            <Box
              onClick={() => handleSort("score")}
              sx={{
                px: 1.2,
                fontSize: theme.typography.h7,
                fontWeight: theme.typography.fontWeight.bold,
                color: theme.palette.primary.navy,
                ":hover": {
                  cursor: "pointer",
                  bgcolor: theme.palette.primary.grey,
                  borderRadius: 2,
                },
              }}
            >
              Score {scoreSortOrder === "asc" ? "▲" : "▼"}
            </Box>
          </Box>
          <Paper sx={{ width: "100%", overflow: "auto" }}>
            <TableContainer sx={{ maxHeight: "92vh" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                        sx={{
                          fontWeight: theme.typography.fontWeight.bold,
                          fontFamily: theme.typography.fontFamily,
                          fontSize: theme.typography.h6,
                          color: theme.palette.primary.white,
                          bgcolor:theme.palette.primary.navy
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedRows.map((row, i) => (
                    <TableRow  tabIndex={-1} key={i}>
                      {columns.map((column) => (
                        <LeaderboardRow
                          key={column.id}
                          column={column}
                          value={row[column.id]}
                          row={row}
                        />
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={currentPage - 1}
              onPageChange={handleChangePage}
            />
          </Paper>
        </Box>
      ) : (
        <Box
          sx={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontSize: "20px" }}>No Results Found</Typography>
        </Box>
      )}
    </Box>
  );
};

export default Leaderboard;
