import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import theme from "../config/theme"
const CssTextField = styled(TextField)({
    "& .MuiOutlinedInput-root": {
      "& fieldset": { 
        borderColor: "rgb(50,86,60,29%)",
        borderRadius: 15,
      },
      "&:hover fieldset": { borderColor: "#9ABEBC" },
      "&.Mui-focused fieldset": { borderColor: "#9ABEBC" },
      "& .MuiInputBase-input": {     
        fontSize:"13px", 
      color:theme.palette.primary.black},
    },
    "& .MuiInputLabel-root": {
      fontSize:"13px",
      color: "rgb(50,86,60,59%)",
    },
    "&:-webkit-autofill": {
      // Styles for autofill state
     boxShadow:"0 0 0 50px white inset" /* Change the color to your own background color */
    }, "&:-webkit-autofill:focus": {
      // Styles for autofill state
     boxShadow:"0 0 0 50px white inset" /* Change the color to your own background color */
    },
  });
  export default CssTextField