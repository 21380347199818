import React, { useState, useEffect } from "react";
import theme from "../config/theme";
import { Box, Typography, Button } from "@mui/material";
import CssTextField from "../components/CssTextField";
import { useCodeVerificationMutation } from "../redux/features/user/userApi";
import Loading from "../components/Loading";


function CodeVerificationForm({ setAlertOpen, setAlert, emailInResetForm, setSubmitCode }) {
  
  const [code, setCode] = useState("");
  const [codeVerification, { data, isError, isLoading, isSuccess, error }] =
    useCodeVerificationMutation();

  const handleCodeSubmit = () => {
    const newData = {
      code: code,
      email: emailInResetForm,
    };
    codeVerification(newData);
  };

  useEffect(() => {
    if (isSuccess && data) {
      setAlertOpen(false);
      setSubmitCode(true);
    } else if (isError && error) {
      setAlertOpen(true);
      setAlert(error?.data?.message);
    }
  }, [isSuccess, data, isError, error, setAlert, setAlertOpen, setSubmitCode]);

  return (
    <>
      <Box display="block">
        <Typography
          sx={{
            my: 2,
            ml: 1,
            fontSize: theme.typography.subtitle2,
            fontWeight: theme.typography.fontWeight.bold,
            color: theme.palette.secondary.gold,
          }}
        >
          An email has been sent to your email, please check your inbox.
        </Typography>
        <Typography
          sx={{
            my: 2,
            ml: 1,
            fontSize: theme.typography.h6,
          }}
        >
          Enter the verification code provided in the email.
        </Typography>
      </Box>
      {isLoading === true ? (
        <Box sx={{ pb: 2 }}>
          <Loading height="10px" />
        </Box>
      ) : (
      <CssTextField
        placeholder="enter your code here"
        fullWidth
        value={code}
        onChange={(e) => setCode(e.target.value)}
      />)}
      <Button
        // disabled={isLoading}
        sx={{
          whiteSpace: "nowrap",
          borderRadius: 5,
          fontWeight: "bold",
          bgcolor: "#9ABEBC",
          "&:hover": {
            bgcolor: " #d0e1e0",
          },
          flexGrow: "0",
          textTransform: "capitalize",
          width: "170px",
          display: "block",
          mx: "auto",
          mt: 2,
        }}
        size="medium"
        variant="contained"
        onClick={handleCodeSubmit}
      >
        Submit Code
      </Button>
    </>
  );
}

export default CodeVerificationForm;
