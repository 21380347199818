import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    selectedQuestions: [],
};

const questionSlice = createSlice({
    name: 'question',
    initialState,
    reducers: {
        assignQuestions: (state, action) => {
            state.selectedQuestions = [...action.payload];
        },
        addToSelectedQuestions: (state, action) => {
            state.selectedQuestions = [...state.selectedQuestions, action.payload];
        },
        clearSelectedQuestions: (state) => {
            state.selectedQuestions = [];
        },
        removeFromSelectedQuestions: (state, action) => {
            const filter = state.selectedQuestions.filter(
                (item) => item?.id !== action.payload.id,
            );
            state.selectedQuestions = [...filter];
        },
    },
});

export const {
    assignQuestions,
    addToSelectedQuestions,
    removeFromSelectedQuestions,
    clearSelectedQuestions,
} = questionSlice.actions;

export default questionSlice.reducer;
