import api from '../../api/apiSlice';
import * as _ from 'lodash';

const QuestionApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getAllQuestion: builder.query({
            query: ({page, search, order, orderBy, filter}) => {
                // console.log({page, search, order, orderBy, filter})
                let url = `/questions/all?page=${page}`;
                if (search) {
                    url += `&search=${search}`;
                }
                if (order && orderBy) {
                    url += `&order=${order}&order_by=${orderBy}`;
                }
                if (filter && !_.isEmpty(filter)) {
                   const filterQuery = Object.keys(filter).reduce((prev, cur) => {
                        prev += `&${cur}=${filter[cur]}`;
                        return prev;
                    }, '');
                   url += filterQuery;
                }
                return {
                    url,
                    method: 'GET',
                };
            },
            providesTags: ['question'],
        }),
        createQuestion: builder.mutation({
            query: (data) => ({
                url: `/questions/create/${data.method}`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['question'],
        }),

        editQuestion: builder.mutation({
            query: (data) => ({
                url: `/questions/update/${data.id}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['question'],
        }),
        // * verify question
        verifyQuestion: builder.mutation({
            query: (data) => {
                return {
                    url: `/questions/verify/${data.id}`,
                    method: 'PUT',
                    body: data,
                };

            },
            invalidatesTags: ['question'],
        }),

        deleteQuestion: builder.mutation({
            query: (id) => ({
                url: `/questions/delete/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['question'],
        }),
        bulkDeleteQuestion: builder.mutation({
            query: (data) => ({
                url: `/questions/bulk-delete`,
                method: 'DELETE',
                body: data,
            }),
            invalidatesTags: ['question'],
        }),
    }),
});

export const {
    useGetAllQuestionQuery,
    useCreateQuestionMutation,
    useDeleteQuestionMutation,
    useEditQuestionMutation,
    useVerifyQuestionMutation,
    useBulkDeleteQuestionMutation,
} = QuestionApi;
