import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedQuizzes: [],
};

const quizSlice = createSlice({
  name: "quiz",
  initialState,
  reducers: {
    addToSelectedQuizzes: (state, action) => {
      state.selectedQuizzes = [...state.selectedQuizzes, action.payload];
    },
    removeFromSelectedQuizzes: (state, action) => {
      const filter = state.selectedQuizzes.filter(
        (item) => item?.id !== action.payload.id
      );
      state.selectedQuizzes = [...filter];
    }, 
  },
});

export const { addToSelectedQuizzes, removeFromSelectedQuizzes, } =
quizSlice.actions;

export default quizSlice.reducer;
