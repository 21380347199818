import api from "../../api/apiSlice";

const QuestionApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllQuiz: builder.query({
      query: ({page, query}) => ({
        url: `/quiz/all?page=${page}&query=${query}`,
        method: "GET",
      }),
      providesTags: ["quiz"],
    }),
    createQuiz: builder.mutation({
      query: (data) => {
       return{
        url: `/quiz/create`,
        method: "POST",
        body: data,
        } 
      },
      invalidatesTags: ["quiz"],
    }),
    updateQuiz:builder.mutation({
      query: (data) => ({
        url: `/quiz/update/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["quiz"],
    }),
    deleteQuiz: builder.mutation({
      query: (id) => ({
        url: `/quiz/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["quiz"],
    }),
    startQuiz: builder.query({
      query: (quizId) => ({
        url: `/quiz/attempt/${quizId}`,
        method: "GET",
      }),
    }),
    submitQuiz: builder.mutation({
      query: (data) => {
        // console.log(data);
        return{
          url: `/quiz/submit/${data.quiz_id}`,
          method: "POST",
          body: data,
        }
      },
      invalidatesTags: ["quiz"],
    }),
    getLeaderboard: builder.query({
      query: (quizId) => ({
        url: `/quiz/leaderboard/${quizId}`,
        method: "GET",
      }),
      // providesTags: ["quiz"],
    }),
    
  }),
});

export const {
  useGetAllQuizQuery,
  useCreateQuizMutation,
  useUpdateQuizMutation,
  useDeleteQuizMutation,
  useStartQuizQuery,
  useSubmitQuizMutation,
  useGetLeaderboardQuery,
} = QuestionApi;
