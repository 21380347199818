// import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
// import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
// import Container from "@mui/material/Container";
import { ThemeProvider } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useEffect, useState } from "react";
import { useRegisterUserMutation } from "../redux/features/user/userApi";
import { toast } from "react-hot-toast";
import { IconButton, InputAdornment } from "@mui/material";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import theme from "../config/theme";
import LeftPage from "../components/LeftPage.js";
import Copyright from "../components/Copyright";
import CssTextField from "../components/CssTextField";
import logoIcon from "../assets/icons/logoIcon.svg";
const hideLeftPageBelow600px = {
  "@media (max-width: 600px)": {
    display: "none",
  },
};
const Register = () => {
  // * * * * * * * * * * * User Registration API * * * * * * * * * * * *
  const [registerUser, { data, isError, isLoading, isSuccess, error }] =
    useRegisterUserMutation();

  // * * * * * * * * * * States * * * * * * * * * *
  const navigate = useNavigate();
  const [passwordNotMatchError, setPasswordNotMatchError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  // * * * * * * * * * * * Functions * * * * * * * * *
  const onSubmit = (data) => {
    //* check if password match or not
    if (data.password !== data.confirm_password) {
      setPasswordNotMatchError("Password is not matching");
      return;
    } else {
      setPasswordNotMatchError("");
    }
    // * if passwords match, this removes the confirm_password property from the data object
    delete data.confirm_password;
    // console.log(data);
    registerUser(data);
  };
  const validatePassword = (value) => {
    // Password must contain at least one letter (a-zA-Z),
    // one number (0-9), one special character (!@#$%^&*),
    // and be at least 8 characters long
    const regex = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$/;
    return regex.test(value);
  };
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  //  * * * * * * * * * * * Notify and redirect user * * * * * * * * * * * * *
  useEffect(() => {
    if (isSuccess && data) {
      toast.success("You have registered successfully");
      // navigate user to the login page
      navigate("/login");
    } else if (isError && error) {
      // Use the specific error message from the backend
      toast.error(error?.data?.message);}
  }, [data, isError, isSuccess, error, navigate]);
  
  return (
    <ThemeProvider theme={theme}>
       <Grid container component="main">
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={6}
          md={7}
          lg={7}
          sx={{
            ...hideLeftPageBelow600px,
            py: 1,
            background:
              "linear-gradient(127deg, rgba(215,232,247,1) 0%, rgba(236,247,241,1) 71%, rgba(255,255,255,1) 100%)",
            display: {
              xs: "none",
              sm: "grid",
              md: "grid",
              lg: "grid",
            },
            alignItems: "center",
          }}
        >
          <LeftPage />
        </Grid>
        <Grid item xs={12} sm={6} md={5} lg={5} component={Paper} elevation={0}>
          <Box
            sx={{
              ml: 4,
              mr: 4,
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              alignItems: "space-between", // Center content vertically
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "start",
                justifyContent: "center",
              }}
            >
              <img src={logoIcon} alt="logoIcon" style={{ height: "3rem" }} />
              <Typography
                variant="subtitle"
                sx={{ color: "#2E4F4F", mt: "0.5rem" }}
              >
                SuperQuiz
              </Typography>
            </Box>
            <Typography sx={{ 
              fontSize:theme.typography.title,
              fontWeight:theme.typography.fontWeight.medium,
              color:theme.palette.primary.black, ml: "0.3em" }}>
              Sign Up
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit(onSubmit)}
              sx={{ my: 2 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <CssTextField
                    autoComplete="first_name"
                    fullWidth
                    label="First Name"
                    autoFocus
                    size="small"
                    {...register("first_name", { required: true })}
                  />
                  {errors.first_name && (
                    <Typography sx={{ color: "red",}} variant="subtitle1">
                      First name is required
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CssTextField
                    fullWidth
                    label="Last Name"
                    autoComplete="last_name"
                    size="small"
                    {...register("last_name", { required: true })}
                  />
                  {errors.last_name && (
                    <Typography sx={{ color: "red" }} variant="subtitle1">
                      Last Name is required
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <CssTextField
                    fullWidth
                    label="Email"
                    autoComplete="email"
                    type="email"
                    size="small"
                    {...register("email", { required: true })}
                  />
                  {errors.email && (
                    <Typography sx={{ color: "red" }} variant="subtitle1">
                      Email address is required
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name="password"
                    control={control}
                    rules={{ validate: validatePassword }}
                    defaultValue=""//this is important, otherwise a warning will show that suggests there's an issue with controlled and uncontrolled inputs in your form. 
                    render={({ field }) => (
                      <CssTextField
                        fullWidth
                        label="Password"
                        type={showPassword ? "text" : "password"}
                        size="small"
                        {...field}
                        InputProps={{
                          // <-- This is where the toggle button is added.
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                size="small"
                                sx={{
                                  color: "#8CA690",
                                }}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        {...register("password", { required: true })}
                      />
                    )}
                  />
                  {errors.password && (
                   <Typography sx={{ color: "red" }} variant="subtitle1">
                   Password must contain at least:<br/>
                   * one letter<br/> * one number<br/> * one
                   special character (!@#$%^&*)<br/> * at least 8 characters
                   long.
                 </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <CssTextField
                    fullWidth
                    label="Confirm Password"
                    type={showPassword ? "text" : "password"}
                    size="small"
                    {...register("confirm_password", { required: true })}
                  />
                  {errors.confirm_password && (
                    <Typography sx={{ color: "red" }} variant="subtitle1">
                      Confirm password is required
                    </Typography>
                  )}
                  {passwordNotMatchError && (
                    <Typography sx={{ color: "red" }} variant="subtitle1">
                      {passwordNotMatchError}
                    </Typography>
                  )}
                </Grid>
              </Grid>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={isLoading}
                sx={{
                  mt: 5,
                  mb: 2,
                  borderRadius: 15,
                  fontWeight: "bold",
                  bgcolor: "#9ABEBC",
                  "&:hover": {
                    bgcolor: " #d0e1e0",
                  },
                }}
              >
                Sign Up
              </Button>
              <Grid container justifyContent="center">
                <div>
                  <Typography
                    sx={{
                      fontSize:theme.typography.h7,
                      color: theme.palette.primary.black,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Already have an account?
                    <Link
                      to="/login"
                      style={{
                        marginLeft: "0.5em",
                        color: theme.palette.primary.black,
                        fontSize:theme.typography.h6,
                        fontWeight:theme.typography.fontWeight.bold,
                        textDecoration: "none",
                      }}
                    >
                      Login here
                    </Link>
                  </Typography>
                </div>
              </Grid>
              <Grid container justifyContent="center" gap={2}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    pt: "1em",
                    color: theme.palette.primary.black,
                    textAlign: "center",
                    // Adjust styles for smaller screens if needed
                  }}
                >
                  <a href="mailto:contact@strive2thrive.earth">
                    Report an issue
                  </a>
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    pt: "1em",
                    color: theme.palette.primary.black,
                    textAlign: "center",
                    // Adjust styles for smaller screens if needed
                  }}
                >
                  <a href="https://docs.google.com/forms/d/e/1FAIpQLSfWk8hYTGRg1j649chAiULXf42_5Ypr6kQ7-8qKFMUPxS4ylQ/viewform?usp=pp_url">
                    Provide Feedback
                  </a>
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    pt: "1em",
                    color: theme.palette.primary.black,
                    textAlign: "center",
                    // Adjust styles for smaller screens if needed
                  }}
                >
                  <a href="https://docs.google.com/forms/d/e/1FAIpQLSdhD_w8kis4umQpafEMbF2HjwksuGcifcsXSfy2P9M8oS5j1w/viewform?usp=sf_link">Share your ideas</a>
                </Typography>
              </Grid>
              <Copyright />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default Register;
