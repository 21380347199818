import * as React from 'react';
import {alpha} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import SvgIcon from '@mui/material/SvgIcon';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {visuallyHidden} from '@mui/utils';
import {useEffect, useRef, useState} from 'react';
import moment from 'moment';
import {
    VerifiedIcon,
    ViewMoreIcon,
} from '../assets/icons';

import theme from '../config/theme';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {Chip, FormControl, InputAdornment, InputLabel, Pagination, Select} from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CssTextField from './CssTextField';
import {useDispatch, useSelector} from 'react-redux';
import {assignQuestions} from '../redux/features/question/questionSlice';

const headCells = [
    {
        id: 'question',
        label: 'Question',
        className: '!pl-0',
        width: '30%',
    },
    {
        id: 'answer',
        label: 'Answer',
        width: '25%',
    },
    {
        id: 'tags',
        label: 'TAG',
        width: '10%',
    },
    {
        id: 'created',
        label: 'Created',
        sortable: true,
        width: '10%',
    },
    {
        id: 'verified',
        label: 'Status',
        sortable: true,
        width: '10%',
    },
    {
        id: 'action',
        label: 'Action',
        width: '10%',
    },
];

function QuestionTableHead(props) {
    const {onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort} =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="none"
                           sx={{width: '5%'}}
                >
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all questions',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        sx={{width: headCell.width}}
                        className={headCell.className}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.sortable ?
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel> :
                            <p>{headCell.label}</p>
                        }

                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}


function QuestionTableToolbar(props) {
    const {
        selected,
        setSearchQuery,
        handleMultipleDelete,
        handleOpenCreateQuizModal,
        setFilterQuery,
        setPage,
    } = props;
    const filter = useRef({});
    const [filteringStatus, setFilteringStatus] = useState('');
    const numSelected = selected.length;

    const handleFilterChange = (property, value) => {
        let newFilter = {...filter.current};
        if (value === '') {
            delete newFilter[property];
        } else {
            newFilter[property] = !!value;
        }
        filter.current = newFilter;
        setFilterQuery(newFilter);
        setFilteringStatus(value);
        setPage(1);
    };

    return (
        <Toolbar
            className="tableToolbar"
            sx={{
                pl: {sm: 2},
                pr: {xs: 1, sm: 1},
                display: 'flex',
                justifyContent: 'space-between',
                ...(numSelected > 0 && {
                    bgcolor: (muiTheme) =>
                        alpha(theme.palette.primary.main, muiTheme.palette.action.activatedOpacity),
                }),
            }}
        >

            <CssTextField
                sx={{
                    border: 'none',
                    ':focus': {
                        border: 'none',
                    },
                    width: {
                        xs: '96%',
                        sm: '45%',
                        md: '60%',
                    },
                    ml: {xs: 1.2, sm: 0},
                    backgroundColor: '#fff',
                }}
                placeholder="Search by question"
                variant="outlined"
                size="small"
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchOutlinedIcon/>
                        </InputAdornment>
                    ),
                }}
            />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexGrow: 1,
                    justifyContent: 'space-between',
                    paddingLeft: '2rem',
                }}>

                {numSelected > 0 ? (
                    <>
                        <Box>
                            <Typography
                                sx={{
                                    fontFamily: theme.typography.fontFamily,
                                    fontSize: theme.typography.h7,
                                    fontWeight: theme.typography.fontWeight.semiBold,
                                    color: theme.palette.primary.navy,
                                }}
                            >
                                {numSelected} selected
                            </Typography>
                            {numSelected < 3 && <Typography
                                sx={{
                                    fontFamily: theme.typography.fontFamily,
                                    fontSize: theme.typography.h8,
                                    color: theme.palette.primary.red,
                                    fontWeight: theme.typography.fontWeight.semiBold,
                                    textAlign: 'end',
                                    marginRight: {xs: 4, sm: '30px'},
                                }}
                            >
                                * Select a minimum of three questions to create a quiz.
                            </Typography>
                            }
                        </Box>

                        <Box sx={{
                            display: 'flex',
                            gap: '5px',
                        }}>
                            <Tooltip title="Create Quiz">
                            <span>
                            <Button variant="contained"
                                    disabled={numSelected < 3}
                                    onClick={() => handleOpenCreateQuizModal(selected)}
                                    sx={{
                                        fontFamily: theme.typography.fontFamily,
                                        textTransform: 'none',
                                        fontWeight: '600',
                                        bgcolor: theme.palette.primary.main,
                                        color: '#fff',
                                        '&:hover': {
                                            bgcolor: theme.palette.primary.main_1,
                                            color: theme.palette.primary.black,
                                        },
                                    }}
                                    startIcon={<AddCircleOutlineIcon/>}>
                                Create Quiz
                            </Button>
                                </span>
                            </Tooltip>
                            <Tooltip title="Delete">
                                <Button variant="outlined"
                                        onClick={() => handleMultipleDelete(selected)}
                                        sx={{
                                            fontFamily: theme.typography.fontFamily,
                                            textTransform: 'none',
                                            fontWeight: '600',
                                            color: theme.palette.primary.red,
                                            borderColor: theme.palette.primary.red,
                                            ':hover': {
                                                borderColor: theme.palette.primary.red,
                                                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                            },
                                        }}
                                        startIcon={<DeleteIcon/>}>
                                    Delete
                                </Button>
                            </Tooltip>
                        </Box>
                    </>
                ) : (<FormControl sx={{
                    width: '20%',
                }}>
                    <InputLabel id="status-filter-select-label">Status</InputLabel>
                    <Select
                        labelId="status-filter-select-label"
                        id="status-filter-select"
                        label="Status"
                        value={filteringStatus}
                        onChange={(event) => {
                            handleFilterChange('verified', event.target.value);
                        }}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value={1}>Verified</MenuItem>
                        <MenuItem value={0}>Unverified</MenuItem>
                    </Select>
                </FormControl>)}
            </Box>

        </Toolbar>
    );
}

export default function QuestionTable({
                                          data,
                                          isLoading,
                                          handleOpenEditQuestionModal,
                                          openVerifyDialog,
                                          openDeleteConfirmationDialog,
                                          handleOpenDetailsModal,
                                          handleMultipleDelete,
                                          handleOpenCreateQuizModal,
                                          setFetchQuestionsQuery,
                                      }) {
    const [order, setOrder] = React.useState('');
    const [orderBy, setOrderBy] = React.useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [filterQuery, setFilterQuery] = useState({});
    const [page, setPage] = React.useState(1);
    const [rows, setRows] = React.useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const {selectedQuestions} = useSelector((state) => state.question);
    const dispatch = useDispatch();
    const setSelectedQuestions = (selected) => dispatch(assignQuestions(selected));

    const menuItems =
        [
            {
                id: 'details',
                label: 'View Details',
                icon: VisibilityIcon,
                action: (item) => handleOpenDetailsModal(item),
            },
            {
                id: 'edit',
                label: 'Edit',
                icon: EditIcon,
                action: (item) => handleOpenEditQuestionModal(item),
            },
            {
                id: 'delete',
                label: 'Delete',
                icon: DeleteIcon,
                action: (item) => openDeleteConfirmationDialog(item),
            },
        ];


    useEffect(() => {
        setRows(data?.items ?? []);
    }, [data]);


    useEffect(() => {
        const buildQuery = () => {
            setFetchQuestionsQuery({
                page: page - 1,
                search: searchQuery,
                order,
                orderBy,
                filter: filterQuery,
            });
        };

        buildQuery();
    }, [page, searchQuery, order, orderBy, filterQuery, setFetchQuestionsQuery]);


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = [...rows];
            setSelectedQuestions(newSelected);
            return;
        }
        setSelectedQuestions([]);
    };

    const handleClick = (event, row) => {
        const selectedIndex = selectedQuestions.findIndex(i => i.id === row.id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = [...selectedQuestions, row];
        } else {
            newSelected = [
                ...selectedQuestions.slice(0, selectedIndex),
                ...selectedQuestions.slice(selectedIndex + 1),
            ];
        }

        setSelectedQuestions(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const isSelected = (row) => selectedQuestions.findIndex(i => i.id === row.id) !== -1;


    function renderVerificationStatus(row) {
        return <>
            {row.verified ? (
                <Typography
                    sx={{
                        fontFamily: theme.typography.fontFamily,
                        fontSize: '0.75rem',
                        fontWeight: '600',
                        color: theme.palette.primary.green,
                        display: 'flex',
                        fontStyle: 'italic',
                        gap: '2px',
                        alignItems: 'baseline',
                    }}
                >
                    Verified <img src={VerifiedIcon} alt={'verifiedIcon'}/>
                </Typography>
            ) : (
                <Button
                    size="small"
                    variant="outlined"
                    sx={{
                        whiteSpace: 'nowrap',
                        color: theme.palette.primary.navy,
                        textTransform: 'none',
                        fontWeight: 'bold',
                        fontSize: '0.7rem',
                        borderColor: theme.palette.primary.babyblue,
                        ':hover': {
                            borderColor: theme.palette.primary.navy,
                        },
                        zIndex: 2,
                        padding: 0,
                    }}
                    onClick={() => {
                        openVerifyDialog(row);
                    }}
                >
                    Verify
                </Button>
            )}
        </>;
    }

    function renderMenu(row, displayItemCount = 2) {
        const visibleItems = menuItems.slice(0, displayItemCount);
        const collapsedItems = menuItems.slice(displayItemCount, menuItems.length);
        return <Box sx={{
            display: 'flex', gap: '5px',
        }}>
            {
                visibleItems.map(item => (
                    <Tooltip key={item.id} title={item.label}>
                        <IconButton

                            onClick={() => item.action(row)}>
                            <SvgIcon component={item.icon} inheritViewBox/>
                        </IconButton>
                    </Tooltip>
                ))
            }

            {collapsedItems?.length ? (
                <>
                    <IconButton
                        sx={{border: 'none !important'}}
                        onClick={(e) => setAnchorEl(e.currentTarget)}>
                        <img src={ViewMoreIcon} alt={'viewMore'}></img>
                    </IconButton>

                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={Boolean(anchorEl)}
                        onClose={() => setAnchorEl(null)}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                        slotProps={
                            {
                                paper: {
                                    elevation: 0,
                                    sx: {
                                        backgroundColor: '#fff',
                                        borderRadius: 3,
                                        border: '1px solid #eee',
                                    },
                                },
                            }
                        }
                        transformOrigin={{horizontal: 'right', vertical: 'top'}}
                        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                    >
                        {collapsedItems.map((menuItem) => (
                            <MenuItem
                                sx={{
                                    color: theme.palette.primary.darkGrey,
                                    '&:hover': {
                                        backgroundColor: '#F0F0F0',
                                        fontWeight: `${theme.typography.fontWeight.medium} !important`,
                                    },
                                }}
                                key={menuItem.id}
                                onClick={() => {
                                    menuItem.action(row);
                                    setAnchorEl(false);
                                }}
                            >
                                <ListItemIcon>
                                    <SvgIcon component={menuItem.icon} inheritViewBox/>
                                </ListItemIcon>
                                <ListItemText>{menuItem.label}</ListItemText>
                            </MenuItem>
                        ))}
                    </Menu>
                </>
            ) : null}
        </Box>;
    }

    function renderSkeleton(rows = 10) {
        return (
            <>
                {
                    Array.from({length: rows}, (_, index) => index + 1)
                        .map((_, index) =>
                            <TableRow
                                tabIndex={-1}
                                key={index}
                            >
                                <TableCell padding="none" align="center" sx={{width: '5%'}}>
                                    <Skeleton variant="rectangular" width={20} height={20} sx={{margin: '0 auto'}}/>
                                </TableCell>
                                {
                                    headCells.map(i => (
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            className={i.className}
                                            sx={{width: i.width}}
                                            key={i.id}
                                        >
                                            <Skeleton variant="text" sx={{fontSize: '1.2rem'}}/>
                                        </TableCell>
                                    ))
                                }
                            </TableRow>,
                        )
                }
            </>
        );
    }

    function renderTags(tags) {
        if (Array.isArray(tags)) {
            return tags.map((i, index) => <Chip key={index} sx={{marginRight: '2px', marginBottom: '2px'}} label={i} color="success"/>);
        }
        return tags;
    }

    return (
        <Box sx={{width: '100%', padding: '1.4rem'}} className="questionTable-container">
            <Paper sx={{width: '100%', mb: 2, borderRadius: '15px', overflow: 'hidden'}} elevation={3}>
                <QuestionTableToolbar selected={selectedQuestions}
                                      setSearchQuery={setSearchQuery}
                                      handleMultipleDelete={handleMultipleDelete}
                                      handleOpenCreateQuizModal={handleOpenCreateQuizModal}
                                      setFilterQuery={setFilterQuery}
                                      setPage={setPage}
                />

                <TableContainer sx={{width: '100%', display: 'table', tableLayout: 'fixed'}}>
                    <Table
                        sx={{minWidth: 750}}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        className="questionTable"
                    >
                        <QuestionTableHead
                            numSelected={selectedQuestions.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {isLoading ?
                                renderSkeleton() :
                                rows.map((row, index) => {
                                    const isItemSelected = isSelected(row);
                                    const labelId = `question-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover={false}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.id}
                                            selected={isItemSelected}
                                            sx={{cursor: 'pointer'}}
                                        >
                                            <TableCell padding="none" align="center"
                                                       sx={{width: '5%', padding: '0 !important'}}>
                                                <Checkbox
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    onClick={(event) => handleClick(event, row)}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                    sx={{
                                                        padding: '30px',
                                                        width: '25px',
                                                        height: '25px',
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                className="!pl-0"
                                                sx={{width: '30%'}}
                                            >
                                                {row.question}

                                            </TableCell>
                                            <TableCell
                                                sx={{width: '25%'}}>{row.answer}</TableCell>
                                            <TableCell
                                                className="italic font-light"
                                                sx={{width: '10%'}}>{renderTags(row.tags)}</TableCell>
                                            <TableCell className="italic font-light"
                                                       align="center"
                                                       sx={{width: '10%'}}>{moment.unix(row.created_date_time).format('DD/MM/YYYY')}</TableCell>
                                            <TableCell align="center"
                                                       sx={{width: '10%'}}>
                                                {renderVerificationStatus(row)}
                                            </TableCell>
                                            <TableCell align="center"
                                                       sx={{width: '10%'}}>
                                                {renderMenu(row)}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Pagination count={data?.page_count ?? 0}
                            showFirstButton
                            showLastButton
                            onChange={handleChangePage}
                            page={page}
                            className="tablePagination"
                />
            </Paper>
        </Box>
    );
}
