import api from "../../api/apiSlice";

const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    registerUser: builder.mutation({
      query: (data) => ({
        url: `/user/register`,
        method: "POST",
        body: data,
      }),
    }),
    login: builder.mutation({
      query: (data) => ({
        url: `/user/login`,
        method: "POST",
        body: data,
      }),
    }),
    logout: builder.mutation({
      query: (data) => ({
        url: `/user/logout`,
        method: "POST",
        body: data,
      }),
    }),
    getAllAdmin: builder.query({
      query: ({ query }) => ({
        url: `/admin/all?query=${query}`,
        method: "GET",
      }),
    }),
    updateAccessLevel:builder.mutation({
      query: (data) => {
        return{
        url: `/admin/update/${data.id}`,
        method: "PUT",
        body: data,
      }},
    }),
    createAdmin: builder.mutation({
      query: (data) => ({
        url: `/admin/create`,
        method: "POST",
        body: data,
      }),
    }),
    resetPassword: builder.mutation({
      query: (data) => {
        // console.log(data);
        return{
        url: `/admin/reset`,
        method: "POST",
        body: data,
      }},
    }),
    codeVerification: builder.mutation({
      query: (data) => {
        // console.log('data',data);
        return{
        url: `/admin/reset/verify`,
        method: "POST",
        body: data,
      }},
    }),
    newPassword: builder.mutation({
      query: (data) => {
        console.log('data',data);
        return{
        url: `/admin/reset/password`,
        method: "POST",
        body: data,
      }},
    }),
  }),
});

export const {
  useRegisterUserMutation,
  useLoginMutation,
  useLogoutMutation,
  useGetAllAdminQuery,
  useUpdateAccessLevelMutation,
  useCreateAdminMutation,
  useResetPasswordMutation,
  useCodeVerificationMutation,
  useNewPasswordMutation,
} = userApi;
