import React, {useState} from 'react';
import {
    Box,
    IconButton,
    Button,
    Typography,
    ThemeProvider,
} from '@mui/material';
import theme from '../config/theme';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import {useForm} from 'react-hook-form';
import {useCreateQuestionMutation} from '../redux/features/question/questionApi';
import {useEffect} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import GPTIcon from '../assets/icons/chatgtp-logo.png';
import CssTextField from './CssTextField';
import {
    getFromLocalStorage,
} from '../utils/localStorage';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {xs: '90vw', sm: '60vw', md: '40vw'},
    maxHeight: '85vh',
    overflow: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '8px',
};
const CreateByOpenai = ({open, handleClose, onSuccess, onError}) => {
    const [createQuestion, {data, isError, isLoading, isSuccess, error}] =
        useCreateQuestionMutation();
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm();
    // * * * * create question API * * * *
    const [sent, setSent] = useState(false);
    const onSubmit = (data) => {
        const user = JSON.parse(getFromLocalStorage('user'));
        const newData = {
            method: 'openai',
            questions_required: data.questions_required,
            article_url: data.article_url,
            created_by: user.email,
        };
        setSent(true);
        createQuestion(newData)
            .then((response) => {
                console.log('Mutation success:', response);
            })
            .catch((error) => {
                console.error('Mutation error:', error);
            });
    };

    //  * * * * * * * * * * * Notify user * * * * * * * * * * * * *
    useEffect(() => {
        if (isSuccess && data) {

            onSuccess(data);
            handleClose();
        } else if (isError && error) {
            onError(error);
            handleClose();
        }
    }, [isLoading, data, isError, isSuccess, error, handleClose, onSuccess]);

    return (
        <div>
            <ThemeProvider theme={theme}>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    slots={{backdrop: Backdrop}}
                    slotProps={{
                        backdrop: {
                            timeout: 400,
                        },
                    }}
                >
                    <Fade in={open}>
                        <Box sx={style}>
                            <Box
                                component="form"
                                noValidate
                                onSubmit={handleSubmit(onSubmit)}
                                sx={{
                                    p: {
                                        xs: 2,
                                        sm: 4,
                                    },
                                    pt: {
                                        xs: 5,
                                        sm: 4,
                                    },
                                    position: 'relative',
                                }}
                            >
                                {/* close icon */}
                                {sent === true ? null :
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: 0,
                                            right: 0,
                                            mt: 0.5,
                                            mr: 0.5,
                                        }}
                                    >
                                        <IconButton size="small" onClick={handleClose}>
                                            <CloseIcon sx={{color: 'gray'}}/>
                                        </IconButton>
                                    </Box>}
                                {/* close icon ends*/}
                                {/* Title */}
                                <Box display="flex" justifyContent="center">
                                    <img src={GPTIcon} alt="chatGPT 3.5 icon"
                                         style={{height: '20px', width: '20px'}}/>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            textAlign: 'center',
                                            ml: 1.5,
                                            color: theme.palette.primary.black,
                                            fontSize: theme.typography.h6,
                                            fontWeight: theme.typography.fontWeight.bold,
                                        }}
                                    >
                                        Generate Questions by GhatGPT
                                    </Typography>
                                </Box>
                                {/* Title ends */}
                                {/* required data */}
                                <Typography
                                    sx={{
                                        mt: 3,
                                        ml: 1.5,
                                        color: 'gray',
                                        fontSize: theme.typography.h6,
                                        fontWeight: theme.typography.fontWeight.medium,
                                    }}
                                >
                                    Maximum Number of Questions to Generate:
                                </Typography>
                                <CssTextField
                                    sx={{
                                        mt: '3px',
                                        mr: {
                                            xs: 0,
                                            lg: 1,
                                        },
                                    }}
                                    id="outlined-basic"
                                    placeholder="Maximun 20 each generation"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    //   set the number smaller then 20
                                    {...register('questions_required', {
                                        required: true,
                                        pattern: {
                                            value: /^[0-9]+$/, //pattern requires a string consists only digits (0 to 9)
                                            message: 'Please enter a valid integer.',
                                        },
                                        validate: (value) =>
                                            parseInt(value) <= 20 || 'Value must be 20 or less.',
                                    })}
                                />
                                {errors.questions_required && (
                                    <Typography sx={{color: 'red'}} variant="subtitle1">
                                        {errors.questions_required.message}
                                    </Typography>
                                )}
                                <Box display="flex">
                                    <Typography
                                        sx={{
                                            display: 'flex',
                                            mt: 3,
                                            mb: '3px',
                                            ml: 1.5,
                                            color: theme.palette.primary.darkGrey,
                                            fontSize: theme.typography.h6,
                                            fontWeight: theme.typography.fontWeight.medium,
                                        }}
                                    >
                                        Article URL:
                                    </Typography>
                                </Box>
                                <CssTextField
                                    sx={{
                                        mt: '3px',
                                        mr: {
                                            xs: 0,
                                            lg: 1,
                                        },
                                    }}
                                    id="outlined-basic"
                                    placeholder="Enter the Article URL for Question Generation"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    {...register('article_url', {
                                        required: 'Article URL is required',
                                        pattern: {
                                            value: /^(ftp|http|https):\/\/[^ "]+$/,
                                            message: 'Invalid URL format',
                                        },
                                    })}
                                />
                                {errors.article_url && (
                                    <Typography sx={{color: 'red'}} variant="subtitle1">
                                        {errors.article_url.message}
                                    </Typography>
                                )}
                                {/* Required data ends */}
                                {/* submit start */}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        mt: 3,
                                    }}
                                >
                                    <Button
                                        type="submit"
                                        disabled={isLoading}
                                        sx={{
                                            whiteSpace: 'nowrap',
                                            borderRadius: 5,
                                            fontWeight: 'bold',
                                            bgcolor: theme.palette.primary.main,
                                            '&:hover': {
                                                bgcolor: theme.palette.primary.navy,
                                            },
                                            flexGrow: '0',
                                            textTransform: 'capitalize',
                                            mr: {
                                                xs: 0,
                                                lg: 4,
                                            },
                                            mb: {
                                                xs: 2,
                                                lg: 0,
                                            },
                                            width: '170px',
                                        }}
                                        size="medium"
                                        variant="contained"
                                    >
                                        <SendIcon sx={{marginRight: '5px'}}/>
                                        Send Request
                                    </Button>
                                </Box>
                                {isLoading ? (
                                    <Typography
                                        sx={{
                                            textAlign: 'center',
                                            fontSize: theme.typography.h7,
                                            color: theme.palette.primary.red,
                                            fontWeight: theme.typography.fontWeight.bold,
                                        }}
                                    >
                                        Please wait...
                                        <CircularProgress size="1rem" color="success"/>

                                    </Typography>

                                ) : null}
                                {/* submit ends */}
                            </Box>
                        </Box>
                    </Fade>
                </Modal>
            </ThemeProvider>
        </div>
    );
};

export default CreateByOpenai;
