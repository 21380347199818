import React, {useEffect} from 'react';
import {
    Box,
    TextField,
    Typography,
    Button,
    Paper,
    Grid,
    Select,
    MenuItem, ClickAwayListener,
} from '@mui/material';
import {useState} from 'react';
import theme from '../config/theme';
import SendIcon from '@mui/icons-material/Send';
import updateIcon from '../assets/icons/updateIcon.svg';
import confirmIcon from '../assets/icons/confirm.svg';
import cancelIcon from '../assets/icons/cancel.svg';
import InfoIcon from '@mui/icons-material/Info';
import {Link} from 'react-router-dom';
import {useUpdateQuizMutation} from '../redux/features/quiz/quizApi';

import {styled} from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

const CssTextField = styled(TextField)({
    '& .MuiInputBase-input': {
        fontSize: theme.typography.h7,
        fontWeight: theme.typography.fontWeight.medium,
        backgroundColor: theme.palette.primary.white,
        borderRadius: 5,
        paddingLeft: 5,
        overflow: 'visible',
    },
    '& .MuiInput-underline:before, & .MuiInput-underline:after, & .MuiInput-underline:hover:before':
        {
            borderBottom: '1px solid navy',
        },
});

const QuizCard = ({index, item, handleTextClick, setSeverity, setAlert}) => {

    const [updateQuiz, {data, isError, isSuccess, error}] =
        useUpdateQuizMutation();

    const [tooltipOpen, setTooltipOpen] = React.useState(false);

    const [update, setUpdate] = useState(false);
    const [quizName, setQuizName] = useState(item?.quiz_name || '');
    const [fieldError, setFieldError] = useState(false);

    const [quizTime, setQuizTime] = useState(item?.quiz_time);
    const [timeFieldError, setTimeFieldError] = useState(false);
    const [showScore, setShowScore] = useState(item?.show_score);
    const [randomizeQuestions, setRandomizeQuestions] = useState(item?.randomize_questions);
    const [showResult, setShowResult] = useState(item?.show_final_result);
    useEffect(() => {
        if (showScore === false) {
            setShowResult(false);
        }
    }, [showScore]);
    const handleConfirm = () => {
        if (quizName === '') {
            setFieldError(true);
            return;
        } else {
            setFieldError(false);
        }
        const intQuizTime = parseInt(quizTime, 10);
        if (
            isNaN(intQuizTime) ||
            intQuizTime < -1 ||
            intQuizTime > 60 ||
            intQuizTime === 0
        ) {
            setTimeFieldError(true);
            return;
        } else {
            setTimeFieldError(false);
        }
        const newData = {
            id: item.id,
            quiz_name: quizName,
            quiz_time: quizTime,
            show_score: showScore,
            show_final_result: showResult,
            randomize_questions: randomizeQuestions,
        };
        updateQuiz(newData);
        handleReset();
    };
    const handleCancel = () => {
        handleReset();
    };
    const handleReset = () => {
        setUpdate(false);
        setQuizName(item?.quiz_name);
        setFieldError(false);
        setTimeFieldError(false);
    };
    const handleTooltipClose = () => {
        setTooltipOpen(false);
    };

    const handleTooltipOpen = () => {
        setTooltipOpen(true);
    };

    useEffect(() => {
        if (isSuccess && data) {
            setSeverity(data.status);
            setAlert('Updated successfully');
        } else if (isError && error) {
            setSeverity('error');
            setAlert(error);
        }
    }, [data, isSuccess, isError, error, setSeverity, setAlert]);
    return (
        <>
            <Paper
                key={index}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    p: 2,
                    pt: 3,
                    borderRadius: '5px',
                    position: 'relative',
                }}
            >
                {/* Top row starts */}
                <div
                    style={{
                        position: 'absolute', // absolute for top
                        top: 0,
                        left: 0,
                        width: 'calc(100% + 3px)',
                        height: '15%',
                        backgroundColor: theme.palette.primary.navy,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderRadius: 4,
                        marginLeft: '-1.5px', // Add negative margin to the left
                        marginRight: '-1.5px',
                    }}
                >
                    <Box display="flex" alignItems="center">
                        {/* <Box sx={{ marginTop: "5px", ml: 6 }}>
              <input
                type="checkbox"
                className="custom-checkbox-quiz"
                checked={selectedQuizzes.includes(item)}
                onChange={(e) => handleCheckboxChange(e, item)}
              />
            </Box> */}
                        <Typography
                            sx={{
                                ml: 4.5,
                                mr: 0.5,
                                color: theme.palette.primary.white,
                                fontSize: theme.typography.h7,
                                fontWeight: theme.typography.fontWeight.semiBold,
                            }}
                        >
                            {(index += 1)}.
                        </Typography>
                        {update ? (
                            <CssTextField
                                placeholder={item?.quiz_name}
                                fullWidth
                                variant="standard"
                                value={quizName}
                                onChange={(e) => setQuizName(e.target.value)}
                                error={fieldError}
                                helperText={fieldError ? 'A name is required' : ''}
                                sx={{minWidth: '150px'}}/>
                        ) : (
                            <Typography
                                sx={{
                                    marginLeft: 0.5,
                                    color: theme.palette.primary.white,
                                    fontSize: theme.typography.h7,
                                    fontWeight: theme.typography.fontWeight.semiBold,
                                }}
                            >
                                {item?.quiz_name}
                            </Typography>
                        )}
                    </Box>
                    {update ? (
                        <Box display="flex">
                            <Button
                                variant="text"
                                wrap="none"
                                sx={{
                                    minWidth: '30px',
                                    borderRadius: 50,
                                    padding: '5px',
                                    ':hover': {
                                        backgroundColor: theme.palette.primary.darkGrey,
                                    },
                                }}
                                onClick={handleConfirm}
                            >
                                <img src={confirmIcon} alt="confirmIcon"/>
                            </Button>
                            <Button
                                variant="text"
                                wrap="none"
                                sx={{
                                    marginRight: 1,
                                    minWidth: '30px',
                                    borderRadius: 50,
                                    ':hover': {
                                        backgroundColor: theme.palette.primary.grey,
                                    },
                                }}
                                onClick={handleCancel}
                            >
                                <img src={cancelIcon} alt="cancelIcon"/>
                            </Button>
                        </Box>
                    ) : (
                        <Button
                            variant="text"
                            wrap="none"
                            sx={{
                                marginRight: 1,
                                padding: '5px',
                                ':hover': {
                                    backgroundColor: 'transparent',
                                },
                            }}
                            onClick={() => setUpdate(true)}
                        >
                            <img src={updateIcon} alt="updateIcon"/>
                            <Typography
                                sx={{
                                    fontSize: theme.typography.h9,
                                    fontWeight: theme.typography.fontWeight.semiBold,
                                    color: theme.palette.primary.white,
                                }}
                            >
                                edit
                            </Typography>
                        </Button>
                    )}

                    {/* Top row ends */}
                </div>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                display: 'flex',
                                backgroundImage:
                                    'linear-gradient(120deg, #fdfbfb 0%, #ebedee 120%)',
                                mt: '35px',
                                borderRadius: 5,
                                pl: '17px',
                            }}
                        >
                            <Box sx={{width: '53%', my: 1.5}}>
                                <Typography
                                    sx={{
                                        fontFamily: theme.typography.fontFamily,
                                        fontSize: theme.typography.h7,
                                        fontWeight: theme.typography.fontWeight.regular,
                                        color: 'grey',
                                    }}
                                >
                                    CREATED
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: theme.typography.fontFamily,
                                        fontSize: theme.typography.h7,
                                        fontWeight: theme.typography.fontWeight.medium,
                                        // color: theme.palette.primary.darkGrey,
                                    }}
                                >
                                    {new Date(
                                        item?.created_date_time * 1000,
                                    ).toLocaleDateString()}{' '}
                                    {new Date(item?.created_date_time * 1000)
                                        .toLocaleTimeString()
                                        .slice(0, 5)}
                                </Typography>
                            </Box>
                            <Box sx={{width: '47%', my: 1.5}}>
                                <Typography
                                    sx={{
                                        fontFamily: theme.typography.fontFamily,
                                        fontSize: theme.typography.h7,
                                        fontWeight: theme.typography.fontWeight.regular,
                                        color: 'grey',
                                    }}
                                >
                                    UPDATE
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: theme.typography.fontFamily,
                                        fontSize: theme.typography.h7,
                                        fontWeight: theme.typography.fontWeight.medium,
                                    }}
                                >
                                    {item?.updated_date_time
                                        ? new Date(
                                            item?.updated_date_time * 1000,
                                        ).toLocaleDateString()
                                        : 'No'}{' '}
                                    {item?.updated_date_time
                                        ? new Date(item?.updated_date_time * 1000)
                                            .toLocaleTimeString()
                                            .slice(0, 5)
                                        : 'update'}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                {/* content starts */}
                {/* created by */}
                <Box
                    display="flex"
                    sx={{
                        mt: '10px',
                        ml: '10px',
                        pl: '7px',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        sx={{

                            width: '53%',
                            fontFamily: theme.typography.fontFamily,
                            fontSize: theme.typography.h7,
                            fontWeight: theme.typography.fontWeight.regular,
                            color: theme.palette.primary.darkGrey,
                        }}
                    >
                        Created By
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: theme.typography.fontFamily,
                            fontSize: theme.typography.h6,
                            fontWeight: theme.typography.fontWeight.semiBold,
                        }}
                    >
                        {item.created_by === null ? '@@@' : null}
                    </Typography>
                </Box>
                {/* Quiz time */}
                <Box
                    display="flex"
                    sx={{
                        my: '3px',
                        ml: '10px',
                        pl: '7px',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        sx={{

                            width: '53%',
                            fontFamily: theme.typography.fontFamily,
                            fontSize: theme.typography.h7,
                            fontWeight: theme.typography.fontWeight.regular,
                            color: theme.palette.primary.darkGrey,
                        }}
                    >
                        Quiz Time
                    </Typography>
                    {/* Quiz time Data */}
                    {update ? (
                        <CssTextField
                            placeholder={String(item?.quiz_time)}
                            size="small"
                            variant="standard"
                            value={quizTime || ''}
                            onChange={(e) => setQuizTime(e.target.value)}
                            inputProps={{
                                style: {textAlign: 'center', width: 20},
                                maxLength: 2, // Limit the input to 2 characters
                            }}
                        />
                    ) : (
                        <Typography
                            sx={{
                                fontFamily: theme.typography.fontFamily,
                                fontSize: theme.typography.h6,
                                fontWeight: theme.typography.fontWeight.semiBold,
                            }}
                        >
                            {item?.quiz_time > 0
                                ? `${item?.quiz_time} ${item?.quiz_time === 1 ? 'min' : 'mins'}`
                                : 'No Time Limit'}
                        </Typography>
                    )}
                </Box>
                {/* Show final score */}
                <Box
                    display="flex"
                    sx={{
                        my: '3px',
                        ml: '10px',
                        pl: '7px',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        sx={{

                            width: '53%',
                            fontFamily: theme.typography.fontFamily,
                            fontSize: theme.typography.h7,
                            fontWeight: theme.typography.fontWeight.regular,
                            color: theme.palette.primary.darkGrey,
                        }}
                    >
                        Final Score
                    </Typography>
                    {update ? (
                        <Select
                            size="small"
                            variant="standard"
                            value={showScore}
                            onChange={(e) => setShowScore(e.target.value)}
                            sx={{fontSize: theme.typography.h9}}
                        >
                            <MenuItem value={true} sx={{fontSize: theme.typography.h9}}>
                                Show
                            </MenuItem>
                            <MenuItem value={false} sx={{fontSize: theme.typography.h9}}>
                                Hide
                            </MenuItem>
                        </Select>
                    ) : (
                        <Typography
                            sx={{
                                fontFamily: theme.typography.fontFamily,
                                fontSize: theme.typography.h8,
                                fontWeight: theme.typography.fontWeight.semiBold,
                                borderRadius: 2,
                                px: 1,
                                backgroundColor: theme.palette.secondary.grey,
                                color: item?.show_score
                                    ? theme.palette.primary.green
                                    : theme.palette.primary.red,
                            }}
                        >
                            {item?.show_score === true ? 'Show' : 'Hide'}
                        </Typography>
                    )}
                </Box>
                {/* Show randomize question */}
                <Box
                    display="flex"
                    sx={{
                        my: '3px',
                        ml: '10px',
                        pl: '7px',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        sx={{

                            width: '53%',
                            fontFamily: theme.typography.fontFamily,
                            fontSize: theme.typography.h7,
                            fontWeight: theme.typography.fontWeight.regular,
                            color: theme.palette.primary.darkGrey,
                        }}
                    >
                        Randomize Questions
                    </Typography>
                    {update ? (
                        <Select
                            size="small"
                            variant="standard"
                            value={randomizeQuestions}
                            onChange={(e) => setRandomizeQuestions(e.target.value)}
                            sx={{fontSize: theme.typography.h9}}
                        >
                            <MenuItem value={true} sx={{fontSize: theme.typography.h9}}>
                                On
                            </MenuItem>
                            <MenuItem value={false} sx={{fontSize: theme.typography.h9}}>
                                Off
                            </MenuItem>
                        </Select>
                    ) : (
                        <Typography
                            sx={{
                                fontFamily: theme.typography.fontFamily,
                                fontSize: theme.typography.h8,
                                fontWeight: theme.typography.fontWeight.semiBold,
                                borderRadius: 2,
                                px: 1,
                                backgroundColor: theme.palette.secondary.grey,
                                color: randomizeQuestions
                                    ? theme.palette.primary.green
                                    : theme.palette.primary.red,
                            }}
                        >
                            {randomizeQuestions === true ? 'On' : 'Off'}
                        </Typography>
                    )}
                </Box>
                {/* Show final result summary*/}
                <Box
                    display="flex"
                    sx={{
                        my: '3px',
                        ml: '10px',
                        pl: '7px',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        sx={{

                            width: '53%',
                            fontFamily: theme.typography.fontFamily,
                            fontSize: theme.typography.h7,
                            fontWeight: theme.typography.fontWeight.regular,
                            color: theme.palette.primary.darkGrey,
                        }}
                    >
                        Result Summary
                    </Typography>
                    {update ? (
                        <Select
                            size="small"
                            variant="standard"
                            value={showResult}
                            onChange={(e) => setShowResult(e.target.value)}
                            sx={{fontSize: theme.typography.h9}}
                        >
                            <MenuItem value={true} sx={{fontSize: theme.typography.h9}}>
                                Show
                            </MenuItem>
                            <MenuItem value={false} sx={{fontSize: theme.typography.h9}}>
                                Hide
                            </MenuItem>
                        </Select>
                    ) : (
                        <Typography
                            sx={{
                                fontFamily: theme.typography.fontFamily,
                                fontSize: theme.typography.h8,
                                fontWeight: theme.typography.fontWeight.semiBold,
                                borderRadius: 2,
                                px: 1,
                                backgroundColor: theme.palette.secondary.grey,
                                color: item?.show_final_result
                                    ? theme.palette.primary.green
                                    : theme.palette.primary.red,
                            }}
                        >
                            {item?.show_final_result === true ? 'Show' : 'Hide'}
                        </Typography>
                    )}
                </Box>
                {/* Questions number */}
                <Box
                    display="flex"
                    sx={{
                        my: '3px',
                        ml: '10px',
                        pl: '7px',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        sx={{

                            width: '53%',
                            fontFamily: theme.typography.fontFamily,
                            fontSize: theme.typography.h7,
                            fontWeight: theme.typography.fontWeight.regular,
                            color: theme.palette.primary.darkGrey,
                        }}
                    >
                        Questions
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: theme.typography.fontFamily,
                            fontSize: theme.typography.h6,
                            fontWeight: theme.typography.fontWeight.semiBold,
                        }}
                    >
                        {item?.questions.length}
                    </Typography>
                    <ClickAwayListener onClickAway={handleTooltipClose}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Tooltip
                                PopperProps={{
                                    disablePortal: true,
                                }}
                                onClose={handleTooltipClose}
                                open={tooltipOpen}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                arrow
                                title={
                                    item?.questions.map((questionItem, index) => `${index + 1}. ${questionItem.question}`).join('\n')
                                }
                            >
                                <IconButton aria-label="info" onClick={handleTooltipOpen} sx={{ml: 1, p: 0}}>
                                    <InfoIcon fontSize="small" />
                                </IconButton>

                            </Tooltip>
                        </div>
                    </ClickAwayListener>
                </Box>
                {/* Total Attempts*/}
                <Box
                    display="flex"
                    sx={{
                        my: '3px',
                        ml: '10px',
                        pl: '7px',
                    }}
                >
                    <Typography
                        sx={{

                            width: '53%',
                            fontFamily: theme.typography.fontFamily,
                            fontSize: theme.typography.h7,
                            fontWeight: theme.typography.fontWeight.regular,
                            color: theme.palette.primary.darkGrey,
                        }}
                    >
                        Total Attempts
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: theme.typography.fontFamily,
                            fontSize: theme.typography.h6,
                            fontWeight: theme.typography.fontWeight.semiBold,
                        }}
                    >
                        {item?.leaderboard.length}
                    </Typography>
                </Box>
                {/* Share Link & Leaderboard */}
                <Box display="flex">
                    <Box sx={{ml: 2, width: '50%'}}>
                        <Button
                            variant="contained"
                            sx={{
                                whiteSpace: 'nowrap',
                                color: theme.palette.primary.main,
                                textTransform: 'none',
                                fontFamily: theme.typography.fontFamily,
                                fontSize: theme.typography.h8,
                                fontWeight: theme.typography.fontWeight.medium,
                                borderRadius: 10,
                                border: '0.5px solid #ddd',
                                bgcolor: 'transparent',
                                ':hover': {
                                    bgcolor: theme.palette.secondary.grey,
                                    color: theme.palette.primary.navy,
                                },
                                px: '12px',
                                py: 0,
                            }}
                            onClick={() => handleTextClick(item)}
                        >
                            Share Link
                            <SendIcon
                                size="small"
                                sx={{
                                    fontSize: '10px',
                                    marginLeft: '5px',
                                    mb: 0.5,
                                    transform: 'rotate(-40deg)',
                                }}
                            />
                        </Button>
                    </Box>
                    {/* Leaderboard */}
                    <Link
                        to={`/leaderboard/${item?.id}`} //must check here later when taking care of leaderborad
                        style={{
                            textDecoration: 'none',
                            cursor: 'pointer',
                        }}
                    >
                        <Button
                            variant="contained"
                            sx={{
                                whiteSpace: 'nowrap',
                                color: theme.palette.primary.main,
                                textTransform: 'none',
                                fontFamily: theme.typography.fontFamily,
                                fontSize: theme.typography.h8,
                                fontWeight: theme.typography.fontWeight.medium,
                                borderRadius: 10,
                                border: '0.5px solid #ddd',
                                bgcolor: 'transparent',
                                ':hover': {
                                    bgcolor: theme.palette.secondary.grey,
                                    color: theme.palette.primary.navy,
                                },
                                px: '12px',
                                py: 0,
                            }}
                        >
                            Leaderboard
                        </Button>
                    </Link>
                </Box>
                {update ? (
                    <Typography
                        sx={{
                            mt: 0.5,
                            ml: 2,
                            fontSize: theme.typography.h8,
                            fontWeight: theme.typography.fontWeight.semiBold,
                            color: theme.palette.primary.darkGrey,
                        }}
                    >
                        * Leave -1 for 'No time limit'
                    </Typography>
                ) : null}
                {timeFieldError ? (
                    <Typography
                        sx={{
                            mt: 0.5,
                            ml: 2,
                            fontSize: theme.typography.h8,
                            fontWeight: theme.typography.fontWeight.semiBold,
                            color: theme.palette.primary.red,
                        }}
                    >
                        Enter a valid quiz time between -1 and 60 (excluding 0)
                    </Typography>
                ) : null}
            </Paper>
        </>
    );
};

export default QuizCard;
