import {
  Box,
  InputAdornment,
  Pagination,
  PaginationItem,
  Stack,
  TextField,
  Typography,
  // Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
// import deleteIcon from "../assets/icons/deleteIcon.svg";
import {
  useGetAllQuizQuery,
  // useDeleteQuizMutation,
} from "../redux/features/quiz/quizApi";
import Loading from "../components/Loading";
import { styled } from "@mui/material/styles";
import theme from "../config/theme";
import Snackbar from "@mui/material/Snackbar";
import SnackAlert from "@mui/material/Alert";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   removeFromSelectedQuizzes,
// } from "../redux/features/quiz/quizSlice";
import QuizCard from "../components/QuizCard";
import ClipboardJS from 'clipboard';

const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#ADC4CE",
      borderRadius: 15,
    },
    "&:hover fieldset": { borderColor: "#ADC4CE" },
    "&.Mui-focused fieldset": { borderColor: "#ADC4CE", borderWidth: "0.1em" },
    "& .MuiInputBase-input": { fontSize: "14px", color: "#2E4374" },
  },
  "& .MuiInputLabel-root": {
    color: "rgb(50,86,60,59%)",
  },
});

const Quiz = () => {
  // * * * * * * * * * * Redux * * * * * * * * * *
  // const { selectedQuizzes } = useSelector((state) => state.quiz);
  // const dispatch = useDispatch();
  // * * * * * * * * States * * * * * * *
  const [severity, setSeverity] = useState("success");
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState(null);

  // * * * * * * * * API * * * * * * *
  const { data, refetch, isLoading } = useGetAllQuizQuery({
    page: page,
    query: searchQuery ?? "",
  });

  // const [deleteQuiz, { isError, isSuccess, error }] = useDeleteQuizMutation();
  //----------Multiple Delete-------
  // const [deleteInitiated, setDeleteInitiated] = useState(false);
  // const handleMultipleDelete = () => {
  //   if (selectedQuizzes.length <= 0) {
  //     setSeverity("warning");
  //     setAlert(
  //       <p>
  //         You must select at least <b>1</b> question
  //       </p>
  //     );
  //   } else {
  //     setSeverity("warning");
  //     setDeleteInitiated(true);
  //     setAlert(
  //       <p>
  //         {" "}
  //         Are you sure you want to delete <b>{selectedQuizzes.length}</b> quiz
  //         {selectedQuizzes.length > 1 ? "zes" : ""}?{" "}
  //       </p>
  //     );
  //   }
  // };
  // const handleMultipleDeleteConfirmed = async () => {
  //   handleCloseSnackbar();
  //   const deletePromises = selectedQuizzes.map((item) => {
  //     return deleteQuiz(item.id);
  //   });
  //   try {
  //     await Promise.all(deletePromises);
  //     selectedQuizzes.forEach((item) => {
  //       dispatch(removeFromSelectedQuizzes(item));
  //     });
  //   } catch (error) {
  //     console.error("Error deleting quizzes:", error);
  //   }
  // };

  //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  // remember to set the isUpdateSuccess, isError and err
  // useEffect(() => {
  //   if (isSuccess && data) {
  //     setSeverity("success");
  //     setAlert("Deleted successfully");
  //     setDeleteInitiated(false);
  //   } else if (isError && error) {
  //     toast.error("isError", error);
  //     setSeverity("error");
  //     setAlert(error);
  //   }
  // }, [data, isError, isSuccess, error]);
  // * * * * * * * * Functions * * * * * * *
  const handleTextClick = (quiz) => {
    const textToCopy = document.URL + `/attempt/${quiz.id}`;
    // Create a button element for ClipboardJS 
    const copyButton = document.createElement('button');
    copyButton.setAttribute('data-clipboard-text', textToCopy);
    copyButton.textContent = 'Copy to Clipboard';

    // Append the button to the DOM
    document.body.appendChild(copyButton);

    // Initialize ClipboardJS with the button 
    const clipboard = new ClipboardJS(copyButton);

    clipboard.on('success', (e) => {
        setAlert("Link copied to clipboard");
        // Remove the temporary button from the DOM
        document.body.removeChild(e.trigger);
        clipboard.destroy();
    });

    clipboard.on('error', () => {
        console.error("Could not copy the URL");
        // remove the temporary button from the DOM
        document.body.removeChild(copyButton);
        clipboard.destroy();
    });
    copyButton.click();
};
  const [alert, setAlert] = useState("");

  const handleChange = (event, value) => {
    setPage(value);
  };
  const handleCloseSnackbar = () => {
    setAlert();
  };
  useEffect(() => {
    refetch();
  }, [refetch, page]);
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Box>
          {!alert ? null : (
            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={true}
              autoHideDuration={6000}
              onClose={handleCloseSnackbar}
            >
              {/* {deleteInitiated === true ? (
                <SnackAlert severity={severity} onClose={handleCloseSnackbar}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      fontWeight: "bold",
                    }}
                  >
                    {alert}
                    <div>
                      <Button
                        variant="contained"
                        color="secondary"
                        sx={{
                          color: theme.palette.primary.main,
                          fontFamily: theme.typography.fontFamily,
                          fontSize: "0.8em",
                          fontWeight: "bold",
                          borderRadius: 15,
                          backgroundColor: "#f7d7db",
                          ":hover": { bgcolor: "#FFBFBF" },
                        }}
                        // onClick={handleMultipleDeleteConfirmed}
                      >
                        Yes
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        sx={{
                          ml: 1,
                          fontFamily: theme.typography.fontFamily,
                          fontSize: "0.8em",
                          fontWeight: "bold",
                          color: theme.palette.primary.main,
                          borderRadius: 15,
                          backgroundColor: "#FFF",
                          ":hover": { bgcolor: "#A6D0DD" },
                        }}
                        onClick={handleCloseSnackbar}
                      >
                        No
                      </Button>
                    </div>
                  </div>
                </SnackAlert>
              ) : ( */}
                <SnackAlert severity={severity} onClose={handleCloseSnackbar}>
                  {alert}
                </SnackAlert>
              {/* )} */}
            </Snackbar>
          )}
          {/* ========= Search and filter area start ========= */}
          <Box
            sx={{
              display: {
                xs: "block",
                sm: "flex",
              },
              px: {
                xs: 1.5,
                sm: 3,
              },
              bgcolor: "#fff",
              marginBottom: "10px",
              maxWidth: "100%",
              position: "sticky",
              top: "0",
              pt: { xs: 9, sm: 10, md: 3 },
              pb: 1,
              zIndex: 5,
            }}
          >
            <CssTextField
              sx={{
                width: { xs: "100%", sm: "60%" },
                bgcolor: "#fff",
                borderRadius: "5px",
                border: "none",
                ":focus": {
                  border: "none",
                },
              }}
              id="outlined-basic"
              placeholder="search by name"
              variant="outlined"
              size="small"
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Box
              display="flex"
              justifyContent="space-between"
              sx={{ width: { xs: "100%", sm: "40%" } }}
            >
              <Typography
                sx={{
                  mt: 1.2,
                  mb:{xs:-1,sm:0},
                  ml: { xs: "16px", sm: 2 },
                  fontFamily: theme.typography.fontFamily,
                  fontSize: theme.typography.h6,
                  fontWeight: "600",
                  color: theme.palette.primary.navy,
                }}
              >
                Total Quizzes: {data?.total_quizzes}
              </Typography>
              {searchQuery ? (
                <Typography
                  sx={{
                    mt: 1.2,
                    ml: { xs: "16px", sm: 2 },
                    fontFamily: theme.typography.fontFamily,
                    fontSize: theme.typography.h6,
                    fontWeight: "600",
                    color: theme.palette.primary.navy,
                  }}
                >
                  Results Found: {data?.match_search_count}
                </Typography>
              ) : null}
              {/* <Button
                variant="text"
                wrap="none"
                onClick={() => handleMultipleDelete()}
                sx={{
                  whiteSpace: "nowrap",
                  // py: "8px",
                  mt: 0.5,
                  borderRadius: 10,
                  fontFamily: theme.typography.fontFamily,
                  fontSize: "12px",
                  fontWeight: "600",
                  textTransform: "none",
                  color: theme.palette.primary.red,
                  ":hover": {
                    bgcolor: "#eee",
                  },
                }}
              >
                <img src={deleteIcon} alt="deleteIcon" />
                &nbsp; Delete
              </Button> */}
            </Box>
          </Box>
          {/* ========= Search and filter area end ========= */}

          <Box
            sx={{
              display: "block",
              px: {
                xs: 1.5,
                sm: 3,
              },
            }}
          >
            {/* ========= Quiz start ========= */}
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(2, 1fr)",
                  md: "repeat(3, 1fr)",
                },
                gap: "20px",
                width: "100%",
                fontFamily: theme.typography.fontFamily,
              }}
            >
              {data?.quizzes?.map((item, index) => (
                <QuizCard
                  key={index}
                  index={index}
                  item={item}
                  handleTextClick={handleTextClick}
                  setSeverity={setSeverity}
                  setAlert={setAlert}
                />
              ))}
            </Box>
            {/* ========= Quiz end ========= */}

            {/* ========= Page footer start ========= */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "50px",
              }}
            >
              {/* ========= Pagination start ========= */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Stack spacing={2}>
                  <Pagination
                    showFirstButton
                    showLastButton
                    style={{ margin: "auto auto" }}
                    sx={{ pt: 2, fontFamily: theme.typography.fontFamily }}
                    count={
                      isNaN(Math.ceil(data?.data?.total / 10))
                        ? 1
                        : Math.ceil(data?.data?.total / 10)
                    }
                    renderItem={(item) => (
                      <PaginationItem
                        sx={{ color: "grey" }}
                        slots={{
                          previous: ArrowBackIcon,
                          next: ArrowForwardIcon,
                        }}
                        {...item}
                      />
                    )}
                    onChange={handleChange}
                    size="medium"
                  />
                </Stack>
              </Box>
              {/* ========= Pagination and footer end ========= */}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Quiz;
