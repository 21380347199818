import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import logoIcon from "../assets/icons/logoIcon.svg";
import MenuIcon from "@mui/icons-material/Menu";
import questionsIcon from "../assets/icons/questions.svg";
import quizIcon from "../assets/icons/quiz.svg";
import admin from "../assets/icons/admin.svg";
import logoutIcon from "../assets/icons/logout.svg";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { useLogoutMutation } from "../redux/features/user/userApi";
import {
  getFromLocalStorage,
  removeFromLocalStorage,
} from "../utils/localStorage";
import { Button } from "@mui/material";
import toast from "react-hot-toast";
import { ThemeProvider } from "@mui/material";
import theme from "../config/theme";
import dashboardIcon from "../assets/icons/dashboard.svg"
import Snackbar from "@mui/material/Snackbar";
import SnackAlert from "@mui/material/Alert";

function Navbar() {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const user = JSON.parse(getFromLocalStorage("user"));
  const menuItems = [];
  const indexIcons = [];
  if (user?.role !== "superadmin") {
    menuItems.push("Dashboard","Questions", "Quiz", "Logout");
    indexIcons.push(
      { icon: dashboardIcon, alt: "dashboardIcon" },
      { icon: questionsIcon, alt: "questionsIcon" },
      { icon: quizIcon, alt: "quizIcon" },
      { icon: logoutIcon, alt: "logoutIcon" }
    );
  } else {
    menuItems.push("Dashboard","Questions", "Quiz", "Admin", "Logout");
    indexIcons.push(
      { icon: dashboardIcon, alt: "dashboardIcon" },
      { icon: questionsIcon, alt: "questionsIcon" },
      { icon: quizIcon, alt: "quizIcon" },
      { icon: admin, alt: "adminIcon" },
      { icon: logoutIcon, alt: "logoutIcon" }
    );
  }
  const handleMenuItemClick = (index) => {
    handleCloseNavMenu();
    let path = `/${menuItems[index]}`;
    if (menuItems[index] === "Dashboard") {
      path = "/";
    }
    if (menuItems[index] === "Logout") {
      handleLogout();
    } else navigate(path);
  };
  // * * * * * * * * * * * * User Logout API * * * * * * * * * * * *
  const [logout, { data, isError }] = useLogoutMutation();
  const [logoutInitiated, setLogoutInitiated] = React.useState(false);
  const [alert, setAlert] = React.useState(false);
  const [alertOpen, setAlertOpen] = React.useState(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };
  const handleLogout = () => {
    setLogoutInitiated(true);
    setAlert("Are you sure you want to logout?");
    setAlertOpen(true);
  };
  const handleLogoutConfirmed = () => {
    logout({ refreshToken: getFromLocalStorage("refresh-token") });
    setLogoutInitiated(false);
    sessionStorage.setItem("alertShown", "true");
    setAlertOpen(false);
  };
  const handleCancelLogout = () => {
    setLogoutInitiated(false);
    setAlertOpen(false);
  };
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  React.useEffect(() => {
    // console.log(data);
    if (data?.status === 'success') {
      removeFromLocalStorage("token");
      removeFromLocalStorage("user");
      window.location.reload();
    }
  }, [data]);

  //  * * * * * * * * * * * Notify user * * * * * * * * * * * * *
  React.useEffect(() => {
    if (isError) {
      toast.error("Logout unsuccessful!");
    }
  }, [isError]);

  return (
    <ThemeProvider theme={theme}>
      <Snackbar
        open={alertOpen}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {logoutInitiated ? (
          <SnackAlert
            sx={{ borderRadius: 5, fontFamily: theme.typography.fontFamily }}
            severity="error"
            onClose={handleCloseSnackbar}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                fontWeight: "bold",
              }}
            >
              {alert}
              <Box display="flex" justifyContent="space-around" sx={{ mt: 1 }}>
                <Box>
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                      color: theme.palette.primary.main,
                      fontFamily: theme.typography.fontFamily,
                      fontSize: "0.8em",
                      fontWeight: "bold",
                      borderRadius: 15,
                      backgroundColor: "#f7d7db",
                      ":hover": { bgcolor: "#FFBFBF" },
                    }}
                    onClick={handleLogoutConfirmed}
                  >
                    Yes
                  </Button>
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                      ml: 1,
                      fontFamily: theme.typography.fontFamily,
                      fontSize: "0.8em",
                      fontWeight: "bold",
                      color: theme.palette.primary.main,
                      borderRadius: 15,
                      backgroundColor: "#FFF",
                      ":hover": { bgcolor: "#A6D0DD" },
                    }}
                    onClick={handleCancelLogout}
                  >
                    No
                  </Button>
                </Box>
              </Box>
            </div>
          </SnackAlert>
        ) : (
          <SnackAlert severity={"error"} onClose={handleCloseSnackbar}>
            {alert}
          </SnackAlert>
        )}
      </Snackbar>
      <AppBar
        position="fixed"
        sx={{
          display: {
            xs: "block",
            md: "none",
          },
          background: "#fff",
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon sx={{ color: theme.palette.primary.navy }} />
              </IconButton>
              <Menu
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                PaperProps={{
                  sx: {
                    borderRadius: 2,
                  },
                }}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {menuItems.map((menu, index) => (
                  <MenuItem
                    key={menu}
                    onClick={() => handleMenuItemClick(index)}
                    sx={{
                      paddingLeft: 1.4,
                      "&:hover": {
                        backgroundColor: "#F0F0F0 !important",
                        fontWeight: `${theme.typography.fontWeight.bold} !important`,
                      },
                    }}
                  >
                    {indexIcons[index].alt === "logoutIcon" ? (
                      <Button
                        sx={{
                          lineHeight: "13px",
                          justifyContent: "start",
                          width: "120px",
                          fontSize: theme.typography.h7,
                          color: theme.palette.primary.red,
                          fontWeight: theme.typography.fontWeight.medium,
                          "&:hover": {
                            bgcolor: "transparent",
                            fontWeight: `${theme.typography.fontWeight.bold} !important`,
                          },
                        }}
                      >
                        {menu}
                      </Button>
                    ) : (
                      <Button
                        sx={{
                          lineHeight: "13px",
                          justifyContent: "start",
                          width: "120px",
                          fontSize: theme.typography.h7,
                          color: theme.palette.primary.navy,
                          fontWeight: theme.typography.fontWeight.medium,
                          "&:hover": {
                            bgcolor: "transparent",
                            fontWeight: `${theme.typography.fontWeight.bold} !important`,
                          },
                        }}
                      >
                        {menu}
                      </Button>
                    )}
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            <Box
              variant="subtitle1"
              component="a"
              sx={{
                ml: 1,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                cursor: "pointer",
              }}
              onClick={() => navigate("/")}
            >
              <img
                src={logoIcon}
                alt="logo"
                style={{ width: "10%", marginTop: "10px" }}
              />
              <Typography
                sx={{
                  textDecoration: "none",
                  color: "#2E4F4F",
                  fontWeight: "600",
                  marginTop: { xs: "10px", sm: "15px" },
                }}
              >
                {" "}
                SuperQuiz
              </Typography>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </ThemeProvider>
  );
}
export default Navbar;
