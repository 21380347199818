import React from 'react'
import{Grid,Typography}  from "@mui/material";
import theme from "../config/theme"
export default function Footer() {
  return (
    <footer>
          <Grid container gap={4} sx={{mb:2, pr:{xs:0,sm:3},justifyContent:{xs:"center",sm:"flex-end"} }}>
                <Typography
                  sx={{
                    fontFamily:theme.typography.fontFamily,
                    fontSize:theme.typography.h6,
                  }}
                >
                  <a href="https://docs.google.com/forms/d/e/1FAIpQLSfWk8hYTGRg1j649chAiULXf42_5Ypr6kQ7-8qKFMUPxS4ylQ/viewform?usp=pp_url">
                    Provide Feedback
                  </a>
                </Typography>
                <Typography
                  sx={{
                    fontFamily:theme.typography.fontFamily,
                    fontSize:theme.typography.h6,
                  }}
                >
                  <a href="https://docs.google.com/forms/d/e/1FAIpQLSdhD_w8kis4umQpafEMbF2HjwksuGcifcsXSfy2P9M8oS5j1w/viewform?usp=sf_link">Share your ideas</a>
                </Typography>
              </Grid>
              </footer>
  )
}
