import React, { useState } from "react";
import theme from "../config/theme";
import { Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ProvideEmailForm from "./ProvideEmailForm";
import CodeVerificationForm from "./CodeVerificationForm";
import ResetPasswordForm from "./ResetPasswordForm"
function ResetPassword({
  open,
  handleCloseDialog,
  setAlertOpen,
  setAlert,
  setSeverity,
  setOpenDialog
}) {
  const [enterCode, setEnterCode] = useState(false);
  const [submitCode, setSubmitCode] = useState(false);
  const [emailInResetForm, setEmailInResetForm] = useState("");

  return (
    <Dialog open={open} onClose={handleCloseDialog} maxWidth="xs" fullWidth>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          mt: 0.5,
          mr: 0.5,
        }}
      >
        <IconButton size="small" onClick={handleCloseDialog}>
          <CloseIcon sx={{ color: "gray" }} />
        </IconButton>
      </Box>
      <DialogTitle
        sx={{
          mt: 2,
          textAlign: "center",
          fontSize: theme.typography.subtitle1,
        }}
      >
        Reset Your Password
      </DialogTitle>
      <DialogContent>
        {submitCode ? (
          <ResetPasswordForm
            setAlertOpen={setAlertOpen}
            setAlert={setAlert}
            emailInResetForm={emailInResetForm}
            setSeverity={setSeverity}
            setOpenDialog={setOpenDialog}
          />
        ) : enterCode ? (
          <CodeVerificationForm
            setAlertOpen={setAlertOpen}
            setAlert={setAlert}
            emailInResetForm={emailInResetForm}
            setSubmitCode={setSubmitCode}
          />
        ) : (
          <ProvideEmailForm
            setAlertOpen={setAlertOpen}
            setAlert={setAlert}
            setEnterCode={setEnterCode}
            setEmailInResetForm={setEmailInResetForm}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}

export default ResetPassword;
